import { documentToReactComponents, Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Box, getCSSVariable, Text } from '@opendoor/bricks-next';
import {
  Image,
  Table,
  TableBody,
  TableCell,
  TableHeader,
  TableHeading,
  TableRow,
} from '@opendoor/bricks/core';
import { novo } from '@opendoor/bricks/theme';

import { EntryComponent } from 'cms/entries/entries';

import {
  ILpComponentComparisonTable,
  ILpComponentComparisonTableFields,
} from 'declarations/contentful';

import Container from './shared/Container';
import { TitleWithAccent } from './shared/Typography';

// Set default theme to generate button colors

const Yes = () => (
  <Text
    tag="p"
    typography="$bodyLarge"
    display="flex"
    justifyContent="center"
    alignItems="center"
    color="unset"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M2 8.03846L6 11.5L14 4" stroke="currentColor" strokeWidth="2" />
    </svg>
  </Text>
);

const No = () => (
  <Text
    tag="p"
    typography="$bodyLarge"
    display="flex"
    justifyContent="center"
    alignItems="center"
    color="unset"
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
      <path d="M3 13.0007L13 3" stroke="currentColor" strokeWidth="2" />
      <path d="M13 13L8.00005 7.99984L3 3" stroke="currentColor" strokeWidth="2" />
    </svg>
  </Text>
);

const Question = () => (
  <Text
    tag="p"
    typography="$subheaderMedium"
    display="flex"
    justifyContent="center"
    alignItems="center"
    color="unset"
  >
    ?
  </Text>
);

const Dash = () => (
  <Text
    tag="p"
    typography="$subheaderMedium"
    display="flex"
    justifyContent="center"
    alignItems="center"
    color="unset"
  >
    -
  </Text>
);

const TableImage = styled(Image)({
  borderRadius: '99em 99em 8em 8em',
  fontSize: '100%',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
});

const options: Options = {
  renderNode: {
    [BLOCKS.TABLE]: (_, children) => children,
    [BLOCKS.TABLE_ROW]: (_, children) => <TableRow>{children}</TableRow>,
    [BLOCKS.TABLE_HEADER_CELL]: (_, children) => (
      <TableHeading
        fontSize="18px"
        fontWeight="medium"
        letterSpacing="-2.4"
        lineHeight="120"
        textAlign="center"
      >
        {children}
      </TableHeading>
    ),
    [BLOCKS.TABLE_CELL]: (_: any, children: any) => {
      // Underlines are mapped to render as th scope="row"
      if (children[0].type == 'u') {
        return (
          <TableHeading
            fontSize="18px"
            fontWeight="medium"
            letterSpacing="-2.4"
            lineHeight="120"
            scope="row"
            textAlign="inherit"
          >
            {children[0].props.children}
          </TableHeading>
        );
      }
      return (
        <TableCell fontSize="18px" letterSpacing="-2.4" lineHeight="120" textAlign="inherit">
          {children}
        </TableCell>
      );
    },
    [BLOCKS.PARAGRAPH]: (_: any, children: any) => {
      let mark;
      if (children[0] == '✓') {
        mark = <Yes />;
      } else if (children[0] == 'X') {
        mark = <No />;
      } else if (children[0] == '?') {
        mark = <Question />;
      } else if (children[0] == '-') {
        mark = <Dash />;
      } else {
        mark = children[0];
      }
      return mark;
    },
  },
};

const getHighlightColor = (color: ILpComponentComparisonTableFields['highlightColor']) => {
  if (color === 'Warm Grey 950') {
    return novo.colors.warmgrey950;
  }

  return novo.colors.brandBlue600;
};

// Parent element that lays out existing table wrapper and image

const RenderComparisonTable = (entry: ILpComponentComparisonTable) => {
  const { fields } = entry;
  const captionId = `caption-${Math.floor(Math.random() * 100 + 1)}`;

  let highlightCss = '';
  const highlightColor = getHighlightColor(fields.highlightColor);
  if (fields.highlightColumn) {
    highlightCss = `
    th:not([scope=row]):nth-child(${fields.highlightColumn}) {
      background-color: ${highlightColor};
      color: ${novo.colors.white0};
    }
    td:nth-child(${fields.highlightColumn}) {
      border-left: 1px solid ${highlightColor};
      border-right: 1px solid ${highlightColor};
    }
    tbody tr:last-child td:nth-child(${fields.highlightColumn}) {
      border-bottom: 1px solid ${highlightColor};
    }
    `;
  }

  let tableCss = '';
  if (fields.layout === 'Border' || fields.layout === 'Earth 200' || fields.layout === 'Grey') {
    tableCss = `
    th,
    td {
      border-bottom: 1px solid ${getCSSVariable('$borderPrimary')};
    }
    tbody tr:last-child th,
    tbody tr:last-child td {
      border-bottom: 0;
    }
    `;
  }

  if (fields.layout === 'No Border') {
    tableCss = `
      thead th {
        border: 0;
        border-radius: 16px 16px 0 0;
      }
      tbody tr:nth-child(odd) th,
      tbody tr:nth-child(odd) td {
        background-color: ${novo.colors.blue50};
      }
      tbody tr:nth-child(odd) th:first-child,
      tbody tr:nth-child(odd) td:first-child {
        border-radius: 16px 0 0 16px;
      }
      tbody tr:nth-child(odd) th:last-child,
      tbody tr:nth-child(odd) td:last-child {
        border-radius: 0 16px 16px 0;
      }
    `;
  }

  let opendoorColCss = '';
  if (fields.opendoorColumn) {
    opendoorColCss = `
      tbody td:nth-child(${fields.opendoorColumn}) {
        color: ${novo.colors.brandBlue600};
      }
    `;
  }

  const tableTextAlignCss = fields.tableTextAlign === 'Center' ? 'center;' : 'left;';
  const hasBGAndBorder =
    fields.image && (fields.layout === 'Earth 200' || fields.layout === 'Grey');
  return (
    <Container paddingVertical="$12x">
      {fields.title && (
        <TitleWithAccent
          title={fields.title}
          titleAccent={fields.titleAccent}
          titleSize={fields.titleSize}
          mb={64}
          mx={fields.titleAlign === 'Center' ? 'auto' : 0}
          textAlign={fields.titleAlign === 'Center' ? 'center' : 'left'}
        />
      )}
      <Box
        display="block"
        gap="$10x"
        backgroundColor={hasBGAndBorder ? '$backgroundTertiary' : undefined}
        borderRadius={hasBGAndBorder ? '$8x' : undefined}
        padding={hasBGAndBorder ? '$10x' : 0}
        $largerThanMD={{ display: fields.image ? 'flex' : 'block', flexDirection: 'row' }}
      >
        <Box flex={fields.image ? 6 : undefined} flexBasis={fields.image ? 0 : undefined}>
          <Box
            borderRadius="$8x"
            borderColor={fields.layout === 'Border' ? '$borderPrimary' : undefined}
            borderWidth={fields.layout === 'Border' ? 1 : undefined}
            // @ts-expect-error overflow is required for the table content to scroll
            overflow="auto"
          >
            <Table
              w="100%"
              id={captionId}
              css={css`
                border-collapse: separate;
                border-spacing: 0;
                th,
                td {
                  padding: 24px 16px;
                }
                @media (min-width: 768px) {
                  th,
                  td {
                    padding: 32px;
                  }
                }
                th[scope='row'],
                tbody td {
                  text-align: ${tableTextAlignCss};
                }
                @media (max-width: 481px) {
                  th[scope='row'],
                  td {
                    min-width: calc(50vw - 24px);
                  }
                }
                ${tableCss}
                ${highlightCss}
                ${opendoorColCss}
              `}
            >
              {fields.tableHeader && (
                <TableHeader>{documentToReactComponents(fields.tableHeader, options)}</TableHeader>
              )}
              {fields.tableBody && (
                <TableBody>{documentToReactComponents(fields.tableBody, options)}</TableBody>
              )}
            </Table>
          </Box>
        </Box>
        {fields.image && (
          <Box flex={4} flexBasis={fields.image ? 0 : undefined}>
            <TableImage
              src={fields.image.fields?.file?.url}
              alt={fields.image.fields?.description || fields.image.fields?.title}
            />
          </Box>
        )}
      </Box>
    </Container>
  );
};

const ComparisonTable: EntryComponent<ILpComponentComparisonTable> = {
  render: RenderComparisonTable,
};

export default ComparisonTable;
