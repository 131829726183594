import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';

import { Box, Button, Dropdown, RadioGroup, Text, useMedia } from '@opendoor/bricks-next';

import { EntryComponent } from 'cms/entries/entries';

import { ILpComponentTimeSavingsCalculator } from 'declarations/contentful';

import { LandingPageSectionContainer } from '../shared/Container';
import { markdownToReactElement } from '../shared/utils';
// import ComparisonTable from './ComparisonTable';
// import richTextTableBodyIcons from './ComparisonTable/data/richTextTableBodyIcons.json';
// import richTextTableHeader from './ComparisonTable/data/richTextTableHeader.json';
import { StickyCTAUtility } from './StickyCTAUtility/StickyCTAUtility';

// const tableHeader = richTextTableHeader as unknown;
// const tableBodyIcons = richTextTableBodyIcons as unknown;

const SAVINGS_MAP = {
  0: {
    cleaning: 0,
    repairs: 0,
    staging: 0,
    decluttering: 0,
  },
  1: {
    cleaning: 12,
    repairs: 10,
    staging: 8,
    decluttering: 10,
  },
  2: {
    cleaning: 18,
    repairs: 15,
    staging: 12,
    decluttering: 15,
  },
  3: {
    cleaning: 25,
    repairs: 20,
    staging: 15,
    decluttering: 20,
  },
  4: {
    cleaning: 40,
    repairs: 30,
    staging: 20,
    decluttering: 30,
  },
};

const HOUSE_SIZE_OPTIONS = [
  {
    label: 'Less than 2,000 square feet',
    value: '1',
  },
  {
    label: 'Between 2,000 - 3,000 square feet',
    value: '2',
  },
  {
    label: 'Between 3,000 - 4,000 square feet',
    value: '3',
  },
  {
    label: 'More than 4,000 square feet',
    value: '4',
  },
];

const COMMON_SELECT_INPUT_OPTIONS = [
  {
    label: 'Yes',
    value: '1',
  },
  {
    label: 'No',
    value: '2',
  },
];

interface LifeStageOption {
  label: string;
  value: string;
  image: string;
  copy: string;
}

const FORM_ALL_NO_ANSWER: LifeStageOption = {
  label: '',
  value: '0',
  image: 'https://imgdrop.imgix.net/2024-08-1724757431875-55318.png',
  copy: 'You can close up to 3x faster compared to the traditional process.',
};

const LIFE_STAGE_DEFAULT: LifeStageOption = {
  label: 'N/A',
  value: '0',
  image: 'https://imgdrop.imgix.net/2024-08-1724187708776-18331.png',
  copy: 'spend more time with friends and family',
};

const INITIAL_SAVINGS_STATE_IMG = 'https://imgdrop.imgix.net/2024-08-1724920393493-04520.png';

const LIFE_STAGE_OPTIONS: LifeStageOption[] = [
  {
    label: 'Upsizing for a growing family',
    value: '1',
    image: 'https://imgdrop.imgix.net/2024-08-1723582055911-91684.png',
    copy: 'to spend the weekend away with the family',
  },
  {
    label: 'Downsizing as an empty nester',
    value: '2',
    image: 'https://imgdrop.imgix.net/2024-08-1724172530232-94501.png',
    copy: "to take that trip you've been planning",
  },
  {
    label: 'Retiring',
    value: '3',
    image: 'https://imgdrop.imgix.net/2024-08-1724172530232-83633.png',
    copy: 'to sit on the beach with a good book',
  },
  {
    label: 'Moving into your forever home',
    value: '4',
    image: 'https://imgdrop.imgix.net/2024-08-1724172530232-11450.png',
    copy: 'to host the best dinner parties',
  },
  {
    label: 'Relocating for work',
    value: '5',
    image: 'https://imgdrop.imgix.net/2024-08-1724172530232-10571.png',
    copy: 'to join that sports league',
  },
  {
    label: 'Moving closer to family',
    value: '6',
    image: 'https://imgdrop.imgix.net/2024-08-1724172530232-39574.png',
    copy: 'to finally master a new hobby',
  },
  {
    label: 'Prioritizing more space for my fur babies',
    value: '7',
    image: 'https://imgdrop.imgix.net/2024-08-1724182708463-82121.png',
    copy: 'to volunteer for a good cause',
  },
  {
    label: 'Investing in real estate',
    value: '8',
    image: 'https://imgdrop.imgix.net/2024-08-1724172530232-08687.png',
    copy: 'to finally start your side hustle',
  },
  {
    label: 'N/A',
    value: '9',
    image: 'https://imgdrop.imgix.net/2024-08-1724187708776-18331.png',
    copy: 'to spend more time with friends and family',
  },
];

const FORM_VALIDATION = {
  homeSize: {
    valid: true,
    errText: '',
  },
  staging: {
    valid: true,
    errText: '',
  },
  repairs: {
    valid: true,
    errText: '',
  },
  cleaning: {
    valid: true,
    errText: '',
  },
  decluttering: {
    valid: true,
    errText: '',
  },
  lifeStage: {
    valid: true,
    errText: '',
  },
};
const RenderTimeSavingsCalculator = ({ disclosureText }: { disclosureText: string }) => {
  const [homeSize, setHomeSize] = useState<string>('0');
  const [staging, setStaging] = useState<string>('0');
  const [repairs, setRepairs] = useState<string>('0');
  const [cleaning, setCleaning] = useState<string>('0');
  const [decluttering, setDecluttering] = useState<string>('0');
  const [totalSavings, setTotalSavings] = useState(0);
  const [submitTouched, setSubmitTouched] = useState(false);
  const [lifeStage, setLifeStage] = useState<LifeStageOption>(LIFE_STAGE_DEFAULT);
  const [hasSubmitted, setHasSubmitted] = useState<boolean>(false);
  const [inputTouched, setInputTouched] = useState(false);
  const [hasNoAll, setHasNoAll] = useState(false);
  const hideStickyCta = false;
  const largerThanSM = useMedia().largerThanSM;

  const {
    ref: addressEntryRef,
    inView: addressEntryInView,
    entry: intersectionObserverEntry,
  } = useInView();

  const showStickyCTAUtility = Boolean(intersectionObserverEntry) && !addressEntryInView;
  // const accordionRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    LIFE_STAGE_OPTIONS.forEach((option) => {
      const img = new Image();
      img.src = option.image;
    });
  }, []);

  useEffect(() => {
    const calculateSavings = () => {
      const homeSizeValue = parseInt(homeSize);
      const cleaningValue = parseInt(cleaning);
      const stagingValue = parseInt(staging);
      const repairsValue = parseInt(repairs);
      const declutteringValue = parseInt(decluttering);

      if (isNaN(homeSizeValue)) return;

      const savings =
        SAVINGS_MAP[homeSizeValue as keyof typeof SAVINGS_MAP].cleaning *
          (cleaningValue == 2 ? 0 : cleaningValue) +
        SAVINGS_MAP[homeSizeValue as keyof typeof SAVINGS_MAP].staging *
          (stagingValue == 2 ? 0 : stagingValue) +
        SAVINGS_MAP[homeSizeValue as keyof typeof SAVINGS_MAP].repairs *
          (repairsValue == 2 ? 0 : repairsValue) +
        SAVINGS_MAP[homeSizeValue as keyof typeof SAVINGS_MAP].decluttering *
          (declutteringValue == 2 ? 0 : repairsValue);

      setHasSubmitted(false);
      if (submitTouched) {
        validateInputs();
      }
      setTotalSavings(savings > 1 ? savings - 1 : savings);
    };
    if (
      homeSize !== '0' ||
      cleaning !== '0' ||
      repairs !== '0' ||
      staging !== '0' ||
      decluttering !== '0' ||
      lifeStage.value !== '0'
    ) {
      setInputTouched(true);
    }
    calculateSavings();
  }, [homeSize, cleaning, repairs, staging, decluttering, lifeStage]);

  useEffect(() => {
    if (cleaning == '2' && repairs == '2' && staging == '2' && decluttering == '2') {
      setHasNoAll(true);
    } else {
      setHasNoAll(false);
    }
  }, [cleaning, repairs, staging, decluttering]);

  const validateInputs = () => {
    let isValid = true;

    FORM_VALIDATION.homeSize.errText = '';
    FORM_VALIDATION.staging.errText = '';
    FORM_VALIDATION.repairs.errText = '';
    FORM_VALIDATION.cleaning.errText = '';
    FORM_VALIDATION.decluttering.errText = '';
    FORM_VALIDATION.lifeStage.errText = '';

    if (homeSize == '0') {
      FORM_VALIDATION.homeSize.errText = 'Please select a home size';
      isValid = false;
    }
    if (staging == '0') {
      FORM_VALIDATION.staging.errText = 'Please select an option';
      isValid = false;
    }
    if (repairs == '0') {
      FORM_VALIDATION.repairs.errText = 'Please select an option';
      isValid = false;
    }
    if (cleaning == '0') {
      FORM_VALIDATION.cleaning.errText = 'Please select an option';
      isValid = false;
    }
    if (decluttering == '0') {
      FORM_VALIDATION.decluttering.errText = 'Please select an option';
      isValid = false;
    }
    if (lifeStage.value == '0') {
      FORM_VALIDATION.lifeStage.errText = 'Please select a life stage';
      isValid = false;
    }
    return isValid;
  };

  const TimeSavedBG = () => (
    <img
      src={
        hasSubmitted
          ? hasNoAll
            ? FORM_ALL_NO_ANSWER.image
            : lifeStage.image
          : INITIAL_SAVINGS_STATE_IMG
      }
      style={{
        width: !hasSubmitted ? (!largerThanSM ? '77px' : '136px') : largerThanSM ? 'auto' : '100%',
        height: !hasSubmitted ? (!largerThanSM ? '90px' : '160px') : '90%',
      }}
      alt=""
    />
  );
  const TimeSavedSection = () => (
    <div
      style={{
        backgroundColor: '#211f1d',
        borderRadius: '16px',
      }}
    >
      <Box
        py="$16x"
        px="$12x"
        width="100%"
        $largerThanSM={{
          height: 500,
        }}
        $largerThanMD={{
          height: 618,
          px: '$24x',
          py: '$24x',
        }}
        justifyContent={!hasSubmitted ? 'space-between' : 'center'}
        alignItems="center"
        testID="Net-proceeds-container"
        position="relative"
      >
        <Text
          typography="$bodySmall"
          pb={!hasSubmitted ? '$24x' : '$8x'}
          tag="span"
          fontWeight="$medium"
          color="$contentInverseTertiary"
          $largerThanMD={{
            typography: '$bodyXlarge',
            pb: '$16x',
            fontWeight: '$medium',
          }}
        >
          TIME SAVED WITH OPENDOOR*
        </Text>
        <TimeSavedBG />
        {!hasSubmitted ? (
          <Box>
            <Text
              pt="$12x"
              tag="h2"
              textAlign="center"
              color="$contentInverseTertiary"
              width={hasNoAll && hasSubmitted ? '70%' : '100%'}
              typography="$subheaderMedium"
              $largerThanMD={{
                typography: '$headerLarge',
                pt: '$4x',
              }}
            >
              Your time is valuable
            </Text>
            <Text
              pt="$1x"
              typography="$bodySmall"
              fontWeight="$regular"
              tag="h3"
              color="$contentInverseTertiary"
              textAlign="center"
              width="100%"
              $largerThanMD={{
                typography: '$subheaderXsmall',
                fontWeight: '$regular',
                textAlign: 'center',
              }}
              $largerThanXS={{
                pt: '$1x',
              }}
            >
              Let's crunch some numbers.
            </Text>
          </Box>
        ) : (
          <Box
            width="100%"
            height="100%"
            justifyContent="center"
            alignItems="center"
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
          >
            {hasSubmitted && inputTouched && (
              <>
                <Text
                  pt="$6x"
                  tag="h2"
                  textAlign="center"
                  color="$contentInverseTertiary"
                  width={hasNoAll ? '70%' : '100%'}
                  typography={hasNoAll ? '$subheaderSmall' : '$headerMedium'}
                  $largerThanMD={{
                    typography: hasNoAll ? '$displayXsmall' : '$displayMedium',
                  }}
                  $largerThanXS={{
                    pt: '$4x',
                  }}
                >
                  {hasNoAll ? 'Sell in as little as 14 days' : `${totalSavings}h saved`}
                </Text>
                <Text
                  pt="$1x"
                  typography="$bodySmall"
                  fontWeight="$regular"
                  tag="span"
                  color="$contentInverseTertiary"
                  textAlign="center"
                  width="80%"
                  $largerThanMD={{
                    typography: '$subheaderXsmall',
                    fontWeight: '$regular',
                    textAlign: 'center',
                    width: '60%',
                  }}
                  $largerThanXS={{
                    pt: '$1x',
                  }}
                >
                  {hasNoAll
                    ? 'You can close up to 3x faster compared to the traditional process.'
                    : lifeStage.copy}
                </Text>
              </>
            )}
          </Box>
        )}
        <Box
          width="100%"
          alignItems="flex-end"
          pt="$8x"
          $largerThanMD={{
            pt: '$16x',
          }}
        >
          <Text
            typography="$labelXsmall"
            tag="span"
            textAlign="right"
            fontWeight="$regular"
            color="$contentInverseTertiary"
            $largerThanMD={{
              typography: '$labelSmall',
              fontWeight: '$regular',
            }}
          >
            Estimated*
          </Text>
        </Box>
      </Box>
    </div>
  );

  // const OpendoorFeaturesTableSection = () => {
  //   return (
  //     <div ref={accordionRef}>
  //       <Accordion title="Reasons to choose Opendoor " isOpen={true}>
  //         <>
  //           {ComparisonTable.render({
  //             fields: {
  //               layout: 'Warm Grey',
  //               tableHeader: tableHeader,
  //               tableBody: tableBodyIcons,
  //             },
  //           } as unknown as ILpComponentComparisonTable)}
  //         </>
  //       </Accordion>
  //     </div>
  //   );
  // };

  const { largerThanMD } = useMedia();
  // const renderODFeaturesSection = useMemo(() => {
  //   return <OpendoorFeaturesTableSection />;
  // }, []);
  return (
    <>
      <Box
        py="$12x"
        gap="$28x"
        $largerThanMD={{ flexDirection: 'row', justifyContent: 'space-between' }}
      >
        {!hideStickyCta && <StickyCTAUtility analyticsId="test" isVisible={showStickyCTAUtility} />}
        {/* START: Left section */}
        <Box display="none" $largerThanMD={{ maxWidth: '100%', display: 'flex', width: 630 }}>
          <Box
            gap="$16x"
            style={
              largerThanMD
                ? {
                    position: 'sticky',
                    top: '60px',
                    zIndex: 10,
                  }
                : {}
            }
          >
            <TimeSavedSection />
            {/* {renderODFeaturesSection} */}
          </Box>
        </Box>

        {!largerThanMD && (
          <Box gap="$28x" $largerThanMD={{ display: 'none' }}>
            <TimeSavedSection />
          </Box>
        )}
        {/* END: Left section */}

        {/* START: Inputs section */}
        <Box
          display="flex"
          flexDirection="column"
          $largerThanMD={{
            width: '50%',
            maxWidth: 630,
          }}
          flexShrink={1}
          testID="Form-Container"
        >
          <Box gap="$40x" $largerThanXS={{ gap: '$40x' }}>
            <Box>
              <Dropdown
                title="Which life stage describes you best?"
                size="medium"
                aria-labelledby="Select Life Stage"
                placeholderOption={{ label: 'Select stage', value: '' }}
                options={LIFE_STAGE_OPTIONS}
                prefilledValue="1000"
                errorText={FORM_VALIDATION.lifeStage.errText}
                onValueChange={(value) => {
                  if (value.length === 0) {
                    setLifeStage(LIFE_STAGE_DEFAULT);
                    return;
                  }
                  setLifeStage(
                    LIFE_STAGE_OPTIONS.find((option) => option.value === value) as LifeStageOption,
                  );
                }}
              />
            </Box>
            <Box paddingBottom="$40x">
              <Dropdown
                title="How big is your house?"
                size="medium"
                aria-labelledby="Select Home Size"
                placeholderOption={{ label: 'Select size', value: '' }}
                options={HOUSE_SIZE_OPTIONS}
                errorText={FORM_VALIDATION.homeSize.errText}
                prefilledValue="2000"
                onValueChange={(value) => {
                  if (value.length === 0) {
                    setHomeSize('0');
                    return;
                  }
                  setHomeSize(value);
                }}
              />
            </Box>
          </Box>
          <div ref={addressEntryRef}></div>
          <Box gap="$40x" $largerThanXS={{ gap: '$40x' }}>
            <Box>
              <RadioGroup
                title="Does your home need to be staged before selling?"
                options={COMMON_SELECT_INPUT_OPTIONS}
                analyticsName={`cosmos-homesale-calculator-needs-staging}`}
                errorText={FORM_VALIDATION.staging.errText}
                size="medium"
                onPress={(value) => {
                  setStaging(value);
                }}
              ></RadioGroup>
            </Box>
            <Box>
              <RadioGroup
                title="Do you need to clean your home before selling?"
                options={COMMON_SELECT_INPUT_OPTIONS}
                analyticsName={`cosmos-homesale-calculator-needs-cleaning}`}
                errorText={FORM_VALIDATION.cleaning.errText}
                onPress={(value) => {
                  setCleaning(value);
                }}
                size="medium"
              ></RadioGroup>
            </Box>
            <Box>
              <RadioGroup
                title="Do you need to declutter your home before selling?"
                options={COMMON_SELECT_INPUT_OPTIONS}
                errorText={FORM_VALIDATION.decluttering.errText}
                analyticsName={`cosmos-homesale-calculator-needs-decluttering}`}
                onPress={(value) => {
                  setDecluttering(value);
                }}
                size="medium"
              ></RadioGroup>
            </Box>
            <Box>
              <RadioGroup
                title="Does your home need any minor repairs before selling?"
                options={COMMON_SELECT_INPUT_OPTIONS}
                errorText={FORM_VALIDATION.repairs.errText}
                analyticsName={`cosmos-homesale-calculator-needs-repairs}`}
                onPress={(value) => {
                  setRepairs(value);
                }}
                size="medium"
              ></RadioGroup>
            </Box>
            <Box width="$130x">
              <Button
                onPress={() => {
                  window.scrollTo({
                    top: 230,
                    behavior: 'smooth',
                  });
                  setSubmitTouched(true);
                  if (!validateInputs()) return;
                  setHasSubmitted(true);
                }}
                variant="primary"
                size="medium"
                label="Submit"
                aria-label="Submit"
                analyticsName="cosmos-homesale-calculator-calculate-time-saved"
              />
            </Box>

            {/*<Box $largerThanMD={{ display: 'none' }}> {renderODFeaturesSection}</Box> */}
          </Box>
        </Box>
        {/* END: Inputs section */}
      </Box>
      <Box py="$28x" gap="$16x" $largerThanMD={{ maxWidth: 620 }}>
        <Text tag="h2" typography="$subheaderLarge">
          *Disclosure
        </Text>
        <Text tag="p" color="$contentPrimary" typography="$bodyMedium" display="block">
          {markdownToReactElement(disclosureText as string)}
        </Text>
      </Box>
    </>
  );
};

export const RenderCalculator = (entry: ILpComponentTimeSavingsCalculator) => {
  const {
    fields: { disclosureText },
  } = entry;
  return (
    <LandingPageSectionContainer>
      <RenderTimeSavingsCalculator disclosureText={disclosureText} />
    </LandingPageSectionContainer>
  );
};

const TimeSavingsCalculator: EntryComponent<ILpComponentTimeSavingsCalculator> = {
  render: RenderCalculator,
};

export default TimeSavingsCalculator;
