import LazyLoad from 'react-lazyload';

import { Text } from '@opendoor/bricks-next';
import { Box, Flex, Image, Link } from '@opendoor/bricks/core';

import { EntryComponent } from 'cms/entries/entries';

import { Desc } from 'components/landing-pages-v2/shared/Typography';

import { ILpComponentMapBackgroundHero } from 'declarations/contentful';

import Grid, { gridStyles } from './Grid';
import Marker, { MarkerPulse } from './shared/Marker';

const RenderMapBackgroundHero = (entry: ILpComponentMapBackgroundHero) => {
  const { fields } = entry;

  return (
    <Flex
      analyticsName={`cosmos-landing-page-map-background-hero-${fields?.analyticsId}`}
      trackImpression
      position="relative"
      justifyContent="center"
      width="100%"
      minHeight={[null, '680px', '560px', '848px']}
      alignItems="center"
      py={[6, null, 8]}
      mt="92px"
    >
      <Box zIndex={0} width="100%" height="100%" position="absolute" top="0" left="0" right="0">
        <Image
          alt="map background hero"
          loading="lazy"
          src="https://images.opendoor.com/source/s3/imgdrop-production/2023-07-1689881024134-43912.jpg?preset=square-2048"
          objectFit="cover"
          width="100%"
          height="100%"
        />
      </Box>
      <LazyLoad offset={300}>
        <Marker
          imageSource="https://images.opendoor.com/source/s3/imgdrop-production/2023-04-1682635416264-57298.png?preset=square-2048"
          left="10%"
          top={['17%', '12%', '12%']}
          height={['40px', null, '50px']}
          width={['100px', null, '125px']}
          css={MarkerPulse}
          animationDelay="2s"
        />
      </LazyLoad>
      <LazyLoad offset={300}>
        <Marker
          imageSource="https://images.opendoor.com/source/s3/imgdrop-production/2023-04-1682635416269-85419.png?preset=square-2048"
          right={['7%', null, '30%']}
          top={['4%', null, '7%']}
          height={['40px', null, '50px']}
          width={['100px', null, '125px']}
          css={MarkerPulse}
          animationDelay="0.5s"
        />
      </LazyLoad>
      <LazyLoad offset={300}>
        <Marker
          imageSource="https://images.opendoor.com/source/s3/imgdrop-production/2023-04-1682635416260-76025.png?preset=square-2048"
          display={['none', null, 'block']}
          left="8%"
          top="40%"
          height={['40px', null, '50px']}
          width={['100px', null, '125px']}
          css={MarkerPulse}
          animationDelay="0.5s"
        />
      </LazyLoad>
      <LazyLoad offset={300}>
        <Marker
          imageSource="https://images.opendoor.com/source/s3/imgdrop-production/2023-04-1682635416240-88834.png?preset=square-2048"
          left="15%"
          top={['80%', null, '85%']}
          height={['40px', null, '50px']}
          width={['100px', null, '125px']}
          css={MarkerPulse}
        />
      </LazyLoad>
      <LazyLoad offset={300}>
        <Marker
          imageSource="https://images.opendoor.com/source/s3/imgdrop-production/2023-04-1682635416253-95450.png?preset=square-2048"
          right={['4%', null, '15%']}
          top={['90%', '85%', '60%']}
          height={['40px', null, '50px']}
          width={['100px', null, '125px']}
          css={MarkerPulse}
          animationDelay="2.5s"
        />
      </LazyLoad>
      <Grid justifyContent="center" position="relative">
        <Flex
          gridColumn={gridStyles.colSpan12}
          justifyContent="center"
          zIndex={1}
          flexDir="column"
          alignItems="center"
        >
          <Box mb={[7]}>
            <Text
              tag="h2"
              typography="$headerSmall"
              display="block"
              color="$backgroundPrimary"
              textAlign="center"
              $largerThanSM={{ typography: '$headerMedium' }}
              $largerThanMD={{ typography: '$headerLarge' }}
            >
              {fields?.title}
            </Text>
            <Text
              tag="h2"
              typography="$headerSmall"
              display="block"
              color="$contentTertiary"
              textAlign="center"
              $largerThanSM={{ typography: '$headerMedium' }}
              $largerThanMD={{ typography: '$headerLarge' }}
              opacity={65}
            >
              {fields?.secondaryTitle}
            </Text>
          </Box>
          <Flex
            width="100%"
            maxWidth={[null, '263px', null, '377px']}
            justifyContent="center"
            mb={[10]}
          >
            <Desc
              textAlign="center"
              typography="$bodyMedium"
              $largerThanMD={{ typography: '$bodyXlarge' }}
              color={'$backgroundPrimary'}
            >
              {fields?.description}
            </Desc>
          </Flex>
          {fields?.ctaLink && fields?.ctaDisplayText && (
            <Link
              variant="primary-button"
              aria-label={fields?.ctaDisplayText}
              analyticsName={`cosmos-landing-page-map-background-hero-cta-${fields?.analyticsId}`}
              maxWidth="165px"
              padding="19px 25px"
              height="59px"
              href={fields?.ctaLink}
              style={{
                borderRadius: '14px',
                backgroundColor: '#2E69FF',
                fontWeight: '500',
              }}
              color="white0"
            >
              {fields?.ctaDisplayText}
            </Link>
          )}
        </Flex>
      </Grid>
    </Flex>
  );
};

const MapBackgroundHero: EntryComponent<ILpComponentMapBackgroundHero> = {
  render: RenderMapBackgroundHero,
};

export default MapBackgroundHero;
