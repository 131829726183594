import { KeyboardEvent, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { Box, Image } from '@opendoor/bricks/core';
import { ColorScheme } from '@opendoor/bricks/system/theme';
import { motion } from 'framer-motion';

import { EntryComponent } from 'cms/entries/entries';

import { ILpComponentFeature5050 } from '../../declarations/contentful';
import { LPLink } from './shared/Buttons';
import Container from './shared/Container';
import { show } from './shared/motion/MotionVariants';
import Sheet from './shared/Sheet';
import { getComponentThemeColors, IComponentThemeOptions } from './shared/styles/ComponentTheme';
import { Desc, Eyebrow, Subhead, TitleWithAccent } from './shared/Typography';

const TextBox = styled(Box)`
  border-radius: 24px 24px 0 0;
  @media (min-width: 48em) {
    border-radius: ${(props) => props.textBdrs};
  }
`;

const ImageBox = styled(Box)`
  border-radius: 0 0 24px 24px;
  @media (min-width: 48em) {
    border-radius: ${(props) => props.imageBdrs};
  }
`;

const ArchImage = styled(Image)((props) => ({
  borderRadius: props.isArched ? '99em 99em 8em 8em' : '2em',
}));

const Feature5050Image = ({
  isArched,
  bgColor,
  imageSrc,
  imageAlt,
}: {
  isArched: boolean;
  bgColor: ColorScheme;
  imageSrc: string;
  imageAlt: string;
}) => {
  return (
    <ArchImage
      w="100%"
      objectFit="cover"
      src={imageSrc}
      alt={imageAlt}
      isArched={isArched}
      h="100%"
      borderStyle="solid"
      borderWidth="16px"
      borderColor={bgColor}
    />
  );
};

interface CtaSheetProps {
  entry: ILpComponentFeature5050;
  componentTheme: IComponentThemeOptions;
}

const CtaSheet = ({ entry, componentTheme }: CtaSheetProps) => {
  const { fields } = entry;

  const [isVisible, setIsVisible] = useState(false);
  const ctaRef = useRef<HTMLAnchorElement>(null);
  // When animation is complete
  const exitComplete = () => {
    ctaRef.current?.focus();
  };

  // Make visual buttons work like Buttons
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === ' ' || e.key === 'Spacebar') {
      setIsVisible(true);
    }
  };

  return (
    <>
      {fields.ctaDisplayText && (
        <LPLink
          aria-label={fields.ctaSheetLabel || 'Open dialog'}
          analyticsName={`cosmos-landing-page-feature5050-${fields?.analyticsId}`}
          componentTheme={componentTheme}
          ctaStyle={fields.ctaStyle}
          ctaDisplayText={fields.ctaDisplayText}
          mt="0"
          tabIndex={0}
          onKeyDown={onKeyDown}
          ref={ctaRef}
          onClick={() => setIsVisible(true)}
        >
          {fields.ctaDisplayText}
        </LPLink>
      )}
      <Sheet
        entry={fields.ctaSheet}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        exitComplete={exitComplete}
      />
    </>
  );
};

const RenderFeature5050 = (entry: ILpComponentFeature5050) => {
  const { fields } = entry;
  const componentTheme = getComponentThemeColors(fields.backgroundColor);
  const { isArched = false, imagePosition = 'Right' } = fields;
  const textBdrs = imagePosition === 'Right' ? '24px 0 0 24px' : '0 24px 24px 0';
  let imageBdrs;
  if (isArched) {
    imageBdrs = imagePosition === 'Left' ? '50% 0 0 24px' : '0 50% 24px 0';
  } else {
    imageBdrs = imagePosition === 'Right' ? '0 24px 24px 0' : '24px 0 0 24px';
  }

  return (
    <Container>
      <Box
        as={motion.div}
        variants={show}
        initial="start"
        animate="end"
        display={[null, null, 'grid']}
        gridTemplateColumns={[null, null, 'repeat(2, 1fr)']}
        justifyItems={[null, 'center']}
      >
        <TextBox
          backgroundColor={componentTheme.bg}
          h="100%"
          textAlign="left"
          display="flex"
          flexDirection="column"
          justifyContent="flex-end"
          pb="56px"
          pt="64px"
          pl={
            imagePosition === 'Left' ? ['24px', null, null, '96px'] : ['24px', null, null, '64px']
          }
          pr={
            imagePosition === 'Left' ? ['24px', null, null, '64px'] : ['24px', null, null, '96px']
          }
          rowGap="24px"
          textBdrs={textBdrs}
          gridColumn={imagePosition === 'Left' ? '2 / -1' : '1 / 2'}
          gridRow={[null, null, '1 / -1']}
          w="100%"
        >
          <Box display="flex" flexDirection="column" rowGap="8px">
            {fields.eyebrow && <Eyebrow>{fields.eyebrow}</Eyebrow>}
            {fields.title && (
              <TitleWithAccent title={fields.title} titleAccent={fields.titleAccent} />
            )}
            {fields.subhead && <Subhead>{fields.subhead}</Subhead>}
            {fields.desc && <Desc mt="$4x">{fields.desc}</Desc>}
          </Box>
          {fields.ctaDisplayText && (fields.ctaUrl || fields.ctaSheet) && (
            <Box>
              {fields.ctaDisplayText && fields.ctaUrl && (
                <LPLink
                  aria-label={`Go to page ${fields?.ctaDisplayText}`}
                  analyticsName={`cosmos-landing-page-feature5050-${fields?.analyticsId}`}
                  componentTheme={componentTheme}
                  ctaStyle={fields.ctaStyle}
                  ctaDisplayText={fields.ctaDisplayText}
                  ctaUrl={fields.ctaUrl}
                  mt="0"
                >
                  {fields.ctaDisplayText}
                </LPLink>
              )}
              {fields.ctaDisplayText && fields.ctaSheet && (
                <CtaSheet entry={entry} componentTheme={componentTheme} />
              )}
            </Box>
          )}
        </TextBox>

        <ImageBox
          backgroundColor={componentTheme.bg}
          imageBdrs={imageBdrs}
          gridColumn={imagePosition === 'Left' ? '1 / 2' : '2 / -1'}
          gridRow={[null, null, '1 / -1']}
          w="100%"
        >
          {fields.image && (
            <Feature5050Image
              isArched={isArched}
              bgColor={componentTheme.bg}
              imageSrc={fields.image.fields?.file?.url}
              imageAlt={fields.image.fields?.description || fields.image.fields?.title}
            />
          )}
        </ImageBox>
      </Box>
    </Container>
  );
};

const Feature5050: EntryComponent<ILpComponentFeature5050> = {
  render: RenderFeature5050,
};

export default Feature5050;
