import React from 'react';
import LazyLoad from 'react-lazyload';

import { Box, Card, Flex, Link, Text } from '@opendoor/bricks/core';
import { novo } from '@opendoor/bricks/theme';
import { breakpoints } from '@opendoor/bricks/theme/novo';

import { generatePresetPictureSet, PresetPictureSet } from '../../shared/performance/pictures';

const SPACER = 6;
const BROWN95 = '#F6F5F4';

const PICTURE_SIZES: PresetPictureSet = [
  {
    minWidth: breakpoints[0],
    maxWidth: breakpoints[1],
    preset: 'square-256',
  },
  {
    minWidth: breakpoints[1],
    maxWidth: breakpoints[2],
    preset: 'square-512',
  },
  {
    minWidth: breakpoints[2],
    maxWidth: breakpoints[3],
    preset: 'square-512',
  },
  {
    minWidth: breakpoints[3],
    maxWidth: null,
    preset: 'square-1024',
  },
];

/* storybook-check-ignore */
export const ReviewCard: React.FC<{
  image: string;
  quote: string;
  analyticsName: string;
  link?: string;
  name?: string;
  market?: string;
}> = ({ image, quote, analyticsName, link, name, market }) => {
  return (
    <Card
      variant="primary"
      minHeight={['460', null, '500', null, null]}
      maxHeight={[null, null, '500', null, null]}
      minWidth={['264', '319', '378', null, null]}
      maxWidth={[null, null, '378', null, null]}
      style={{
        backgroundColor: BROWN95,
        borderRadius: '36px',
        boxShadow: novo.shadows.none,
      }}
    >
      <Flex
        flexDirection="column"
        textAlign="center"
        alignItems="center"
        gap={SPACER}
        flexGrow={1}
        mt={SPACER}
      >
        <Box
          width={['80px', null, '112px']}
          height={['80px', null, '112px']}
          borderRadius="rounded"
          overflow="hidden"
        >
          <LazyLoad offset={300}>
            {generatePresetPictureSet({
              photoUrl: image,
              sizes: PICTURE_SIZES,
              debugImageSizes: false,
              radii: {},
              lazy: true,
            })}
          </LazyLoad>
        </Box>
        <Text
          size="110"
          fontWeight="regular"
          style={{
            lineHeight: '25.6px',
          }}
          minWidth={[null, null, null, '250px']}
        >
          {quote}
        </Text>
        <Link href={link} aria-label={analyticsName} analyticsName={analyticsName}>
          <Text fontWeight="medium" size="110" color="brand50">
            Read more
          </Text>
        </Link>
      </Flex>
      <Flex flexDirection="column" textAlign="center" marginBottom={SPACER}>
        <Text size="120" fontWeight="medium" margin={0}>
          {name}
        </Text>
        <Text size="100" fontWeight="medium" color="neutrals70" margin={0}>
          {market}
        </Text>
      </Flex>
    </Card>
  );
};
