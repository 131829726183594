import { globalObservability } from '@opendoor/observability/slim';
import { Entry } from 'contentful';

import { CONTENT_TYPE } from '../declarations/contentful';
import IEntryLookup from './entries/entries';

export type IIdToLoaderData = { [id: string]: any };

type SafeEntry<T> = Omit<Entry<T>, 'toPlainObject' | 'update'>;

// TODO: hydrate inline entries (AG-51)
export interface HydrateEntriesOptions {
  includes: Array<SafeEntry<unknown>>;
  entryLookup: IEntryLookup;
  root?: Entry<any>;
  pageContext: {
    pageNum?: number;
    url: string;
  };
}

export function hydrateEntries(options: HydrateEntriesOptions): Array<Promise<[string, any]>> {
  const { includes, entryLookup, root, pageContext } = options;
  // The rest of the function implementation remains the same
  const loaderPromises: Array<Promise<[string, any]>> = [];
  for (const x of includes) {
    const contentType = x.sys.contentType.sys.id as CONTENT_TYPE;
    const entryId = x.sys.id;
    if (!contentType) {
      continue;
    }
    const entryComponent = entryLookup.block[contentType];
    if (!entryComponent?.loader) {
      continue;
    }
    const loader = entryComponent?.loader;
    const safeLoader = async (): Promise<[string, any]> => {
      try {
        const results = await loader(x, root, {
          paginationIndex: pageContext?.pageNum || 1,
          url: pageContext.url,
        });
        return [entryId, results];
      } catch (e) {
        globalObservability.getSentryClient().captureException?.(e, {
          data: {
            entryId,
          },
        });
        return [entryId, null];
      }
    };
    loaderPromises.push(safeLoader());
  }
  return loaderPromises;
}
