import LazyLoad from 'react-lazyload';

import { Button, Text } from '@opendoor/bricks-next';
import { Box, Flex, Image } from '@opendoor/bricks/core';

import { EntryComponent } from 'cms/entries/entries';

import { Desc, Subhead } from 'components/landing-pages-v2/shared/Typography';

import { ILpComponentImageCard } from 'declarations/contentful';

import Grid, { gridStyles } from './Grid';

const RenderImageCard = (entry: ILpComponentImageCard) => {
  const { fields } = entry;
  const desktopImageSourceUrl = fields?.desktopImage;
  const mobileImageSourceUrl = fields?.mobileImage;
  const mobileDescriptionDisplay = fields?.hideDescriptionOnMobile ? 'none' : 'block'; // By default, we hide description on mobile

  return (
    <Box analyticsName={`cosmos-landing-page-image-card-${fields?.analyticsId}`} trackImpression>
      <Grid
        // @ts-expect-error TODO - @growth update after brown95 is added to Novo theme
        backgroundColor="#F6F5F4"
      >
        <Flex
          gridColumn={gridStyles.colSpan12}
          flexDirection={['column', null, 'row', null, null]}
          width="100%"
          height={['495px', '590px', '460px', '712px']}
          my={fields?.verticalPadding === 'Small' ? [6, 8, 10] : [12, 10, 12]}
          borderRadius="roundedSmooth"
          overflow="hidden"
          backgroundColor="neutrals0"
        >
          <Flex
            // flex=1 needs to be in a wrapper Flex because padding
            // throws off the width distribution
            flex={[0.6, 1, 1, null, null]}
          >
            <Flex padding={[8, null, 9, 11, null]} flexDirection="column" justifyContent="start">
              {fields?.subtitle && (
                <Flex flexGrow={1} pb={[4, null, 'unset']}>
                  <Subhead>{fields.subtitle}</Subhead>
                </Flex>
              )}
              <Flex flexDirection="column">
                <Box
                  width="100%"
                  maxWidth={['197px', '249px', '269px', '417px']}
                  minHeight={['75px', '93px', '105px', '168px']}
                >
                  <Text
                    tag="h2"
                    typography="$headerSmall"
                    display="block"
                    mb={'$8x'}
                    color="$contentPrimary"
                    $largerThanMD={{ typography: '$headerLarge' }}
                  >
                    {fields.title}
                    {fields.secondaryTitle && (
                      <Text
                        tag="span"
                        typography="$headerSmall"
                        color="$contentTertiary"
                        $largerThanMD={{ typography: '$headerLarge' }}
                      >
                        {' '}
                        {fields.secondaryTitle}
                      </Text>
                    )}
                  </Text>
                </Box>
                <Desc
                  display={mobileDescriptionDisplay && 'none'}
                  $largerThanSM={{ display: 'block' }}
                  $largerThanMD={{ fontSize: 18 }}
                >
                  {fields.description}
                </Desc>
                {fields?.ctaLink && fields?.ctaDisplayText && (
                  <Button
                    variant="primary"
                    aria-label={`Go to page to ${fields?.ctaDisplayText}`}
                    analyticsName={`cosmos-landing-page-image-card-cta-${fields?.analyticsId}`}
                    onPress={() => fields?.ctaLink && window.location.assign(fields?.ctaLink)}
                    mt="$12x"
                    $largerThanSM={{
                      mt: '$16x',
                    }}
                    $largerThanMD={{
                      mt: '$24x',
                    }}
                    size="small"
                    width={180}
                    minHeight={48}
                    label={fields?.ctaDisplayText}
                  />
                )}
              </Flex>
            </Flex>
          </Flex>
          <Flex
            height={['45%', '50%', '100%', null, null]}
            flex={[0.4, 1, 1, null, null]}
            padding={[null, null, 5, 6]}
          >
            <LazyLoad offset={300}>
              <Image
                display={['block', null, 'none']}
                src={mobileImageSourceUrl}
                alt="illustration of a home"
                borderRadius={[null, null, 'semiRounded']}
                objectFit="cover"
                objectPosition="80%"
                width="100%"
                loading="lazy"
              />
              <Image
                display={['none', null, 'block']}
                src={desktopImageSourceUrl}
                alt="illustration of a home"
                borderRadius={[null, null, 'semiRounded']}
                objectFit="cover"
                objectPosition="80%"
                width="100%"
                loading="lazy"
              />
            </LazyLoad>
          </Flex>
        </Flex>
      </Grid>
    </Box>
  );
};

const ImageCard: EntryComponent<ILpComponentImageCard> = {
  render: RenderImageCard,
};

export default ImageCard;
