/* storybook-check-ignore */
import { useEffect, useState } from 'react';

// Periodically we want to load something after the window has loaded. This will
// do that, it also adds a buffer so it's definitely "afterLoad", these are for
// low-priority things.
export const useOnLoad = () => {
  const [isAfterLoad, setIsAfterLoad] = useState(
    // handle ssr
    typeof document === 'undefined' ? false : document.readyState === 'complete',
  );
  function onLoadStateChange() {
    setTimeout(() => setIsAfterLoad(true), 1000);
  }
  function handleLoadStateCleanup() {
    window.removeEventListener('load', onLoadStateChange);
  }
  useEffect(() => {
    if (isAfterLoad) {
      handleLoadStateCleanup();
      return;
    }
    window.addEventListener('load', onLoadStateChange);
    return handleLoadStateCleanup;
  }, [isAfterLoad]);
  return isAfterLoad;
};
