// storybook-check-ignore
export const CaretDownIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path d="M7 9L13 15L19 9" stroke="white" strokeWidth="2" />
  </svg>
);

export const CaretUpIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
    <path d="M19 15L13 9L7 15" stroke="white" strokeWidth="2" />
  </svg>
);

export const LoadingIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
    <circle opacity="0.2" cx="13" cy="13" r="12" stroke="currentColor" strokeWidth="2" />
    <path
      d="M13 0.999999C15.3734 0.999999 17.6935 1.70379 19.6668 3.02236C21.6402 4.34094 23.1783 6.21508 24.0866 8.4078C24.9948 10.6005 25.2324 13.0133 24.7694 15.3411C24.3064 17.6689 23.1635 19.8071 21.4853 21.4853"
      stroke="currentColor"
      strokeWidth="2"
    >
      <animateTransform
        attributeName="transform"
        attributeType="XML"
        type="rotate"
        from="0 13 13"
        to="360 13 13"
        dur="500ms"
        repeatCount="indefinite"
      />
    </path>
  </svg>
);
