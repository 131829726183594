/* storybook-check-ignore */
import { Image, ImageProps } from '@opendoor/bricks/core';
import { RadiiProps } from '@opendoor/bricks/system/theme';
import Head from 'next/head';

export type PictureSourceSpec = Array<{
  srcSet: string;
  media: string;
  key: string;
}>;

export const Picture: React.FC<{
  preload: boolean;
  lazy: boolean;
  radii: RadiiProps;
  sources: PictureSourceSpec;
  objectFit?: ImageProps['objectFit'];
  customStyles?: ImageProps['css'];
}> = ({ preload, lazy, radii, sources, objectFit = 'cover', customStyles }) => {
  return (
    <>
      {preload && (
        <Head>
          {sources.map(({ srcSet, media, key }) => {
            return (
              <link
                rel="preload"
                as="image"
                href=""
                // @ts-expect-error: react incorrectly capitalizes this if you use
                // the "typescript" suggestion of `imgSrcSet`
                imagesrcset={srcSet}
                media={media}
                key={`${key}-preload`}
                fetchpriority="high"
              />
            );
          })}
        </Head>
      )}
      <picture style={{ width: '100%' }}>
        {sources.map(({ srcSet, media, key }) => {
          return <source srcSet={srcSet} media={media} key={`${key}-source`}></source>;
        })}
        {/* Intentionally missing src, so we don't double load images */}
        <Image
          objectFit={objectFit}
          width={`100%`}
          height={`100%`}
          alt=""
          {...radii}
          loading={lazy ? 'lazy' : undefined}
          css={customStyles}
        />
      </picture>
    </>
  );
};

export type PresetPictureSet = Array<{
  minWidth: number;
  maxWidth: number | null;
  // List of preset values here: https://github.com/opendoor-labs/open_listings/blob/dd5e7230ae24d2e79181cb89485efa79aadee705/app/helpers/image_cdn.rb#L6
  preset: string;
  presetScale?: string;
}>;
export type PresetPictureSetOptions = {
  photoUrl: string;
  sizes: PresetPictureSet;
  radii: RadiiProps;
  debugImageSizes: boolean;
  lazy: boolean;
  dpr?: Array<number> | null;
  preload?: boolean;
  objectFit?: ImageProps['objectFit'];
};

export const generatePresetPictureSetProps = ({
  photoUrl,
  sizes,
  radii,
  lazy,
  dpr = [1, 2, 3],
  preload = false,
  objectFit,
  ...props
}: PresetPictureSetOptions) => {
  // sort in reverse because <source> matches in order, required to use
  // the min-width selectors
  const sortedSizes = sizes.sort((a, b) => (a.minWidth <= b.minWidth ? -1 : 1));
  const sources: Array<{ srcSet: string; media: string; key: string }> = sortedSizes.map(
    ({ minWidth, maxWidth, preset, presetScale }) => {
      const paramsRecord: Record<string, string> = {
        preset,
        ...(presetScale ? { presetScale } : {}),
        service: 'cosmos',
      };
      const srcSet =
        dpr && dpr?.length > 0
          ? dpr
              .map((d) => {
                const finalParams: Record<string, string> = { ...paramsRecord, dpr: `${d}` };
                return `${photoUrl}?${new URLSearchParams(finalParams).toString()} ${d}x`;
              })
              .join(', ')
          : `${photoUrl}?${new URLSearchParams(paramsRecord).toString()}`;
      const media =
        maxWidth === null
          ? `(min-width: ${minWidth}px)`
          : `(min-width: ${minWidth}px) and (max-width: ${maxWidth - 1}px)`;
      return { srcSet, media, key: `${photoUrl}-${minWidth}` };
    },
  );
  return {
    photoUrl,
    preload,
    lazy,
    radii,
    sources,
    objectFit,
    ...props,
  };
};

export const generatePresetPictureSet = (props: PresetPictureSetOptions) => {
  const { photoUrl, preload, lazy, radii, sources, objectFit } =
    generatePresetPictureSetProps(props);
  return (
    <Picture
      key={photoUrl}
      preload={preload}
      lazy={lazy}
      radii={radii}
      sources={sources}
      objectFit={objectFit}
    />
  );
};
