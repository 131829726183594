import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Text } from '@opendoor/bricks-next';
import { Box } from '@opendoor/bricks/core';
import { breakpoints } from '@opendoor/bricks/theme/eero';
import { motion } from 'framer-motion';

import { Awaited, EntryComponent, LoaderFn } from 'cms/entries/entries';

import { PressBox } from 'components/landing-pages-v2/HeroV2';

import { ILpComponentHeroCta } from 'declarations/contentful';

import {
  generatePresetPictureSet,
  generatePresetPictureSetProps,
  PictureSourceSpec,
  PresetPictureSet,
  PresetPictureSetOptions,
} from '../shared/performance/pictures';
import ContextualField from './ContextualField';
import { LPLink } from './shared/Buttons';
import Container from './shared/Container';
import ExtraCtas from './shared/ExtraCtas';
import { OpendoorLogo } from './shared/OpendoorLogo';
import { getComponentThemeColors } from './shared/styles/ComponentTheme';
import { Gutter, GutterSmall, OuterMargin, StackStyle } from './shared/styles/SharedStyles';
import { markdownToReactElement } from './shared/utils';

const HERO_IMAGE_SIZES: PresetPictureSet = [
  {
    minWidth: breakpoints[0],
    maxWidth: breakpoints[1],
    preset: 'square-256',
  },
  {
    minWidth: breakpoints[1],
    maxWidth: breakpoints[2],
    preset: 'square-512',
  },
  {
    minWidth: breakpoints[2],
    maxWidth: breakpoints[3],
    preset: 'square-512',
  },
  {
    minWidth: breakpoints[3],
    maxWidth: null,
    preset: 'square-1024',
  },
];

export const HERO_IMAGE_PROPS: Omit<PresetPictureSetOptions, 'photoUrl'> = {
  sizes: HERO_IMAGE_SIZES,
  debugImageSizes: false,
  lazy: false,
  preload: true,
  radii: {
    borderRadius: 'semiRounded',
  },
};

const DoorwayImageContainer = styled(Box)({
  height: '100%',
  objectFit: 'cover',
  objectPosition: 'center',
  width: '100%',
});

const ArchBox = styled(Box)({
  borderRadius: '99em 99em 16em 16em',
  fontSize: '100%',
});

const maxH = ['187px', null, null, '250px'];

const GridImageContainer = styled(Box)({
  objectAlign: 'center',
  objectFit: 'cover',
  width: '100%',
  height: '100%',
});

const styles = { ...StackStyle };

const showLogo = {
  start: {
    opacity: 0,
  },
  end: {
    opacity: 1,
    transition: {
      ease: 'linear',
      delay: 0,
      duration: 0.3,
    },
  },
};

const showArch = {
  start: {
    y: 0,
  },
  end: {
    y: '-100%',
    transition: {
      ease: [0.55, 0, 0.15, 1],
      delay: 0.35,
      duration: 1,
    },
  },
};

const showText = {
  start: {
    opacity: 0,
  },
  end: {
    opacity: 1,
    transition: {
      delay: 1.4,
      duration: 0.3,
      type: 'linear',
    },
  },
};

// Set default theme to generate button colors
const defaultTheme = getComponentThemeColors('White 0');

const DoorwayLayout = ({ entry }: { entry: ILpComponentHeroCta }) => {
  const { fields } = entry;
  const hasLogos = fields.pressLogos && fields.pressLogos?.length > 1;

  const showPressSection = fields.showPressSection;

  return (
    <Box className="lp-section" boxSizing="content-box" ml="auto" mr="auto" maxW="1440px">
      <Box
        display="grid"
        gap={Gutter}
        gridTemplate="1fr / 1fr"
        height={[580, null, null, 760]}
        overflow="hidden"
        position="relative"
        w="100%"
      >
        <Box display="grid" gridArea="1 / 1 / -1 / -1" placeItems="end center" zIndex="5">
          <Box
            as={motion.div}
            variants={showText}
            initial="start"
            animate="end"
            textAlign="center"
            pb={hasLogos ? Gutter : ['32px', null, '80px']}
            pl={OuterMargin}
            pr={OuterMargin}
          >
            <Text
              tag="h1"
              color="$backgroundPrimary"
              typography={fields.titleSize === 'Small' ? '$headerSmall' : '$headerMedium'}
              $largerThanSM={{
                typography: fields.titleSize === 'Small' ? '$headerMedium' : '$displaySmall',
              }}
              marginBottom={14}
              marginHorizontal="auto"
              // @ts-expect-error "ch" units aren't accepted by bricks-next but they're valid for web styles
              maxWidth={'16ch'}
            >
              {markdownToReactElement(fields.title || '')}
            </Text>
            {fields.desc && (
              <Text
                tag="p"
                color="$backgroundPrimary"
                typography="$bodyMedium"
                $largerThanMD={{
                  typography: '$bodyLarge',
                  marginHorizontal: 'auto',
                }}
                marginHorizontal="$10x"
                $smallerThanMD={{
                  // @ts-expect-error "ch" units aren't accepted by bricks-next but they're valid for web styles
                  maxWidth: '40ch',
                }}
              >
                {markdownToReactElement(fields.desc || '')}
              </Text>
            )}
            {((fields.ctaDisplayText && fields.ctaUrl) || fields.extraCtas) && (
              <Box
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
                gap={5}
                mt="0"
                mb={hasLogos ? '32px' : 0}
              >
                {fields.ctaDisplayText && fields.ctaUrl && (
                  <LPLink
                    aria-label={`Go to page ${fields.ctaDisplayText}`}
                    analyticsName={`cosmos-landing-page-cta-standalone-${fields?.analyticsId}`}
                    componentTheme={defaultTheme}
                    ctaStyle={fields.ctaStyle}
                    ctaDisplayText={fields.ctaDisplayText}
                    ctaUrl={fields.ctaUrl}
                    mt="0"
                  />
                )}
                {fields.extraCtas && (
                  <ExtraCtas ctas={fields.extraCtas} componentTheme={defaultTheme} />
                )}
              </Box>
            )}
            {fields.contextualField && (
              <Box display="flex" width="100%" justifyContent="center" mt={8}>
                <ContextualField
                  theme={defaultTheme}
                  contextFields={fields.contextualField}
                  inputLocation="hero-cta"
                />
              </Box>
            )}
          </Box>
        </Box>
        <Box
          as={motion.div}
          variants={showArch}
          initial="start"
          animate="end"
          gridArea="1 / 1 / -1 / -1"
          h="100%"
          w="100%"
          pointerEvents="none"
          position="absolute"
          top="0"
          zIndex="2"
        >
          <Box
            backgroundColor="white0"
            display="grid"
            placeItems="center"
            minH="100%"
            w="100%"
            position="relative"
          >
            <Box as={motion.div} variants={showLogo} initial="start" animate="end">
              <OpendoorLogo />
            </Box>
            <Box
              h="10px"
              w="100%"
              backgroundColor="white0"
              left="0"
              position="absolute"
              top="100%"
              transform="translateY(-8px)"
            />
            <svg
              style={{
                height: 'auto',
                left: 0,
                position: 'absolute',
                top: '100%',
                width: '100%',
              }}
              xmlns="http://www.w3.org/2000/svg"
              width="1442"
              height="737"
              viewBox="0 0 1442 737"
              fill="none"
            >
              <path
                d="M1442 0H0V737C0 338.803 322.803 16 721 16C1119.2 16 1442 338.803 1442 737V0Z"
                fill="#FFF"
              />
            </svg>
          </Box>
        </Box>
        <Box
          alignSelf="end"
          backgroundColor="warmgrey950"
          gridArea="1 / 1 / -1 / -1"
          h="100%"
          overflow="hidden"
          zIndex="1"
        >
          {fields.doorwayImageUrl && (
            <DoorwayImageContainer>
              {generatePresetPictureSet({
                ...HERO_IMAGE_PROPS,
                radii: {
                  borderRadius: 'none',
                },
                photoUrl: fields?.doorwayImageUrl,
              })}
            </DoorwayImageContainer>
          )}
        </Box>
      </Box>
      {!!showPressSection && (
        <Box as={motion.div} variants={showText} initial="start" animate="end">
          <PressBox width={['100%']} borderRadius={['unset']} />
        </Box>
      )}
    </Box>
  );
};

const CollageLayout = ({ entry }: { entry: ILpComponentHeroCta }) => {
  const { fields } = entry;
  const minH = 'calc(90vh - 56px)';

  const collageImageTopLeft = fields.collageImageUrlTopLeft;
  const collageImageBottomLeft = fields.collageImageUrlBottomLeft;
  const collageImageBottomRight = fields.collageImageUrlBottomRight;

  const showPressSection = fields.showPressSection;

  return (
    <Container>
      <Box
        display={[null, null, null, 'grid']}
        gap={Gutter}
        gridTemplateColumns="repeat(12, 1fr)"
        minH={[null, null, null, minH]}
      >
        <Box
          alignSelf={[null, null, null, 'center']}
          mb={7}
          __css={styles}
          gridColumn={['1 / 6', null, null, null, '1 / 5']}
        >
          <Text
            tag="h1"
            typography={fields.titleSize === 'Small' ? '$headerSmall' : '$headerMedium'}
            $largerThanSM={{
              typography: fields.titleSize === 'Small' ? '$headerMedium' : '$displaySmall',
              // @ts-expect-error max-width is required
              maxWidth: '15ch',
            }}
            marginBottom={24}
            css={css`
              line-height: 100%;
              letter-spacing: -2.56px;
            `}
          >
            {markdownToReactElement(fields.title || '')}
          </Text>
          {fields.desc && (
            <Text
              tag="p"
              typography={fields.titleSize === 'Small' ? '$bodyLarge' : '$bodyXlarge'}
              color="$contentPrimary"
              $largerThanMD={{
                typography: '$bodyLarge',
                // @ts-expect-error max width required
                maxWidth: '45ch',
              }}
            >
              {markdownToReactElement(fields.desc || '')}
            </Text>
          )}
          {(fields.ctaDisplayText || fields.ctaUrl || fields.extraCtas) && (
            <Box display="flex" flexWrap="wrap" alignItems="center" gap={5} mt={7}>
              {fields.ctaDisplayText && fields.ctaUrl && (
                <LPLink
                  aria-label={`Go to page ${fields.ctaDisplayText}`}
                  analyticsName={`cosmos-landing-page-cta-standalone-${fields?.analyticsId}`}
                  componentTheme={defaultTheme}
                  ctaStyle={fields.ctaStyle}
                  ctaDisplayText={fields.ctaDisplayText}
                  ctaUrl={fields.ctaUrl}
                  mt="0"
                />
              )}
              {fields.extraCtas && (
                <ExtraCtas ctas={fields.extraCtas} componentTheme={defaultTheme} />
              )}
            </Box>
          )}

          {/*
            // The ContextualField component is rendered twice to handle different placement based on contextual field values.
            // Depending on the values of fields.contextualField, the component will be rendered in different positions.
            // This allows for flexible rendering based on different contextual scenarios.
          */}
          {fields.contextualField && (
            <Box mt={8}>
              <ContextualField
                theme={defaultTheme}
                contextFields={fields.contextualField}
                inputLocation="hero-cta"
              />
            </Box>
          )}
        </Box>
        <Box
          display={[null, null, null, 'grid']}
          gridTemplateRows="repeat(2, 1fr)"
          gridColumn="6 / -1"
          alignSelf="center"
        >
          <Box
            display="grid"
            gap={[GutterSmall, null, Gutter]}
            gridRow="1 / 2"
            gridTemplateColumns={['1fr 108px', null, '1fr 208px']}
          >
            <ArchBox
              backgroundColor={'earth200'}
              display="grid"
              gridArea="1 / 2 / -1 / -1"
              placeItems={'center'}
              h={maxH}
              mb={[GutterSmall, null, Gutter]}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="34"
                height="49"
                viewBox="0 0 34 49"
                fill="none"
                css={css`
                  height: auto;
                  max-width: 100%;

                  @media (min-width: 480px) {
                    width: 48px;
                  }
                  @media (min-width: 992px) {
                    width: 64px;
                  }
                `}
              >
                <path
                  d="M24.4441 18.9269C24.4441 7.0532 22.4319 2.13138 16.8995 2.13138C12.0206 2.13138 9.55613 5.58163 9.55613 17.557C9.55613 29.3799 11.4675 34.2511 17.1006 34.2511C22.08 34.2511 24.4441 30.8513 24.4441 18.9269ZM0 18.6226C0 5.2772 9.10359 0 17.2013 0C25.8522 0 34 5.22662 34 17.8107C34 31.2573 24.8966 36.3825 16.7987 36.3825C8.14802 36.3825 0 30.8009 0 18.6226ZM32.2246 47.1186C31.3991 46.014 28.2194 41.7583 28.2194 41.7583C27.9211 41.359 27.4099 41.118 26.8612 41.118H7.13884C6.59022 41.118 6.07901 41.359 5.78067 41.7583L1.77607 47.1179C1.64903 47.2878 1.58162 47.4857 1.58162 47.688V48.5061C1.58162 48.7789 1.80076 49 2.07118 49H31.9289C32.1993 49 32.4185 48.7789 32.4185 48.5061V47.688C32.4185 47.4857 32.3516 47.2884 32.2246 47.1186Z"
                  fill="#9D9893"
                />
              </svg>
            </ArchBox>
            <Box gridArea="1 / 1 / -1 / 2" overflow="hidden" h={maxH}>
              {collageImageTopLeft && (
                <GridImageContainer>
                  {generatePresetPictureSet({
                    ...HERO_IMAGE_PROPS,
                    photoUrl: collageImageTopLeft,
                  })}
                </GridImageContainer>
              )}
            </Box>
          </Box>
          <Box
            display="grid"
            gap={[GutterSmall, null, Gutter]}
            gridRow="2 / -1"
            gridTemplateColumns={['1fr 166px', null, '1.7fr 1.3fr']}
          >
            <Box gridColumn="1 / 2" overflow="hidden" h={maxH}>
              {collageImageBottomLeft && (
                <GridImageContainer>
                  {generatePresetPictureSet({
                    ...HERO_IMAGE_PROPS,
                    photoUrl: collageImageBottomLeft,
                  })}
                </GridImageContainer>
              )}
            </Box>
            <Box gridColumn="2 / -1" overflow="hidden" h={maxH}>
              {collageImageBottomRight && (
                <GridImageContainer>
                  {generatePresetPictureSet({
                    ...HERO_IMAGE_PROPS,
                    photoUrl: collageImageBottomRight,
                  })}
                </GridImageContainer>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {!!showPressSection && (
        <Box marginTop="32px">
          <PressBox
            width={['100%', null, null, 'unset']}
            // @ts-expect-error we don't have 20px in novo.radii
            borderRadius={['20px', null]}
          />
        </Box>
      )}
    </Container>
  );
};

const RenderHeroCTA = (entry: ILpComponentHeroCta) => {
  const { fields } = entry;

  return fields.layout === 'Doorway' ? (
    <DoorwayLayout entry={entry} />
  ) : (
    <CollageLayout entry={entry} />
  );
};

type IHeroCTALoaderReturn = {
  preloadImages: PictureSourceSpec;
};

const heroCTALoader: LoaderFn<ILpComponentHeroCta, IHeroCTALoaderReturn> = async (input) => {
  const preloadImages: PictureSourceSpec = [];
  /*
    To optimize loading images required for this hero component, let's generate
    the picture source sets to use in preloading
  */
  // Doorway image
  if (input.fields.doorwayImageUrl) {
    const { sources } = generatePresetPictureSetProps({
      ...HERO_IMAGE_PROPS,
      photoUrl: input.fields.doorwayImageUrl,
    });
    preloadImages.push(...sources);
  }
  if (input.fields.collageImageUrlTopLeft) {
    const { sources } = generatePresetPictureSetProps({
      ...HERO_IMAGE_PROPS,
      photoUrl: input.fields.collageImageUrlTopLeft,
    });
    preloadImages.push(...sources);
  }
  if (input.fields.collageImageUrlBottomLeft) {
    const { sources } = generatePresetPictureSetProps({
      ...HERO_IMAGE_PROPS,
      photoUrl: input.fields.collageImageUrlBottomLeft,
    });
    preloadImages.push(...sources);
  }
  if (input.fields.collageImageUrlBottomRight) {
    const { sources } = generatePresetPictureSetProps({
      ...HERO_IMAGE_PROPS,
      photoUrl: input.fields.collageImageUrlBottomRight,
    });
    preloadImages.push(...sources);
  }

  const loaderReturn: IHeroCTALoaderReturn = {
    preloadImages,
  };

  return loaderReturn;
};

const HeroCTA: EntryComponent<ILpComponentHeroCta, Awaited<ReturnType<typeof heroCTALoader>>> = {
  render: RenderHeroCTA,
  loader: heroCTALoader,
};

export default HeroCTA;
