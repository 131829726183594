// storybook-check-ignore
export const useMortgageCalculator = ({
  homePrice,
  downPayment,
  interestRate,
  homeInsurance,
  propertyTax,
}: {
  homePrice: number;
  downPayment: number;
  interestRate: number;
  homeInsurance: number;
  propertyTax: number;
}) => {
  const interesteRatePerPeriod = Number(interestRate) / 100 / 12;

  const numberOfPayments = 30 * 12;

  //Insurance
  const insuranceDollarsValue = Math.floor(homeInsurance / 12);

  const propertyTaxInDollars = propertyTax;

  const propertyTaxDollarsValuePerMonth = Math.floor(propertyTaxInDollars / 12);

  const downPaymentInDollars = downPayment;

  const principalPerMonth =
    ((homePrice - downPaymentInDollars) *
      (interesteRatePerPeriod * Math.pow(1 + interesteRatePerPeriod, numberOfPayments))) /
    (Math.pow(1 + interesteRatePerPeriod, numberOfPayments) - 1);

  const validPrincipalPerMonth = isNaN(principalPerMonth) ? 0 : principalPerMonth;

  const roundedPrincipalPerMonth = Math.floor(validPrincipalPerMonth);

  const totalMonthlyPayment = Math.round(
    validPrincipalPerMonth + propertyTaxDollarsValuePerMonth + insuranceDollarsValue,
  );

  //Percentage breakdown
  const insuranceTotalPercentage = ((insuranceDollarsValue * 100) / totalMonthlyPayment).toFixed(1);
  const propertyTaxTotalPercentage = (
    (propertyTaxDollarsValuePerMonth * 100) /
    totalMonthlyPayment
  ).toFixed(1);
  const principalTotalPercentage = ((validPrincipalPerMonth * 100) / totalMonthlyPayment).toFixed(
    1,
  );

  return {
    totalMonthlyPayment,
    principalTotalPercentage,
    propertyTaxTotalPercentage,
    insuranceTotalPercentage,
    roundedPrincipalPerMonth,
    propertyTaxDollarsValuePerMonth,
    insuranceDollarsValue,
  };
};
