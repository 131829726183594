// storybook-check-ignore
import { useState } from 'react';

import { css } from '@emotion/react';
import { useAuth } from '@opendoor/auth-fe';
import { Box, Button, Text } from '@opendoor/bricks/core';
import { novo } from '@opendoor/bricks/theme';

import { CONSUMER_URL } from 'components/globals';

import { useObservability } from '../../../helpers/observability';
import { BWOD_MARKETS } from '../../shared/Markets';
import { CaretDownIcon, CaretUpIcon } from './icon';
import { defaultEaseCss } from './motion/MotionVariants';
import { IComponentThemeOptions } from './styles/ComponentTheme';

const markets = BWOD_MARKETS.map((m) => ({ label: m[0], value: m[1] }));

type Option = {
  label: string;
  value: string;
};

interface ListBoxProps {
  analyticsId: string;
  theme: IComponentThemeOptions;
  ctaDisplayText: string;
  children: React.ReactNode;
  listBoxButton?: React.ReactNode;
  listBoxInput?: React.ReactNode;
  showDropdown: boolean;
  onClick?: () => void;
}

const Options = ({
  height,
  maxHeight,
  children,
  as = 'div',
  id,
  role,
}: {
  theme: IComponentThemeOptions;
  height?: string;
  maxHeight?: string;
  children: React.ReactNode;
  as?: React.ElementType;
  id?: string;
  role?: 'listbox';
}) => {
  return maxHeight ? (
    <Box
      id={id}
      as={as}
      role={role}
      backgroundColor="white0"
      borderRadius="semiRounded"
      left="-4px"
      maxHeight={maxHeight}
      p={4}
      pb="70px"
      position="absolute"
      bottom="-4px"
      right="-4px"
      boxShadow="small"
      overflow="auto"
      css={css`
        overscroll-behavior-y: contain;
        list-style: none;
        margin: 0;
      `}
    >
      {children}
    </Box>
  ) : (
    <Box
      backgroundColor="white0"
      borderRadius="semiRounded"
      left="-4px"
      height={height}
      p={4}
      pb="70px"
      position="absolute"
      bottom="-4px"
      right="-4px"
      boxShadow="small"
      overflow="hidden"
    >
      <Box
        overflow="auto"
        position="absolute"
        top="0"
        bottom="78px"
        left="0"
        right="0"
        px="8px"
        css={css`
          overscroll-behavior-y: contain;
        `}
      >
        {children}
      </Box>
    </Box>
  );
};

const ListBox = ({
  analyticsId,
  ctaDisplayText,
  children,
  listBoxButton,
  listBoxInput,
  showDropdown,
  onClick,
}: ListBoxProps) => {
  return (
    <Box
      display="flex"
      flexDirection="column"
      w="100%"
      my={4}
      position="relative"
      maxW="450px"
      zIndex="500"
    >
      {showDropdown && children}
      <Box
        padding={2}
        paddingLeft={4}
        backgroundColor="white0"
        borderRadius="semiRounded"
        borderColor="blue100"
        borderWidth="1px"
        w="100%"
        borderStyle="solid"
        zIndex="3"
      >
        <Box display="grid" alignItems="center" gridTemplateColumns="1fr auto" gap="16px">
          <Box gridColumn="1 / 2">
            {listBoxInput ? (
              listBoxInput
            ) : (
              <Text
                color="warmgrey800"
                fontWeight="regular"
                fontSize="16px"
                letterSpacing="-1.4"
                lineHeight="130"
                pl="8px"
                textAlign="left"
              >
                {ctaDisplayText}
              </Text>
            )}
          </Box>
          <Box gridColumn="2 / -1">
            {listBoxButton ? (
              listBoxButton
            ) : (
              // height to avoid jumpiness with buy/sell toggle
              <Button
                onClick={() => onClick && onClick()}
                aria-label={`Go to page ${ctaDisplayText}`}
                analyticsName={`cosmos-landing-page-contextual-field-${analyticsId}`}
                height="61px"
                width="auto"
                overflow="hidden"
                _after={{
                  aspectRatio: '1 / 1',
                  backgroundColor: 'blue700',
                  content: '""',
                  display: 'block',
                  borderRadius: '99em 99em 10em 10em',
                  left: '0',
                  position: 'absolute',
                  top: '100%',
                  transform: 'scale(0.5)',
                  transition: `0.5s ${defaultEaseCss} transform`,
                  width: '100%',
                }}
                _hover={{
                  color: 'white0',
                  backgroundColor: 'blue600',
                }}
                _focusVisible={{
                  color: 'white0',
                  backgroundColor: 'blue700',
                }}
                _focus={{
                  outline: 0,
                }}
                _active={{
                  color: 'white0',
                  backgroundColor: 'blue700',
                  scale: 1,
                }}
                css={css`
                  transition: 0.3s ${defaultEaseCss} background-color;
                  will-change: transform;

                  &:after {
                    will-change: transform;
                  }

                  @media (hover: hover) and (pointer: fine) {
                    &:hover:after {
                      transform: translate3d(0, -100%, 0) scale(2);
                    }
                  }
                `}
              >
                <Box as="span" color="inherit" zIndex="5">
                  {showDropdown ? <CaretDownIcon /> : <CaretUpIcon />}
                </Box>
              </Button>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
ListBox.Options = Options;

export const RegionListBox = ({
  onSelect,
  theme,
}: {
  onSelect: (value: string) => void;
  theme: IComponentThemeOptions;
}) => {
  const { trackEvent } = useObservability();
  const { authentication } = useAuth();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isLoggedIn = authentication.state === 'authenticated';
  const onMarketSelect = (market?: string) => {
    trackEvent(
      'cta-click',
      'homes-hero-dropdown',
      { market },
      { categoryOverride: 'buy-and-sell' },
    );
    trackEvent(
      'market_dropdown_selected_conversion',
      'homes-hero-dropdown',
      {
        market,
      },
      { categoryOverride: 'cosmos_homes' },
    );
    onSelect(market || '');
    isLoggedIn
      ? window.open(`${CONSUMER_URL}/dashboard/buy/search`, '_self')
      : window.open(`/homes/${market}`, '_blank');
  };

  return (
    <ListBox
      showDropdown={isOpen}
      onClick={() => setIsOpen(!isOpen)}
      analyticsId="market-dropdown"
      theme={theme}
      ctaDisplayText="Pick your city or region"
    >
      <ListBox.Options theme={theme} height="420px">
        {markets.map((option: Option, i: number) => (
          <Button
            backgroundColor="white0"
            color="warmgrey950"
            fontWeight="regular"
            borderBottomWidth={i === markets.length - 1 ? 0 : '1px'}
            borderBottomStyle="solid"
            fontSize="16px"
            justifyContent="flex-start"
            padding={4}
            aria-label={`Go to page ${option.label}`}
            analyticsName="cosmos-landing-page-contextual-field-market-dropdown"
            onClick={() => onMarketSelect(option.value)}
            key={i}
            css={css`
              border-color: #efefef;
              border-radius: 0;
            `}
            _hover={{
              backgroundColor: novo.colors.white0,
              color: novo.colors.brandBlue600,
            }}
            _focus={{
              backgroundColor: novo.colors.white0,
              color: novo.colors.brandBlue600,
            }}
            _active={{
              scale: 1,
            }}
          >
            {option.label}
          </Button>
        ))}
      </ListBox.Options>
    </ListBox>
  );
};

interface LinkProps {
  label: string;
  href: string;
}

export const AgentSelectListBox = ({
  theme,
  linkOptions,
}: {
  theme: IComponentThemeOptions;
  linkOptions: LinkProps[];
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  let links;

  if (linkOptions?.length > 0) {
    links = linkOptions;
  } else {
    links = [
      {
        label: 'Sell to Opendoor',
        href: '/w/agents#alp-agents',
      },
      {
        label: 'Buy an Opendoor home',
        href: '/w/agents#alp-buy',
      },
      {
        label: 'Why agents use Opendoor',
        href: '/w/agents#alp-testimonials',
      },
    ];
  }

  const navigateOnButtonClick = (href: string) => {
    window && window.open(href, '_blank');
  };

  return (
    <ListBox
      showDropdown={isOpen}
      onClick={() => setIsOpen(!isOpen)}
      analyticsId="agent-select"
      theme={theme}
      ctaDisplayText="For Agents"
    >
      <ListBox.Options theme={theme} height="248px">
        {links.map((option: { label: string; href: string }, i: number) => (
          <Button
            key={i}
            backgroundColor="neutrals0"
            color="neutrals90"
            fontWeight="regular"
            borderBottomWidth={i === linkOptions.length - 1 ? 0 : '1px'}
            borderBottomStyle="solid"
            borderBottomColor="neutrals90"
            fontSize="16px"
            justifyContent="flex-start"
            padding={4}
            aria-label={`Go to page ${option.label}`}
            analyticsName={`cosmos-agents-sell-to-opendoor-${option.label
              .toLowerCase()
              .replace(' ', '-')}`}
            onClick={() => navigateOnButtonClick(option.href)}
            css={css`
              border-color: #efefef;
              border-radius: 0;
            `}
            _hover={{
              backgroundColor: novo.colors.white0,
              color: novo.colors.brandBlue600,
            }}
            _focus={{
              backgroundColor: novo.colors.white0,
              color: novo.colors.brandBlue600,
            }}
            _active={{
              scale: 1,
            }}
          >
            {option.label}
          </Button>
        ))}
      </ListBox.Options>
    </ListBox>
  );
};

export const SupportListBox = ({ theme }: { theme: IComponentThemeOptions }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const linkOptions = [
    {
      label: 'Email',
      text: 'support@opendoor.com',
      href: 'mailto:support@opendoor.com',
    },
    {
      label: 'Call',
      text: '1-888-352-7075',
      href: 'tel:+18883527075',
    },
    {
      label: 'Visit',
      text: 'Opendoor Help Center',
      href: 'https://help.opendoor.com',
    },
  ];

  const navigateOnButtonClick = (href: string) => {
    window && window.open(href, '_blank');
  };

  return (
    <ListBox
      showDropdown={isOpen}
      onClick={() => setIsOpen(!isOpen)}
      analyticsId="agent-select"
      theme={theme}
      ctaDisplayText="How can we help?"
    >
      <ListBox.Options theme={theme} height="248px">
        {linkOptions.map((option: { label: string; href: string; text: string }, i: number) => (
          <Button
            key={i}
            backgroundColor="white0"
            sx={{
              borderRadius: 'none',
            }}
            color="neutrals90"
            fontWeight="regular"
            borderBottomWidth={i === linkOptions.length - 1 ? 0 : '1px'}
            borderBottomStyle="solid"
            borderBottomColor="neutrals90"
            fontSize="16px"
            justifyContent="flex-start"
            padding={4}
            aria-label={`Go to page ${option.label}`}
            analyticsName={`cosmos-agents-sell-to-opendoor-${option.label
              .toLowerCase()
              .replace(' ', '-')}`}
            onClick={() => navigateOnButtonClick(option.href)}
            css={css`
              border-color: #efefef;
              border-radius: 0;
            `}
            _hover={{
              backgroundColor: novo.colors.white0,
              color: novo.colors.brandBlue600,
            }}
            _focus={{
              backgroundColor: novo.colors.white0,
              color: novo.colors.brandBlue600,
            }}
            _active={{
              scale: 1,
            }}
          >
            <Text fontWeight="medium">{option.label}:</Text> &nbsp;{option.text}
          </Button>
        ))}
      </ListBox.Options>
    </ListBox>
  );
};

export default ListBox;
