import ExternalLinks from 'components/articles-v2/topicPage/ExternalLinks';
import Calculator from 'components/landing-pages-v2/Calculator';
import OpenPositions from 'components/landing-pages-v2/OpenPositions';
import Reviews from 'components/landing-pages-v2/reviews';

import BasicPageHeader from '../../components/landing-pages-v2/BasicPageHeader';
import CarouselCards from '../../components/landing-pages-v2/CarouselSection/CarouselCards';
import ComparisonTable from '../../components/landing-pages-v2/ComparisonTable';
import ContactSection from '../../components/landing-pages-v2/ContactSection';
import CTAStandalone from '../../components/landing-pages-v2/CTAStandalone';
import FAQsDisplay from '../../components/landing-pages-v2/FAQsDisplay';
import Feature5050 from '../../components/landing-pages-v2/Feature5050';
import HeaderStandalone from '../../components/landing-pages-v2/HeaderStandalone';
import HeroCTA from '../../components/landing-pages-v2/HeroCTA';
import HeroV2 from '../../components/landing-pages-v2/HeroV2';
import HeroV2WithMarkers from '../../components/landing-pages-v2/HeroV2WithMarkers';
import ImageCard from '../../components/landing-pages-v2/ImageCard';
import LinkList from '../../components/landing-pages-v2/LinkList';
import List from '../../components/landing-pages-v2/List';
import LWCHeroCard from '../../components/landing-pages-v2/LWCHeroCard';
import LWCImageCard from '../../components/landing-pages-v2/LWCImageCard';
import LWCTextSection from '../../components/landing-pages-v2/LWCTextSection';
import MapBackgroundHero from '../../components/landing-pages-v2/MapBackgroundHero';
import Multicard from '../../components/landing-pages-v2/Multicard';
import Quotation from '../../components/landing-pages-v2/Quotation';
import Spacer from '../../components/landing-pages-v2/Spacer';
import StandaloneText from '../../components/landing-pages-v2/StandaloneText';
import TimeSavingsCalculator from '../../components/landing-pages-v2/TimeSavingsCalculator';
import IEntryLookup from './entries';

const landingPageV2EntryLookup: IEntryLookup = {
  block: {
    lpComponentMapBackgroundHero: MapBackgroundHero,
    lpComponentCarouselCards: CarouselCards,
    lpComponentImageCard: ImageCard,
    lpComponentFaqDisplay: FAQsDisplay,
    lpComponentHeroV2: HeroV2,
    lpComponentContactSection: ContactSection,
    lpComponentLwcTextSection: LWCTextSection,
    lpComponentLwcHeroCard: LWCHeroCard,
    lpComponentLwcImageCard: LWCImageCard,
    lpComponentHeroV2WithMarkers: HeroV2WithMarkers,
    lpComponentCTAStandalone: CTAStandalone,
    lpComponentComparisonTable: ComparisonTable,
    lpComponentFeature5050: Feature5050,
    lpComponentHeaderStandalone: HeaderStandalone,
    lpComponentHeroCta: HeroCTA,
    lpComponentList: List,
    lpComponentStandaloneText: StandaloneText,
    lpComponentMulticard: Multicard,
    lpComponentBasicPageHeader: BasicPageHeader,
    lpComponentLinkList: LinkList,
    lpComponentQuotation: Quotation,
    lpComponentSpacer: Spacer,
    lpComponentOpenPositions: OpenPositions,
    lpComponentOpendoorReviews: Reviews,
    lpComponentCalculator: Calculator,
    lpComponentTimeSavingsCalculator: TimeSavingsCalculator,
    componentRelatedExternalLinks: ExternalLinks,
  },
  inline: {},
};

export default landingPageV2EntryLookup;
