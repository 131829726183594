import { useEffect, useState } from 'react';

import { useAuth } from '@opendoor/auth-fe';
import { Box, Button, Dialog, useMedia } from '@opendoor/bricks-next';

import { ILpComponentGetCashOfferModalFields } from 'declarations/contentful';

const GetCashOfferModal = (entry: ILpComponentGetCashOfferModalFields) => {
  const { authentication } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (authentication.state === 'not_authenticated') {
      const hasShownDialog = localStorage.getItem('getCashOfferModalShown');

      if (!hasShownDialog) {
        timer = setTimeout(() => {
          setIsOpen(true);
          localStorage.setItem('getCashOfferModalShown', 'true');
        }, 400);
      }
    }
    return () => clearTimeout(timer);
  }, [authentication.state]);

  const handleClose = () => {
    setIsOpen(!isOpen);
  };
  const analyticsName = `cosmos-landing-page-visitor-modal-${entry?.analyticsId}`;
  const { largerThanSM } = useMedia();

  return (
    <Dialog
      isOpen={isOpen}
      dismissable={largerThanSM ? true : false}
      animation="accelerateDecelerateFast"
      setIsOpen={handleClose}
    >
      <Box maxWidth={504}>
        <Dialog.Content
          title={entry.title}
          titleProps={{
            typography: '$subheaderLarge',
            $largerThanSM: { typography: '$headerLarge' },
          }}
          description={entry.description}
        >
          <Box $largerThanSM={{ pt: '$24x' }} pt="$16x">
            {!largerThanSM && (
              <Button
                variant="secondary"
                size="small"
                analyticsName={analyticsName}
                label="Close"
                onPress={handleClose}
              />
            )}
            <Button
              marginTop="$4x"
              variant="primary"
              size="small"
              $largerThanSM={{ size: 'medium' }}
              onPress={() => {
                document.location.href = 'https://www.opendoor.com/address-entry';
              }}
              analyticsName={`${analyticsName}-cash-offer-cta`}
              label="Get a cash offer"
            />
          </Box>
        </Dialog.Content>
      </Box>
    </Dialog>
  );
};

export default GetCashOfferModal;
