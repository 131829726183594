/* storybook-check-ignore */
// An easy source of truth for market functionality can be found here:
// https://control.ops.opendoor.com/types
// It would be *great* to hook this up to pull from a service at some point!
import { COSMOS_URL } from '../globals';

function strcmp(a: string[], b: string[]): number {
  return a[0] < b[0] ? -1 : a[0] > b[0] ? 1 : 0;
}

// TODO: Drop the use of this constant in favor of using the market data stored in web or PBDB
export const BWOD_MARKETS = [
  ['Albuquerque, NM', 'albuquerque'],
  ['Atlanta, GA', 'atlanta'],
  ['Austin, TX', 'austin'],
  ['Birmingham, AL', 'birmingham'],
  ['Boise, ID', 'boise'],
  ['Boston, MA', 'boston'],
  ['Charleston, SC', 'charleston'],
  ['Charlotte, NC', 'charlotte'],
  ['Chattanooga, GA/TN', 'chattanooga'],
  ['Cincinnati, OH', 'cincinnati'],
  ['Cleveland, OH', 'cleveland'],
  ['Colorado Springs, CO', 'colorado-springs'],
  ['Columbia, SC', 'columbia'],
  ['Columbus, OH', 'columbus'],
  ['Corpus Christi, TX', 'corpus-christi'],
  ['Dallas-Fort Worth, TX', 'dallas-fort-worth'],
  ['Denver, CO', 'denver'],
  ['Detroit, MI', 'detroit'],
  ['Greensboro, NC', 'greensboro'],
  ['Greenville, SC', 'greenville'],
  ['Houston, TX', 'houston'],
  ['Indianapolis, IN', 'indianapolis'],
  ['Jacksonville, FL', 'jacksonville'],
  ['Kansas City, KS/MO', 'kansas-city'],
  ['Killeen, TX', 'killeen'],
  ['Knoxville, TN', 'knoxville'],
  ['Las Vegas, NV', 'las-vegas'],
  ['Los Angeles, CA', 'los-angeles'],
  ['Miami, FL', 'miami'],
  ['Minneapolis-Saint Paul, MN', 'minneapolis-saint-paul'],
  ['Nashville, TN', 'nashville'],
  ['New York / New Jersey, NY', 'new-york-new-jersey '],
  ['Northern Colorado, CO', 'northern-colorado'],
  ['Oklahoma City, OK', 'oklahoma-city'],
  ['Orlando, FL', 'orlando'],
  ['Phoenix, AZ', 'phoenix'],
  ['Portland, OR', 'portland'],
  ['Prescott, AZ', 'prescott'],
  ['Raleigh-Durham, NC', 'raleigh-durham'],
  ['Richmond, VA', 'richmond'],
  ['Riverside, CA', 'riverside'],
  ['Sacramento, CA', 'sacramento'],
  ['Salt Lake City, UT', 'salt-lake-city'],
  ['San Antonio, TX', 'san-antonio'],
  ['San Diego, CA', 'san-diego'],
  ['San Francisco Bay Area, CA', 'san-francisco-bay-area'],
  ['Southwest, FL', 'southwest-florida'],
  ['St. Louis, MO', 'st-louis'],
  ['Tampa, FL', 'tampa'],
  ['Tucson, AZ', 'tucson'],
  ['Washington, DC', 'washington-dc'],
].sort(strcmp);

// TODO: Drop the use of this constant in favor of using the market data stored in web or PBDB
export const MARKETS = [...BWOD_MARKETS, ['Asheville, NC', 'asheville'], ['Reno, NV', 'reno']].sort(
  strcmp,
);

export const MARKET_GUIDES: { [key: string]: string } = {
  phoenix: `${COSMOS_URL}/homes/guides/market/phoenix`,
  'dallas-fort-worth': `${COSMOS_URL}/homes/guides/market/dfw`,
  'los-angeles': `${COSMOS_URL}/homes/guides/market/los-angeles`,
  austin: `${COSMOS_URL}/homes/guides/market/austin`,
  'raleigh-durham': `${COSMOS_URL}/homes/guides/market/raleigh`,
  charlotte: `${COSMOS_URL}/homes/guides/market/charlotte`,
};

export default MARKETS;
