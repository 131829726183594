import { useInView } from 'react-intersection-observer';

import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { useAuth } from '@opendoor/auth-fe';
import { Text } from '@opendoor/bricks-next';
import { Box, Flex, FlexProps, Heading } from '@opendoor/bricks/core';
import { breakpoints } from '@opendoor/bricks/theme/eero';
import { Entry } from 'contentful';
import { useRouter } from 'next/router';

import { Desc } from 'components/landing-pages-v2/shared/Typography';

import { Awaited, EntryComponent, LoaderFn } from '../../cms/entries/entries';
import { ILandingPageFields, ILpComponentHeroV2 } from '../../declarations/contentful';
import { getMarketDisplayNameForHeroV2 } from '../../helpers/markets';
import AddressSearch from '../shared/AddressSearch';
import {
  generatePresetPictureSet,
  generatePresetPictureSetProps,
  PictureSourceSpec,
  PresetPictureSet,
  PresetPictureSetOptions,
} from '../shared/performance/pictures';
import Grid, { gridStyles } from './Grid';
import { StickyCTAUtility } from './StickyCTAUtility/StickyCTAUtility';

const HERO_DESKTOP_IMAGE_SIZES: PresetPictureSet = [
  {
    minWidth: breakpoints[2],
    maxWidth: breakpoints[3],
    preset: 'square-512',
  },
  {
    minWidth: breakpoints[3],
    maxWidth: null,
    preset: 'square-1024',
  },
];

const HERO_MOBILE_IMAGE_SIZES: PresetPictureSet = [
  {
    minWidth: breakpoints[0],
    maxWidth: breakpoints[1],
    preset: 'square-256',
  },
  {
    minWidth: breakpoints[1],
    maxWidth: breakpoints[2],
    preset: 'square-512',
  },
];

export const HERO_IMAGE_DESKTOP_PROPS: Omit<PresetPictureSetOptions, 'photoUrl'> = {
  sizes: HERO_DESKTOP_IMAGE_SIZES,
  debugImageSizes: false,
  lazy: false,
  preload: true,
  radii: {
    borderTopStartRadius: 'none',
    borderBottomStartRadius: 'none',
    borderTopEndRadius: 'none',
    borderBottomEndRadius: 'none',
  },
};

export const HERO_IMAGE_MOBILE_PROPS: Omit<PresetPictureSetOptions, 'photoUrl'> = {
  sizes: HERO_MOBILE_IMAGE_SIZES,
  debugImageSizes: false,
  lazy: false,
  preload: true,
  radii: {
    borderTopStartRadius: 'none',
    borderBottomStartRadius: 'none',
    borderTopEndRadius: 'none',
    borderBottomEndRadius: 'none',
  },
};

const HeroImageContainer = styled(Box)({
  position: 'relative',
  width: '100%',
  height: '100%',
  objectFit: 'cover',
  borderRadius: '540px 540px 0px 0px',
  aspectRatio: '4 / 5',
  '@media screen and (min-width: 769px)': {
    borderRadius: '540px 540px 36px 36px',
  },
  '@media (min-width: 1336px)': {
    maxHeight: '804px',
  },
  overflow: 'hidden',
});

const FORBES_LOGO_URL =
  'https://images.opendoor.com/source/s3/imgdrop-production/2023-07-1690500903565-83391.png';

const LOGO_SIZES: PresetPictureSet = [
  {
    minWidth: breakpoints[0],
    maxWidth: breakpoints[1],
    preset: 'square-256',
  },
  {
    minWidth: breakpoints[1],
    maxWidth: breakpoints[2],
    preset: 'square-512',
  },
  {
    minWidth: breakpoints[2],
    maxWidth: breakpoints[3],
    preset: 'square-512',
  },
  {
    minWidth: breakpoints[3],
    maxWidth: null,
    preset: 'square-1024',
  },
];

const FORTUNE_LOGO_URL =
  'https://images.opendoor.com/source/s3/imgdrop-production/2023-07-1690500903560-61291.png';

const FORTUNE_LOGO_SIZES = [
  { w: 82, h: 19 },
  { w: 82, h: 19 },
  { w: 64, h: 18 },
  { w: 75, h: 20 },
  { w: 75, h: 20 },
];

const NEW_YORK_TIMES_LOGO_URL =
  'https://images.opendoor.com/source/s3/imgdrop-production/2023-07-1690500903555-88900.png';

const NEW_YORK_TIMES_LOGO_SIZES = [
  { w: 157, h: 230 },
  { w: 157, h: 230 },
  { w: 140, h: 18 },
  { w: 145, h: 20 },
  { w: 145, h: 20 },
];

const WALL_STREET_JOURNAL_LOGO_URL =
  'https://images.opendoor.com/source/s3/imgdrop-production/2023-07-1690500903562-10942.png';

const WALL_STREET_JOURNAL_LOGO_SIZES = [
  { w: 38, h: 20 },
  { w: 38, h: 20 },
  { w: 140, h: 18 },
  { w: 145, h: 20 },
  { w: 145, h: 20 },
];

export const PRESS_LOGOS = [
  { photoUrl: FORTUNE_LOGO_URL, sizes: FORTUNE_LOGO_SIZES },
  { photoUrl: NEW_YORK_TIMES_LOGO_URL, sizes: NEW_YORK_TIMES_LOGO_SIZES },
  { photoUrl: WALL_STREET_JOURNAL_LOGO_URL, sizes: WALL_STREET_JOURNAL_LOGO_SIZES },
];

const RenderHeroV2 = (
  entry: ILpComponentHeroV2,
  resolvedData?: Awaited<ReturnType<typeof heroV2Loader>>,
) => {
  const { fields } = entry;
  const { authentication } = useAuth();
  const hideStickyCta =
    resolvedData?.stickyCTA === 'None' || authentication.state === 'authenticated';
  const router = useRouter();
  const headlineMarketIdentifier = router.query.headline_market_name as string | undefined;
  const headlineMarketDisplayName = getMarketDisplayNameForHeroV2(headlineMarketIdentifier);
  const displayMarketNameInHeadline = headlineMarketDisplayName && fields.showMarketNameInHeadline;
  const headline =
    fields?.title + (displayMarketNameInHeadline ? ` in ${headlineMarketDisplayName}` : '');

  const headlineStylesDesktop = displayMarketNameInHeadline
    ? css`
        @media (min-width: 1049px) {
          line-height: 100%;
          font-size: 80px;
          letter-spacing: -3.264px;
          text-wrap: balance;
        }
      `
    : css`
        @media (min-width: 1049px) {
          line-height: 90%;
          font-size: 96px;
          letter-spacing: -3.264px;
          text-wrap: wrap;
        }
      `;
  const {
    ref: addressEntryRef,
    inView: addressEntryInView,
    entry: intersectionObserverEntry,
  } = useInView();
  // For the first intersection observer event, the entry is undefined
  // as the element loads. We want to make sure not to flash the sticky
  // cta while it loads for the first time.
  const showStickyCTAUtility = Boolean(intersectionObserverEntry) && !addressEntryInView;

  return (
    <Box
      analyticsName={`cosmos-landing-page-hero-v2-${fields?.analyticsId}`}
      trackImpression
      inViewThreshold={0.3}
    >
      {!hideStickyCta && (
        <StickyCTAUtility analyticsId={fields?.analyticsId} isVisible={showStickyCTAUtility} />
      )}
      <Grid mr={[null, null, 9, 11, 11]} ml={[null, null, 9, 11, 11]}>
        <Box gridColumn={gridStyles.colSpan12}>
          <Flex
            zIndex="1"
            flexDirection={['column', null, 'row']}
            justifyContent="space-between"
            width="100%"
            columnGap={[null, null, 4]}
            position="relative"
          >
            <Flex
              id="title-and-address-entry"
              flexDirection="column"
              width={['100%', null, '50%']}
              mb={[10, null, 8]}
              px={[6, 6, 0]}
            >
              <Flex
                marginTop={[6, null, '104px']}
                flexDirection="column"
                marginBottom={[4, null, 8]}
                marginLeft={0}
                width="100%"
                alignItems={['center', null, 'unset']}
              >
                <Heading
                  as="h1"
                  color="neutrals100"
                  fontWeight="medium"
                  marginBottom={[0, null, 3]}
                  textAlign={['center', null, 'left']}
                  maxWidth={['275px', '275px', '480px', '543px']}
                  css={css`
                    line-height: 100%;
                    font-size: 44px;
                    letter-spacing: -1.32px;
                    @media (min-width: 376px) and (max-width: 768px) {
                      font-size: 44px;
                      line-height: 100%;
                      letter-spacing: -1.32px;
                    }
                    @media (min-width: 769px) and (max-width: 1048px) {
                      font-size: 56px;
                      line-height: 100%;
                      letter-spacing: -1.904px;
                    }
                    @media (min-width: 789px) and (max-width: 1048px) {
                      max-width: 320px;
                    }
                    ${headlineStylesDesktop}
                  `}
                >
                  {headline}
                </Heading>
                <Desc
                  textAlign="center"
                  maxWidth={235}
                  mt={10}
                  $largerThanSM={{ textAlign: 'left', maxWidth: 211 }}
                  $largerThanMD={{ fontSize: 24, maxWidth: 313 }}
                >
                  {fields?.subtitle}
                </Desc>
              </Flex>
              <Box visibility="hidden" ref={addressEntryRef} />
              <Flex justifyContent={['center', null, 'flex-start']}>
                {fields?.showAddressInputCta && (
                  <Box
                    width="100%"
                    paddingTop={[6, null, 'unset']}
                    maxWidth={[null, '420px', '418px', '435px', '478px']}
                    minWidth={[null, null, '336px', '435px', '478px']}
                    zIndex={10}
                    className="hp-reskin-v2-address-entry-wrapper"
                  >
                    <Box id="desktop-address-search" display={['none', null, null, 'block']}>
                      <AddressSearch
                        hideLabel
                        inputLocation="hero-cta"
                        analyticsPrefix={`cosmos-landing-page-hero-v2${
                          fields?.analyticsId ? `-${fields?.analyticsId}` : ''
                        }`}
                        trackingTaxonomy="new_homepage"
                        actionText={fields?.addressCtaText || 'Get cash offer'}
                        actionTextSmall={fields?.addressCtaTextSmall || 'Get offer'}
                        placeholderText={fields?.addressCtaPlaceholderText}
                        showShadow={false}
                      />
                    </Box>
                    <Box id="mobile-address-search" display={['block', null, null, 'none']}>
                      <AddressSearch
                        hideLabel
                        inputLocation="hero-cta"
                        analyticsPrefix={`cosmos-landing-page-hero-v2${
                          fields?.analyticsId ? `-${fields?.analyticsId}` : ''
                        }`}
                        trackingTaxonomy="new_homepage"
                        actionText={fields?.addressCtaText || 'Get cash offer'}
                        actionTextSmall={fields?.addressCtaTextSmall || 'Get offer'}
                        placeholderText={fields?.addressCtaPlaceholderTextSmall}
                        showShadow={false}
                      />
                    </Box>
                  </Box>
                )}
              </Flex>
            </Flex>
            <Flex
              width={['100%', null, '50%', '55%']}
              alignItems="center"
              justifyContent={['center', null, 'flex-end']}
              minWidth={[null, null, '350px', null]}
            >
              {/* Desktop hero image */}
              <Flex
                position="relative"
                height={['431px', '543px', 'unset']}
                width="100%"
                alignItems="flex-end"
                display={['none', 'none', 'block', 'block', 'block']}
              >
                {fields?.imageDesktopUrl && (
                  <HeroImageContainer>
                    {generatePresetPictureSet({
                      ...HERO_IMAGE_DESKTOP_PROPS,
                      photoUrl: fields?.imageDesktopUrl,
                    })}
                  </HeroImageContainer>
                )}
              </Flex>
              {/* Mobile hero image */}
              <Flex
                position="relative"
                width="100%"
                alignItems="flex-end"
                display={['block', 'block', 'none', 'none', 'none']}
              >
                {fields?.imageMobileUrl && (
                  <HeroImageContainer>
                    {generatePresetPictureSet({
                      ...HERO_IMAGE_MOBILE_PROPS,
                      photoUrl: fields?.imageMobileUrl,
                    })}
                  </HeroImageContainer>
                )}
              </Flex>
            </Flex>
            {fields?.showPressSection && (
              <Box
                id="desktop-press-box"
                width="555px" // hack to prevent "featured in" from wrapping above the Press logos
                display={['none', null, 'block']}
                left="50%"
                transform="translate(-50%)"
                position="absolute"
                bottom={[null, null, '15px', '40px']}
                css={
                  displayMarketNameInHeadline
                    ? css`
                        @media (min-width: 768px) and (max-width: 1250px) {
                          bottom: -45px;
                        }
                      `
                    : css`
                        @media (min-width: 768px) and (max-width: 868px) {
                          bottom: -45px;
                        }
                        @media (min-width: 867px) and (max-width: 968px) {
                          bottom: -15px;
                        }
                        @media (max-width: 1048px) and (max-height: 672px) {
                          top: 74vh;
                        }
                        @media (min-width: 1049px) and (max-height: 864px) {
                          top: 76vh;
                        }
                        @media (min-width: 1280px) and (max-height: 864px) {
                          top: 78vh;
                        }
                      `
                }
              >
                <PressBox />
              </Box>
            )}
          </Flex>
        </Box>
        {fields?.showPressSection && (
          <Box
            id="mobile-press-box"
            gridColumn={gridStyles.colSpan12}
            display={['block', null, 'none']}
          >
            <PressBox />
          </Box>
        )}
      </Grid>
    </Box>
  );
};

export const PressBox = (props: FlexProps) => {
  return (
    <Flex justifyContent="center">
      <Flex
        flexWrap="wrap"
        columnGap={[4, 5, 6]}
        p={[4, 5, 6]}
        alignItems="center"
        justifyContent="center"
        style={{
          backgroundColor: 'rgba(242, 241, 240, 0.64)',
          backdropFilter: 'blur(20px)',
        }}
        // @ts-expect-error we don't have 20px in novo.radii
        borderRadius={['unset', 'unset', '20px']}
        height={['160px', '94px', 'unset']}
        width={['100%', null, 'unset']}
        css={css`
          @media (min-width: 375px) and (max-width: 562px) {
            height: 144px;
          }
        `}
        {...props}
      >
        <Text
          tag="span"
          typography="$bodyMedium"
          color="$contentStateInactivePrimary"
          $smallerThanMD={{ width: '100%', textAlign: 'center', paddingBottom: '$4x' }}
        >
          Featured in:
        </Text>
        <Flex
          maxWidth={['240px', 'unset']}
          rowGap={6}
          columnGap={[4, 7, 6]}
          flexWrap="wrap"
          alignItems="center"
          justifyContent={['space-between', 'center']}
          css={css`
            @media (min-width: 375px) and (max-width: 562px) {
              max-width: 280px;
              justify-content: space-between;
            }
          `}
        >
          <Box
            width={['77px', '77px', '75px']}
            height="auto"
            order={[1, 2]}
            css={css`
              @media (min-width: 375px) and (max-width: 562px) {
                order: 1;
              }
            `}
          >
            {generatePresetPictureSet({
              photoUrl: FORBES_LOGO_URL,
              sizes: LOGO_SIZES,
              debugImageSizes: false,
              radii: {},
              preload: true,
              lazy: false,
            })}
          </Box>
          <Box width={['82px', '82px', '78px']} order={[2, 4]}>
            {generatePresetPictureSet({
              photoUrl: FORTUNE_LOGO_URL,
              sizes: LOGO_SIZES,
              debugImageSizes: false,
              radii: {},
              preload: true,
              lazy: false,
            })}
          </Box>
          <Box width={['157px', '157px', '155px']} order={[3, 1]}>
            {generatePresetPictureSet({
              photoUrl: NEW_YORK_TIMES_LOGO_URL,
              sizes: LOGO_SIZES,
              debugImageSizes: false,
              radii: {},
              preload: true,
              lazy: false,
            })}
          </Box>
          <Box width={['38px', '38px', '38px']} order={[4, 3]}>
            {generatePresetPictureSet({
              photoUrl: WALL_STREET_JOURNAL_LOGO_URL,
              sizes: LOGO_SIZES,
              debugImageSizes: false,
              radii: {},
              preload: true,
              lazy: false,
            })}
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
};

type IHeroV2LoaderReturn = {
  preloadImages: PictureSourceSpec;
  stickyCTA: string | undefined;
};

const heroV2Loader: LoaderFn<ILpComponentHeroV2, IHeroV2LoaderReturn> = async (
  input,
  root?: Entry<ILandingPageFields>,
) => {
  const preloadImages: PictureSourceSpec = [];
  /*
    To optimize loading images required for this hero component, let's generate
    the picture source sets to use in preloading
  */
  // Hero image
  if (input.fields.imageDesktopUrl) {
    const { sources } = generatePresetPictureSetProps({
      ...HERO_IMAGE_DESKTOP_PROPS,
      photoUrl: input.fields.imageDesktopUrl,
    });
    preloadImages.push(...sources);
  }
  if (input.fields.imageMobileUrl) {
    const { sources } = generatePresetPictureSetProps({
      ...HERO_IMAGE_MOBILE_PROPS,
      photoUrl: input.fields.imageMobileUrl,
    });
    preloadImages.push(...sources);
  }

  const loaderReturn: IHeroV2LoaderReturn = {
    preloadImages,
    stickyCTA: root?.fields?.stickyCta || '',
  };

  return loaderReturn;
};

const HeroV2: EntryComponent<ILpComponentHeroV2, Awaited<ReturnType<typeof heroV2Loader>>> = {
  render: RenderHeroV2,
  loader: heroV2Loader,
};

export default HeroV2;
