import LazyLoad from 'react-lazyload';

import { Button, Text } from '@opendoor/bricks-next';
import { Box, Flex, Image } from '@opendoor/bricks/core';

import { EntryComponent } from 'cms/entries/entries';

import { ILpComponentLwcImageCard } from 'declarations/contentful';

import Grid, { gridStyles } from './Grid';

const RenderLWCImageCard = (entry: ILpComponentLwcImageCard) => {
  const { fields } = entry;
  const desktopImageSourceUrl = fields?.desktopImage;
  const mobileImageSourceUrl = fields?.mobileImage;

  return (
    <Box
      analyticsName={`cosmos-landing-page-lwc-image-card-${fields?.analyticsId}`}
      trackImpression
    >
      <Grid
        // @ts-expect-error TODO - @growth update after brown95 is added to Novo theme
        backgroundColor="#F6F5F4"
      >
        <Flex
          gridColumn={gridStyles.colSpan12}
          flexDirection={['column', null, 'row', null, null]}
          width="100%"
          height={['495px', '590px', '460px', '712px']}
          my={fields?.verticalPadding === 'Small' ? [6, 8, 10] : ['112px', '160px']}
          overflow="hidden"
          backgroundColor="neutrals0"
          css={{ borderRadius: '36px' }}
        >
          <Flex
            padding={[8, null, null, 11, '80px']}
            paddingRight={[7, null, 8, 11, '80px']}
            paddingBottom={['43px', null, 8, 11, '80px']}
            flexDirection="column"
            justifyContent="start"
          >
            {fields?.subtitle && (
              <Flex flexGrow={1} pb={[4, null, 'unset']}>
                <Text
                  tag="h3"
                  display="block"
                  typography="$bodyLarge"
                  fontWeight="$medium"
                  color="$contentPrimary"
                >
                  {fields.subtitle}
                </Text>
              </Flex>
            )}
            <Flex flexDirection="column">
              <Box width="100%" maxWidth={['197px', '297px', '269px', '500px']}>
                <Text
                  tag="h3"
                  display="block"
                  typography="$subheaderMedium"
                  $largerThanMD={{ typography: '$headerLarge' }}
                >
                  {fields.title}
                </Text>
              </Box>
              {fields?.ctaLink && fields?.ctaDisplayText && (
                <Button
                  variant="primary"
                  aria-label={`Go to page to ${fields?.ctaDisplayText}`}
                  analyticsName={`cosmos-landing-page-lwc-image-card-cta-${fields?.analyticsId}`}
                  onPress={() => fields?.ctaLink && window.location.assign(fields?.ctaLink)}
                  marginTop="$15x"
                  size="small"
                  maxWidth={165}
                  label={fields?.ctaDisplayText}
                />
              )}
            </Flex>
          </Flex>
          <Flex
            height={['45%', '50%', '100%', null, null]}
            flex={[0.4, 1, 1, null, null]}
            padding={[null, null, 5, 6]}
          >
            <LazyLoad offset={300}>
              <Image
                display={['block', null, 'none']}
                src={mobileImageSourceUrl}
                alt="illustration of a home"
                borderRadius={[null, null, 'semiRounded']}
                objectFit="cover"
                objectPosition="80%"
                width="100%"
                loading="lazy"
              />
              <Image
                display={['none', null, 'block']}
                src={desktopImageSourceUrl}
                alt="illustration of a home"
                borderRadius={[null, null, 'semiRounded']}
                objectFit="cover"
                objectPosition="80%"
                width="100%"
                loading="lazy"
              />
            </LazyLoad>
          </Flex>
        </Flex>
      </Grid>
    </Box>
  );
};

const LWCImageCard: EntryComponent<ILpComponentLwcImageCard> = {
  render: RenderLWCImageCard,
};

export default LWCImageCard;
