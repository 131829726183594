// storybook-check-ignore
import styled from '@emotion/styled';

const Arch = styled('svg')`
  display: block;
  height: auto;
  position: relative;
  top: 1px;
  width: 100%;
`;

export const ArchSVG = ({ color }: { color: string }) => (
  <Arch
    xmlns="http://www.w3.org/2000/svg"
    width="1440"
    height="124"
    viewBox="0 0 1440 124"
    fill="none"
  >
    <path
      d="M720 0C468.014 0 225.817 42.2127 0 120V124H1440V120C1214.18 42.2127 971.986 0 720 0Z"
      fill={color || 'currentColor'}
    />
  </Arch>
);
