import { KeyboardEvent, useRef, useState } from 'react';

import { Box, getCSSVariable } from '@opendoor/bricks-next';

import { EntryComponent } from 'cms/entries/entries';

import { ILpComponentHeaderStandalone } from 'declarations/contentful';

import ContextualField from './ContextualField';
import { ArchSVG } from './shared/ArchSVG';
import { LPLink } from './shared/Buttons';
import Container from './shared/Container';
import Sheet from './shared/Sheet';
import {
  BNColorThemes,
  getBNComponentThemeColors,
  getComponentThemeColors,
  IComponentThemeOptions,
} from './shared/styles/ComponentTheme';
import { StackStyle } from './shared/styles/SharedStyles';
import { Desc, Eyebrow, Subhead, SubheadSmall, TitleWithAccent } from './shared/Typography';

interface CtaSheetProps {
  entry: ILpComponentHeaderStandalone;
  componentTheme: IComponentThemeOptions;
}

const CtaSheet = ({ entry, componentTheme }: CtaSheetProps) => {
  const { fields } = entry;

  const [isVisible, setIsVisible] = useState(false);
  const ctaRef = useRef<HTMLAnchorElement>(null);
  // When animation is complete
  const exitComplete = () => {
    ctaRef.current?.focus();
  };

  // Make visual buttons work like Buttons
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === ' ' || e.key === 'Spacebar') {
      setIsVisible(true);
    }
  };

  return (
    <>
      {fields.ctaDisplayText && (
        <LPLink
          aria-label={fields.ctaSheetLabel || 'Open dialog'}
          analyticsName={`cosmos-landing-page-cta-standalone-${fields?.analyticsId}`}
          componentTheme={componentTheme}
          ctaStyle={fields.ctaStyle}
          ctaDisplayText={fields.ctaDisplayText}
          tabIndex={0}
          onKeyDown={onKeyDown}
          ref={ctaRef}
          onClick={() => setIsVisible(true)}
          mt={7}
        >
          {fields.ctaDisplayText}
        </LPLink>
      )}
      <Sheet
        entry={fields.ctaSheet}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        exitComplete={exitComplete}
      />
    </>
  );
};

const RenderHeaderStandalone = (entry: ILpComponentHeaderStandalone) => {
  const { fields } = entry;
  const componentTheme = getComponentThemeColors(fields.backgroundColor);

  const theme = getBNComponentThemeColors(
    fields.backgroundColor in BNColorThemes
      ? (fields?.backgroundColor as BNColorThemes)
      : BNColorThemes.Light,
  );
  return (
    <Box className="lp-section">
      <Box>
        {fields.showArch && (
          <ArchSVG
            // @ts-expect-error theme.bgPrimary is a color token
            color={getCSSVariable(theme.bgPrimary, 'color')}
          />
        )}
      </Box>
      <Box position="relative" backgroundColor={theme.bgPrimary}>
        <Container paddingVertical="$10x" $largerThanMD={{ paddingVertical: '$20x' }}>
          <Box gap="$14x" $largerThanSM={{ flexDirection: 'row' }}>
            <Box
              css={{ ...StackStyle }}
              $largerThanSM={{ flex: 6, flexBasis: fields.layout === 'Two Columns' ? 0 : 'unset' }}
            >
              {fields.eyebrow && (
                <Eyebrow
                  textAlign={fields.textAlign === 'Center' ? 'center' : 'auto'}
                  color={theme.contentPrimary}
                >
                  {fields.eyebrow}
                </Eyebrow>
              )}
              {fields.title && (
                <TitleWithAccent
                  title={fields.title}
                  titleAccent={fields.titleAccent}
                  titleSize={fields.titleSize}
                  textAlign={fields.textAlign === 'Center' ? 'center' : 'auto'}
                  marginHorizontal={fields.textAlign === 'Center' ? 'auto' : 'unset'}
                  titleColor={theme.contentPrimary}
                  titleAccentColor={theme.contentPrimaryAccent}
                  overRideTitleTag="h1"
                />
              )}
              <Box css={{ ...StackStyle }} mx={fields.textAlign === 'Center' ? 'auto' : 0}>
                {fields.subhead &&
                  (fields.titleSize === 'Large' ? (
                    <Subhead
                      mb={0} // @ts-expect-error maxWidth ch required
                      $largerThanMD={{ maxWidth: '48ch' }}
                      textAlign={fields.textAlign === 'Center' ? 'center' : 'auto'}
                    >
                      {fields.subhead}
                    </Subhead>
                  ) : (
                    <SubheadSmall
                      mb={0} // @ts-expect-error maxWidth ch required
                      $largerThanMD={{ maxWidth: '48ch' }}
                      textAlign={fields.textAlign === 'Center' ? 'center' : 'auto'}
                    >
                      {fields.subhead}
                    </SubheadSmall>
                  ))}
                {fields.layout !== 'Two Columns' && fields.desc && (
                  <Desc textAlign={fields.textAlign === 'Center' ? 'center' : 'auto'}>
                    {fields.desc}
                  </Desc>
                )}
                {fields.ctaDisplayText && fields.ctaUrl && (
                  <LPLink
                    aria-label={`Go to page ${fields.ctaDisplayText}`}
                    analyticsName={`cosmos-landing-page-cta-standalone-${fields?.analyticsId}`}
                    componentTheme={componentTheme}
                    ctaStyle={fields.ctaStyle}
                    ctaDisplayText={fields.ctaDisplayText}
                    ctaUrl={fields.ctaUrl}
                    mx={fields.textAlign === 'Center' ? 'auto' : 'unset'}
                    width="max-content"
                  />
                )}

                {fields.ctaDisplayText && fields.ctaSheet && (
                  <CtaSheet entry={entry} componentTheme={componentTheme} />
                )}

                {fields.contextualField && (
                  <Box
                    display="flex"
                    width="100%"
                    justifyContent={fields.textAlign === 'Center' ? 'center' : 'flex-start'}
                    mt={7}
                  >
                    <ContextualField
                      theme={componentTheme}
                      contextFields={fields.contextualField}
                      inputLocation="header-standalone"
                    />
                  </Box>
                )}
              </Box>
            </Box>
            {fields.layout === 'Two Columns' && fields.desc && (
              <Box
                $largerThanSM={{ flex: 4, flexBasis: 0 }}
                $largerThanMD={{ marginRight: '$20x' }}
                alignSelf="flex-end"
                marginHorizontal={fields.textAlign === 'Center' ? 'auto' : 'unset'}
              >
                <Desc textAlign={fields.textAlign === 'Center' ? 'center' : 'auto'}>
                  {fields.desc}
                </Desc>
              </Box>
            )}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

const HeaderStandalone: EntryComponent<ILpComponentHeaderStandalone> = {
  render: RenderHeaderStandalone,
};

export default HeaderStandalone;
