import { css } from '@emotion/react';
import { Box, Flex } from '@opendoor/bricks/core';

import { EntryComponent } from 'cms/entries/entries';

import { Desc, TitleWithAccent } from 'components/landing-pages-v2/shared/Typography';

import { ILpComponentLwcTextSection } from 'declarations/contentful';

import Grid, { gridStyles } from './Grid';

const RenderLWCTextSection = (entry: ILpComponentLwcTextSection) => {
  const { fields } = entry;
  const analyticsName = `cosmos-landing-page-lwc-text-section-${fields?.analyticsId}`;

  return (
    <Box analyticsName={analyticsName} trackImpression>
      <Grid>
        <Flex
          gridColumn={gridStyles.colSpan12}
          width="100%"
          flexDirection={['column', null, 'row']}
          justifyContent={[null, null, 'space-between']}
          alignItems={[null, null, 'flex-end']}
          gap={[6, null, 8]}
          css={css`
            @media (max-width: 374px) {
              margin-top: 64px;
              margin-bottom: 48px;
            }
            @media (min-width: 375px) {
              margin-top: 96px;
              margin-bottom: 64px;
            }
            @media (min-width: 768px) {
              margin-top: 80px;
              margin-bottom: 80px;
            }
          `}
        >
          <Box
            css={css`
              @media (min-width: 375px) {
                width: 276px;
              }
              @media (min-width: 768px) {
                width: 263px;
              }
              @media (min-width: 960px) {
                width: 450px;
              }
              @media (min-width: 1201px) {
                width: 547px;
              }
            `}
          >
            <TitleWithAccent title={fields?.title} />
          </Box>
          <Box
            pb={[null, null, '3']}
            css={css`
              @media (min-width: 375px) {
                width: 323px;
              }
              @media (min-width: 768px) {
                width: 380px;
              }
              @media (min-width: 960px) {
                width: 500px;
              }
              @media (min-width: 1201px) {
                width: 580px;
              }
            `}
          >
            <Desc $largerThanMD={{ fontSize: 24 }}>{fields?.description}</Desc>
          </Box>
        </Flex>
      </Grid>
    </Box>
  );
};

const LWCTextSection: EntryComponent<ILpComponentLwcTextSection> = {
  render: RenderLWCTextSection,
};

export default LWCTextSection;
