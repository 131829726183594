// storybook-check-ignore
import { Box } from '@opendoor/bricks/core';

import { EntryComponent } from 'cms/entries/entries';

import { ILpComponentSpacer } from 'declarations/contentful';

import { getComponentThemeColors } from './shared/styles/ComponentTheme';

const RenderSpacer = (entry: ILpComponentSpacer) => {
  const { fields } = entry;
  const componentTheme = fields.backgroundColor
    ? getComponentThemeColors(fields.backgroundColor)
    : getComponentThemeColors('White 0');

  return (
    <Box
      className="lp-spacer"
      backgroundColor={componentTheme.bg ? componentTheme.bg : 'white0'}
      h={[fields.mobileSpace || 0, null, fields.space || 0]}
      aria-hidden="true"
    />
  );
};

const Spacer: EntryComponent<ILpComponentSpacer> = {
  render: RenderSpacer,
};

export default Spacer;
