// storybook-check-ignore
import { useEffect } from 'react';

import { useAuth } from '@opendoor/auth-fe';

import { useObservability } from 'helpers/observability';

import { useOnLoad } from './useOnLoad';

/**
 * Prompt the user to sign in with Google One Tap with built in error tracking + analytics
 * @param page - The page name to use for analytics tracking
 */
export const usePromptGoogleOneTap = (
  page: string,
  onSuccess?: () => void,
  disablePromptGoogleOneTap?: boolean,
  delay?: number,
) => {
  const { trackEvent, trackError } = useObservability();
  const { authentication, refresh } = useAuth();

  const isAfterLoad = useOnLoad();
  useEffect(() => {
    // don't fire off the import until the document is finished loading
    if (authentication.state !== 'not_authenticated' || !isAfterLoad || disablePromptGoogleOneTap) {
      return;
    }

    const prompt = () => {
      import('./GoogleOneTap').then(({ promptGoogleOneTap }) => {
        promptGoogleOneTap(
          `cosmos-${page}`,
          () => {
            onSuccess && onSuccess();
            refresh();
          },
          trackEvent,
          trackError,
        );
      });
    };

    let timer: NodeJS.Timeout;

    if (delay) {
      timer = setTimeout(prompt, delay);
    } else {
      prompt();
    }

    return () => {
      clearTimeout(timer);
    };
  }, [authentication.state, isAfterLoad, delay]);
};
