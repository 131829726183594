import { css, keyframes } from '@emotion/react';
import { Box, Flex, FlexProps } from '@opendoor/bricks/core';

import { Picture } from 'components/shared/performance/pictures';

function createFadeAnimation(opacityValue: 0 | 1) {
  return keyframes`
  0% {
    opacity: 1;
    transform: scale(0.9);
  }

  30% {
    transform: scale(0.95);
  }

  50% {
    transform: scale(1);
  }

  100% {
    opacity: ${opacityValue};
    transform: scale(1);
  }
`;
}

export const MarkerPulse = css({
  opacity: 0,
  transform: 'scale(1)',
  animation: `${createFadeAnimation(0)} 5s infinite`,
});

export const MarkerFadeIn = css({
  opacity: 0,
  transform: 'scale(1)',
  animation: `${createFadeAnimation(1)} 5s forwards`,
});

const Marker = ({
  imageSource,
  preload = false,
  lazy = true,
  animationDelay = '0s',
  ...props
}: {
  imageSource: string;
  preload?: boolean;
  lazy?: boolean;
  animationDelay?: string;
} & FlexProps) => {
  return (
    <Box
      position="absolute"
      {...props}
      style={{
        animationDelay,
      }}
    >
      <Flex justifyContent="center" alignItems="center" width="100%">
        <Picture
          preload={preload}
          lazy={lazy}
          radii={{}}
          sources={[{ srcSet: imageSource, media: '(min-width: 0px)', key: imageSource }]}
          objectFit="cover"
        />
      </Flex>
    </Box>
  );
};

export default Marker;
