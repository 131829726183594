export const daysToFindABuyer = [
  {
    id: '1',
    code: 'atl',
    name: 'Atlanta, GA',
    daysToFind: '40-65',
  },
  {
    id: '2',
    code: 'atx',
    name: 'Austin, TX',
    daysToFind: '45-80',
  },
  {
    id: '3',
    code: 'boi',
    name: 'Boise, ID',
    daysToFind: '30-60',
  },
  {
    id: '4',
    code: 'clt',
    name: 'Charlotte, NC',
    daysToFind: '45-70',
  },
  {
    id: '5',
    code: 'dfw',
    name: 'Dallas-Fort Worth, TX',
    daysToFind: '35-65',
  },
  {
    id: '6',
    code: 'den',
    name: 'Denver, CO',
    daysToFind: '25-50',
  },
  {
    id: '7',
    code: 'hou',
    name: 'Houston, TX',
    daysToFind: '50-75',
  },
  {
    id: '8',
    code: 'jax',
    name: 'Jacksonville, FL',
    daysToFind: '55-85',
  },
  {
    id: '9',
    code: 'las',
    name: 'Las Vegas, NV',
    daysToFind: '35-60',
  },
  {
    id: '10',
    code: 'lax',
    name: 'Los Angeles, CA',
    daysToFind: '40-60',
  },
  {
    id: '11',
    code: 'msp',
    name: 'Minneapolis-Saint Paul, MN',
    daysToFind: '35-65',
  },
  {
    id: '12',
    code: 'nas',
    name: 'Nashville, TN',
    daysToFind: '50-80',
  },
  {
    id: '13',
    code: 'orl',
    name: 'Orlando, FL',
    daysToFind: '50-70',
  },
  {
    id: '14',
    code: 'phx',
    name: 'Phoenix, AZ',
    daysToFind: '40-60',
  },
  {
    id: '15',
    code: 'pdx',
    name: 'Portland, OR',
    daysToFind: '35-65',
  },
  {
    id: '16',
    code: 'rdu',
    name: 'Raleigh-Durham, NC',
    daysToFind: '40-70',
  },
  {
    id: '17',
    code: 'riv',
    name: 'Riverside, CA',
    daysToFind: '35-60',
  },
  {
    id: '18',
    code: 'sac',
    name: 'Sacramento, CA',
    daysToFind: '30-60',
  },
  {
    id: '19',
    code: 'slc',
    name: 'Salt Lake City, UT',
    daysToFind: '30-60',
  },
  {
    id: '20',
    code: 'sat',
    name: 'San Antonio, TX',
    daysToFind: '50-75',
  },
  {
    id: '21',
    code: 'tpa',
    name: 'Tampa, FL',
    daysToFind: '50-65',
  },
  {
    id: '22',
    code: 'tuc',
    name: 'Tucson, AZ',
    daysToFind: '55-80',
  },
];
