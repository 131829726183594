// TODO: change to novo
import { Box, Divider, Flex, Icon, Link } from '@opendoor/bricks/core';
import { colors } from '@opendoor/bricks/theme/eero';

import { EntryComponent } from 'cms/entries/entries';

import { Subhead, TitleWithAccent } from 'components/landing-pages-v2/shared/Typography';

import { ILpComponentFaqDisplay } from 'declarations/contentful';

import landingPageEntryLookup from '../../cms/entries/landingPageV2';
import RichTextRenderer from '../../cms/renderer';
import Grid, { gridStyles } from './Grid';

const RenderFAQsDisplay = (entry: ILpComponentFaqDisplay) => {
  const { fields } = entry;

  const viewAllLink = fields?.viewMoreLinkUrl ? (
    <Link
      aria-label="View more FAQs"
      analyticsName={`cosmos-landing-page-faqs-display-${fields?.analyticsId}`}
      href={fields?.viewMoreLinkUrl}
      fontWeight="semibold"
      alignSelf="center"
    >
      View all FAQ {<Icon name="arrow-right" size={16} color="brand50" alignSelf="center" />}
    </Link>
  ) : null;
  return (
    <Box analyticsName={`cosmos-landing-page-faqs-display-${fields?.analyticsId}`} trackImpression>
      <Grid
        style={{
          backgroundColor: '#F6F5F4',
        }}
        py={11}
      >
        <Flex
          gridColumn={gridStyles.colSpan12}
          width="100%"
          flexDirection={['column', null, 'row']}
          alignContent="center"
        >
          <Flex width="100%" flexDirection="column">
            <Flex width="100%" justifyContent="space-between" mb="56px" alignItems="flex-end">
              <TitleWithAccent title={fields?.title} maxWidth={488} />
              <Box display={['none', null, 'block']}>{viewAllLink}</Box>
            </Flex>
            <Box>
              <Box>
                {fields?.faQs?.map((faq: any, idx) => {
                  return (
                    <Box key={idx}>
                      <Box my={[9, null, 6, 10]} display={idx === 0 ? 'none' : 'block'}>
                        <Divider borderTop={`1px solid ${colors.neutrals40}`} />
                      </Box>
                      <Flex
                        gap={[0, null, '15%']}
                        pr={[null, null, null, null, '91px']}
                        width="100%"
                        flexDir={['column', null, 'row']}
                      >
                        <Box width={['100%', null, '35%']} mb="6">
                          <Subhead $largerThanMD={{ fontSize: 24 }}>{faq?.fields?.title}</Subhead>
                        </Box>
                        <Box width={['100%', null, '55%']}>
                          <RichTextRenderer.Component
                            body={faq?.fields?.body}
                            idToLoaderData={{}}
                            entryLookup={landingPageEntryLookup}
                          />
                        </Box>
                      </Flex>
                    </Box>
                  );
                })}
              </Box>
            </Box>
            <Box mt="7" display={['block', null, 'none']}>
              {viewAllLink}
            </Box>
          </Flex>
        </Flex>
      </Grid>
    </Box>
  );
};

const FAQsDisplay: EntryComponent<ILpComponentFaqDisplay> = {
  render: RenderFAQsDisplay,
};

export default FAQsDisplay;
