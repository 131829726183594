/* storybook-check-ignore */
import { useEffect, useState } from 'react';

import { SerializedStyles } from '@emotion/react';
import { Button } from '@opendoor/bricks-next';
import { Flex, Text } from '@opendoor/bricks/core';

import { ANIMATION_DURATION, HIDE_ANIMATION, REVEAL_ANIMATION } from './animations';

interface StickyCTAUtilityProps {
  isVisible: boolean;
  analyticsId: string;
}

export const StickyCTAUtility = ({ isVisible, analyticsId }: StickyCTAUtilityProps) => {
  const [showStickyCTAUtility, setShowStickyCTAUtility] = useState(false);
  const [animate, setAnimate] = useState<SerializedStyles | null>(null);

  useEffect(() => {
    // figure out which animation to render
    setAnimate(isVisible ? REVEAL_ANIMATION : HIDE_ANIMATION);

    // Reveal animation needs CTA element on DOM
    if (isVisible) {
      setShowStickyCTAUtility(true);
    }

    setTimeout(() => {
      // Animation complete
      setAnimate(null);

      // Hide CTA after hide animation completes
      if (!isVisible) {
        setShowStickyCTAUtility(false);
      }
    }, ANIMATION_DURATION * 1000);
  }, [isVisible]);

  return (
    <Flex
      display={showStickyCTAUtility ? 'flex' : 'none'}
      position="fixed"
      zIndex={9999}
      left="50%"
      top="2%"
      ml={['-150px', null, '-180px']}
      width={['300px', null, '360px']}
      justifyContent="space-between"
      alignItems="center"
      background="linear-gradient(90deg, #F2F1F0, 64%, #F2F1F0)"
      backdropFilter="blur(20px)"
      css={animate}
      boxShadow="z2"
      padding="6px"
      style={{
        borderRadius: '20px',
      }}
    >
      <Text size="130" fontWeight="medium" px={[5, null, 6]}>
        Still selling?
      </Text>
      <Button
        maxWidth={140}
        $largerThanLG={{ maxWidth: 160 }}
        onPress={() => window.location.assign('/address-entry')}
        aria-label="sticky CTA utility"
        analyticsName={`cosmos-landing-page-sticky-cta-${analyticsId}`}
        variant="primary"
        size="xsmall"
        label="Get cash offer"
        minHeight="$16x"
        $largerThanMD={{ minHeight: 52 }}
      />
    </Flex>
  );
};
