/*
  Saves us from making a network request to https://images.opendoor.com/source/s3/imgdrop-production/2023-06-1687822944103-28395.svg?preset=square-2048

  storybook-check-ignore
*/
import { css } from '@emotion/react';

export const GlobalNavSVG = ({ isOpen }: { isOpen: boolean }) => {
  return (
    <svg
      className={isOpen ? 'is-open' : undefined}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      css={css`
        .menu-bar-top,
        .menu-bar-bottom {
          transform-origin: center;
          transition: transform 0.3s cubic-bezier(0.55, 0, 0.15, 1);
        }

        .menu-bar-top {
          transform: translateY(-4px);
        }

        .menu-bar-bottom {
          transform: translateY(4px);
        }

        &.is-open .menu-bar-top {
          transform: rotate(45deg);
        }

        &.is-open .menu-bar-bottom {
          transform: rotate(-45deg);
        }
      `}
    >
      <path
        className="menu-bar-top"
        d="M4 12H20"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        className="menu-bar-bottom"
        d="M4 12H20"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
