const HOMEPAGE_SCHEMA = {
  '@context': 'http://schema.org',
  '@type': 'Organization',
  legalName: 'Opendoor Labs, Inc.',
  alternateName: 'Opendoor',
  url: 'https://www.opendoor.com',
  contactPoint: {
    '@type': 'ContactPoint',
    telephone: '+1-888-352-7075',
    contactType: 'Customer Service',
  },
  address: {
    '@type': 'PostalAddress',
    addressLocality: 'San Francisco',
    addressCountry: 'USA',
    postalCode: '94104',
    streetAddress: '1 Post St, Fl 11',
  },
  logo: 'https://www.opendoor.com/w/wp-content/uploads/2019/04/Opendoor-Logo.jpg',
  sameAs: [
    'https://www.facebook.com/OpendoorHQ',
    'https://twitter.com/opendoor',
    'https://www.youtube.com/channel/UCKKVV4bx18EtclsdNOFzWBw',
    'https://www.linkedin.com/company/opendoor-com',
    'https://www.pinterest.com/opendoorhq',
    'https://en.wikipedia.org/wiki/Opendoor',
  ],
};

export { HOMEPAGE_SCHEMA };
