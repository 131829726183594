import { Fee } from '../NetProceedsComparisonCalculator';

export const feeInputFields: Fee[] = [
  {
    id: 'offerPrice',
    label: 'Offer price',
    odDefaultValue: '300000',
    trDefaultValue: '300000',
    unit: { opendoor: '$', traditional: '$' },
    editable: { opendoor: true, traditional: true },
  },
  {
    id: 'mortgagePayoff',
    label: 'Mortgage payoff',
    odDefaultValue: '0',
    trDefaultValue: '0',
    supportText: 'Enter your outstanding loan balance here.',
    unit: { opendoor: '$', traditional: '$' },
    editable: { opendoor: true, traditional: true },
  },
  {
    id: 'daysToFindABuyer',
    label: 'Estimated days to find a buyer',
    odDefaultValue: 'Sold directly to Opendoor',
    trDefaultValue: ' ',
    supportText: 'In a traditional sale, the number of days your home is on the market will vary.',
    unit: { opendoor: '', traditional: '' },
    editable: { opendoor: false, traditional: false },
  },
  {
    id: 'daysToCloseTransaction',
    label: 'Days to close transaction',
    odDefaultValue: '3 to 270',
    trDefaultValue: '46',
    supportText: 'You choose the exact close date with Opendoor.',
    unit: { opendoor: 'days', traditional: 'days' },
    editable: { opendoor: false, traditional: false },
  },
  {
    id: 'opendoorServiceCharge',
    label: 'Opendoor service charge',
    odDefaultValue: '5',
    trDefaultValue: 'Not applicable',
    supportText: 'This lets you skip the costs and stress of the listing process.',
    unit: { opendoor: '%', traditional: '' },
    editable: { opendoor: false, traditional: false },
  },
  {
    id: 'realEstateAgentFees',
    label: 'Real estate agent fees',
    odDefaultValue: 'No agent fees',
    trDefaultValue: '0',
    supportText: 'Commission fees often total 5-6% of the purchase price, but varies by home.',
    unit: { opendoor: '', traditional: '%' },
    editable: { opendoor: true, traditional: true },
  },
  {
    id: 'repairs',
    label: 'Repairs',
    odDefaultValue: '0',
    trDefaultValue: '0',
    supportText:
      'This is included in our final offer. We handle the work to get your home market ready.',
    unit: { opendoor: '$', traditional: '$' },
    editable: { opendoor: true, traditional: true },
  },
  {
    id: 'sellerConcessions',
    label: 'Seller concessions',
    odDefaultValue: 'No fees or taxes',
    trDefaultValue: '0',
    supportText:
      'Buyers may ask sellers to pay costs such as inspection fees, transfer taxes, and more.',
    unit: { opendoor: '', traditional: '%' },
    editable: { opendoor: true, traditional: true },
  },
  {
    id: 'titleEscrowTax',
    label: 'Title, escrow, notary, and transfer tax',
    odDefaultValue: '1',
    trDefaultValue: '1',
    supportText:
      'Closing costs are a part of every sale, and are usually 1% to 3% of the purchase price.',
    unit: { opendoor: '%', traditional: '%' },
    editable: { opendoor: true, traditional: true },
  },
  {
    id: 'hoaTransferFee',
    label: 'HOA transfer fee',
    odDefaultValue: '0',
    trDefaultValue: '0',
    supportText:
      'If your home is part of an HOA, you may have a transfer fee. The average is $250.',
    unit: { opendoor: '$', traditional: '$' },
    editable: { opendoor: true, traditional: true },
  },
  {
    id: 'monthsRenting',
    label: 'Month(s) renting',
    odDefaultValue: 'Flexible close date',
    trDefaultValue: '1',
    unit: { opendoor: '', traditional: '' },
    supportText:
      'In a traditional sale, you may need to live in a rental between your transactions.',
    editable: { opendoor: true, traditional: true },
  },
  {
    id: 'monthlyRentalCost',
    label: 'Monthly rental cost',
    odDefaultValue: 'No rental costs',
    trDefaultValue: '1100',
    supportText:
      'We’ve estimated $1,100 a month based on national averages, but this varies by area.',
    unit: { opendoor: '', traditional: '$' },
    editable: { opendoor: true, traditional: true },
  },
];
