import { useEffect, useState } from 'react';

import { Box, Dropdown, Link, Text } from '@opendoor/bricks-next';
import { Loader } from '@opendoor/bricks/core';

import { ILpComponentOpenPositions } from 'declarations/contentful';

import { LandingPageSectionContainer } from './shared/Container';

interface Department {
  id: number;
  name: string;
  parent_id?: number;
  child_ids: number[];
  jobs: Job[];
}

interface Job {
  absolute_url: string;
  internal_job_id: number;
  location: Location;
  metadata: any;
  id: number;
  updated_at: string;
  requisition_id: string;
  title: string;
}

interface Location {
  name: string;
}

const GREEN_HOUSE_API_URL = 'https://boards-api.greenhouse.io/v1/boards/opendoor/departments';

const getFilteredPositionsByOffice = (departments: Department[], selectedOffice: string) => {
  const regexString = new RegExp(`\\b${selectedOffice.toLowerCase()}\\b`);

  const filteredDepartments = departments.map((dept: Department) => ({
    ...dept,
    jobs: dept.jobs.filter((job: Job) => !!job.location?.name.toLowerCase().match(regexString)),
  }));

  return filteredDepartments.filter((dept: Department) => !!dept.jobs.length);
};

const OpenPositionCard = ({ job }: { job: Job }) => {
  return (
    <Box
      flexDirection="column"
      justifyContent="space-between"
      borderColor="$borderPrimary"
      borderStyle="solid"
      borderWidth="$default"
      borderRadius="$12x"
      paddingHorizontal="$20x"
      paddingVertical="$16x"
      gap="$8x"
      $largerThanSM={{
        flexDirection: 'row',
      }}
      hoverStyle={{
        borderColor: '$borderStateHoverPrimary',
      }}
    >
      <Box width="100%" $largerThanSM={{ width: '50%' }}>
        <Link
          analyticsName="open-position"
          size="large"
          color="$contentAccentBrand"
          typography="$labelLarge"
          variant="primary"
          href={`/careers/open-positions/jobs?gh_jid=${job.id}`}
          target="_blank"
        >
          {job.title}
        </Link>
      </Box>
      <Box width="100%" $largerThanSM={{ width: '50%' }}>
        <Text
          tag="p"
          typography="$bodyLarge"
          color="$contentSecondary"
          $largerThanSM={{ textAlign: 'right' }}
        >
          {job.location.name}
        </Text>
      </Box>
    </Box>
  );
};

const OpenPositionsCTA = (
  entry: ILpComponentOpenPositions,
  resolvedData: {
    departments: Department[];
    departmentFilters: Array<{ label: string; value: string }>;
    officeFilters: Array<{ label: string; value: string }>;
  },
) => {
  const { fields } = entry;
  const { departmentFilters, officeFilters } = resolvedData;
  const [showLoader, setShowLoader] = useState(true);

  const [selectedDepartment, setSelectedDepartment] = useState<string>();

  const [selectedOffice, setSelectedOffice] = useState<string>();

  const allDepartments = resolvedData.departments;

  const filteredDepartments =
    selectedDepartment && selectedDepartment !== 'all'
      ? allDepartments.filter((dept: Department) => String(dept.id) === selectedDepartment)
      : allDepartments;

  const filteredDepartmentsByOffice =
    selectedOffice && selectedOffice !== 'all'
      ? getFilteredPositionsByOffice(filteredDepartments, selectedOffice)
      : filteredDepartments;

  useEffect(() => {
    if (fields.pageType === 'Job Description') {
      const script = document.createElement('script');
      script.src = 'https://boards.greenhouse.io/embed/job_board/js?for=opendoor';
      script.onload = () => {
        Grnhse?.Iframe.load();
        setTimeout(() => {
          setShowLoader(false);
        }, 1500);
      };
      document.head.appendChild(script);
    }
  }, []);

  return (
    <LandingPageSectionContainer minHeight={400}>
      {fields.pageType === 'Job Description' ? (
        <>
          {showLoader && (
            <Box minHeight={1000} justifyContent="center">
              <Loader />
            </Box>
          )}
          <Box id="grnhse_app" opacity={showLoader ? 0 : 1}></Box>
        </>
      ) : (
        <>
          <Box gap="$16x" marginBottom="$32x" $largerThanSM={{ flexDirection: 'row' }}>
            <Box
              width="100%"
              flexGrow={1}
              $largerThanSM={{ width: 285 }}
              $largerThanMD={{ flexGrow: 0 }}
            >
              <Dropdown
                title="Department"
                size="medium"
                aria-labelledby="Select Department"
                options={departmentFilters}
                placeholderOption={{
                  label: 'All Departments',
                  value: 'all',
                }}
                onValueChange={(value) => {
                  setSelectedDepartment(value);
                }}
              />
            </Box>
            <Box
              width="100%"
              flexGrow={1}
              $largerThanSM={{ width: 285 }}
              $largerThanMD={{ flexGrow: 0 }}
            >
              <Dropdown
                title="Office"
                size="medium"
                aria-labelledby="Select Office"
                options={officeFilters}
                placeholderOption={{
                  label: 'All Offices',
                  value: 'all',
                }}
                onValueChange={(value) => {
                  setSelectedOffice(value);
                }}
              />
            </Box>
          </Box>

          <Box marginVertical="$10x">
            {filteredDepartmentsByOffice.map((dept: Department) => (
              <Box key={dept.id} marginBottom="$32x" gap="$20x">
                <Text tag="h2" typography="$headerSmall">
                  {dept.name}
                </Text>
                <Box gap="$8x">
                  {dept.jobs.map((job: Job) => (
                    <OpenPositionCard key={job.id} job={job} />
                  ))}
                </Box>
              </Box>
            ))}
          </Box>
        </>
      )}
    </LandingPageSectionContainer>
  );
};

const openPositionsLoader = async () => {
  const departmentRes = await fetch(GREEN_HOUSE_API_URL);

  if (departmentRes.status !== 200) {
    return { departments: [], departmentFilters: [], officeFilters: [] };
  }

  const { departments: allDepartments } = (await departmentRes.json()) as {
    departments: Department[];
  };

  const { departmentWithJobs, departmentFilters } = allDepartments.reduce(
    (filteredData, dept) => {
      if (dept.jobs.length > 0) {
        filteredData.departmentWithJobs.push(dept);
        filteredData.departmentFilters.push({
          label: dept.name,
          value: String(dept.id),
        });
      }
      return filteredData;
    },
    {
      departmentWithJobs: [] as Department[],
      departmentFilters: [] as Array<{ label: string; value: string }>,
    },
  );

  const officeFilters = Array.from(
    departmentWithJobs.reduce((offices, dept) => {
      dept.jobs.forEach((job) => offices.add(job.location.name));

      return offices;
    }, new Set()),
  ).map((location) => ({ label: location, value: location }));

  return { departments: departmentWithJobs, departmentFilters, officeFilters };
};

const OpenPositions = {
  render: OpenPositionsCTA,
  loader: openPositionsLoader,
};

export default OpenPositions;
