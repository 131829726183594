import { FeeInput } from '../HomeSaleCalculator';

export const AGENT_FEES_PERCENTAGE = 5;
export const STAGING_COST_PERCENTAGE = 20;
export const SELLER_CONCESSIONS_PERCENTAGE = 2;
export const OVERLAP_COSTS_PERCENTAGE = 20;
export const CLOSING_COSTS_PERCENTAGE = 3;

export const feeInputFields: FeeInput[] = [
  {
    id: 'agent-fees-input',
    title: 'Real estate agent fees',
    supportText:
      "In a traditional home sale, the seller pays fees to both their agent and the buyer's agent. It's common for the total commission to be around 5-6% of the sale price.",
    defaultValue: AGENT_FEES_PERCENTAGE,
    isFormatPercentage: true,
    learnMoreLink: 'https://www.opendoor.com/articles/how-much-does-it-cost-to-sell-a-house',
  },
  {
    id: 'staging-fees-input',
    title: 'Staging and prep work',
    supportText:
      'This is the total cost of getting your home ready to show to potential buyers. This varies depending on whether you pay for staging, cosmetic improvements, and storage.',
    defaultValue: STAGING_COST_PERCENTAGE,
    isFormatPercentage: true,
    learnMoreLink: 'https://www.opendoor.com/articles/how-much-does-it-cost-to-sell-a-house',
  },
  {
    id: 'seller-concessions-input',
    title: 'Seller concessions',
    supportText:
      'Buyers may ask sellers to pay certain costs on their behalf. The amount a buyer can request is limited by their loan type and size of their downpayment. When buyers ask for concessions, they typically ask for 0 - 2% of the home sale price.',
    defaultValue: SELLER_CONCESSIONS_PERCENTAGE,
    isFormatPercentage: true,
    learnMoreLink: 'https://www.opendoor.com/articles/how-much-does-it-cost-to-sell-a-house',
  },
  {
    id: 'overlap-costs-input',
    title: 'Home ownership and overlap costs',
    supportText:
      'These are costs you incur transitioning from one home to the next. Examples include paying for a short-term rental, paying two mortgage payments, or leasing your home back from the buyer before you move.',
    defaultValue: OVERLAP_COSTS_PERCENTAGE,
    isFormatPercentage: true,
    learnMoreLink: 'https://www.opendoor.com/articles/how-much-does-it-cost-to-sell-a-house',
  },
  {
    id: 'closing-costs-input',
    title: 'Title, escrow, notary, and transfer tax',
    supportText:
      'Also referred to as closing costs, these fees can range from 1% to 3% of the sale price depending on where you live.',
    defaultValue: CLOSING_COSTS_PERCENTAGE,
    isFormatPercentage: true,
    learnMoreLink: 'https://www.opendoor.com/articles/how-much-are-closing-costs-for-seller',
  },
];
