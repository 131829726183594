import { useState } from 'react';

import { Box, Dropdown, Text, useMedia } from '@opendoor/bricks-next';

import { daysToFindABuyer } from './constants/days-to-find-buyer';
import FeeInput from './NetProceedsComparisonFeeFormat';
import { NetProceedsCalculatorContainer as Container } from './shared/Container';
import { markdownToReactElement } from './shared/utils';

export interface Fee {
  id: string;
  label: string;
  supportText?: string;
  unit: { opendoor: string; traditional: string };
  odDefaultValue: string;
  trDefaultValue: string;
  editable: { opendoor: boolean; traditional: boolean };
}

export type sellType = 'opendoor' | 'traditional';

const NetProceedsComparisonCalculator = ({
  disclosureText,
  feeInputFields,
}: {
  disclosureText: string;
  feeInputFields: Fee[];
}) => {
  const MARKET_DROPDOWN_OPTIONS = daysToFindABuyer.map(({ name, code }) => ({
    label: name,
    value: code,
  }));
  const [, setSelectedMarket] = useState(MARKET_DROPDOWN_OPTIONS[0].value);
  const [daysToCloseTransaction, setDaysToCloseTransaction] = useState<string>('');
  const { largerThanMD, largerThanSM } = useMedia();
  const [fees, setFees] = useState(() =>
    feeInputFields.reduce((acc, field) => {
      acc[field.id] = {
        opendoor: Number(field?.odDefaultValue),
        traditional: Number(field?.trDefaultValue),
      };
      return acc;
    }, {} as Record<string, { opendoor?: number; traditional?: number }>),
  );

  const calculateNetProceeds = (type: sellType) => {
    const mortgagePayoff = fees.mortgagePayoff[type] || 0;
    const serviceCharge = (fees.opendoorServiceCharge[type] || 0) / 100;
    const agentFees = (fees.realEstateAgentFees[type] || 0) / 100;
    const repairs = fees.repairs[type] || 0;
    const hoaTransferFee = fees.hoaTransferFee[type] || 0;
    const sellerConcessions = (fees.sellerConcessions[type] || 0) / 100;
    const titleTax = (fees.titleEscrowTax[type] || 0) / 100;
    const rentalCost = fees.monthlyRentalCost[type] || 0;
    const monthsRenting = fees.monthsRenting[type] || 0;
    const offerPrice = fees.offerPrice[type] || 300000;

    const totalCosts =
      mortgagePayoff +
      serviceCharge * offerPrice +
      repairs +
      sellerConcessions * offerPrice +
      hoaTransferFee +
      agentFees * offerPrice +
      titleTax * offerPrice +
      rentalCost * monthsRenting;
    const netProceeds = offerPrice - totalCosts;

    return netProceeds;
  };
  const opendoorNetProceeds = calculateNetProceeds('opendoor');
  const traditionalNetProceeds = calculateNetProceeds('traditional');

  const handleMarketChange = (marketId: string) => {
    setSelectedMarket(marketId);

    const market = daysToFindABuyer.find((market) => market.code === marketId);
    if (market) {
      setDaysToCloseTransaction(market.daysToFind);
    }
  };
  const updatedFeeFields = feeInputFields.map((field) => {
    if (field.id === 'daysToFindABuyer') {
      return { ...field, trDefaultValue: daysToCloseTransaction };
    }
    return field;
  });

  const handleChange = (id: string, value: number, type: sellType) => {
    setFees((prevFees) => ({
      ...prevFees,
      [id]: {
        ...prevFees[id],
        [type]: value,
      },
    }));
  };
  const StickyFooter = () =>
    largerThanSM ? (
      <Box
        testID="sticky-footer-container"
        style={{ position: 'sticky', zIndex: 10, marginBottom: 25, bottom: 25 }}
        flexDirection="row"
        padding="$12x"
        paddingRight={0}
        opacity={0.9}
        borderRadius={'$16x'}
        gap="$16x"
        backgroundColor={'$backgroundSecondary'}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box style={{ flex: 1 }} paddingRight="$16x">
          <Text
            typography="$subheaderXsmall"
            tag="span"
            color="$contentTertiary"
            $largerThanMD={{
              typography: '$subheaderSmall',
            }}
          >
            Net Proceeds
          </Text>
        </Box>
        <Box flexDirection="row" style={{ flex: 1 }} gap="$24x" paddingLeft="$12x">
          <Text
            flex={1}
            $largerThanMD={{ typography: '$subheaderSmall' }}
            typography="$subheaderXsmall"
            tag="h2"
            color="$contentPrimary"
          >
            {'$' + opendoorNetProceeds.toLocaleString('en-US')}
          </Text>
          <Text
            flex={1}
            typography="$subheaderXsmall"
            tag="h2"
            color="$contentPrimary"
            $largerThanMD={{ typography: '$subheaderSmall' }}
          >
            {'$' + traditionalNetProceeds.toLocaleString('en-US')}
          </Text>
        </Box>
      </Box>
    ) : (
      <Box
        testID="sticky-footer-container"
        style={{ position: 'sticky', zIndex: 10, bottom: 25 }}
        flexDirection="row"
        padding="$12x"
        opacity={0.9}
        mb={largerThanMD ? '$28x' : '$16x'}
        borderRadius={'$16x'}
        backgroundColor={'$backgroundSecondary'}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box flexDirection="column" alignItems="center">
          <Text typography="$labelXsmall" tag="span" color="$contentTertiary">
            Opendoor net proceeds
          </Text>
          <Text typography="$subheaderXsmall" tag="h2" color="$contentPrimary">
            {'$' + opendoorNetProceeds.toLocaleString('en-US')}
          </Text>
        </Box>
        <Box flexDirection="column" alignItems="center">
          <Text typography="$labelXsmall" tag="span" color="$contentTertiary">
            Traditional net proceeds
          </Text>
          <Text typography="$subheaderXsmall" tag="h2" color="$contentPrimary">
            {'$' + traditionalNetProceeds.toLocaleString('en-US')}
          </Text>
        </Box>
      </Box>
    );

  return (
    <>
      <Box
        testID="Net-proceeds-container"
        backgroundColor={'$backgroundTertiary'}
        paddingBottom="$20x"
      >
        <Container>
          <Box $largerThanSM={{ width: 369 }}>
            <Dropdown
              title=" "
              placeholderOption={{ label: 'Select a market' }}
              size={largerThanMD ? 'medium' : 'small'}
              aria-labelledby="Select a market"
              options={MARKET_DROPDOWN_OPTIONS}
              prefilledValue=" "
              onValueChange={(value) => {
                handleMarketChange(value);
              }}
            />
          </Box>
        </Container>
      </Box>
      <Container>
        <Box py="$24x" gap="$28x" overflow="hidden">
          <Box
            testID="Form-Container"
            $largerThanMD={{ gap: '$12x' }}
            $largerThanSM={{ gap: '$8x' }}
          >
            {updatedFeeFields.map((fee) => (
              <FeeInput
                key={fee.id}
                id={fee.id}
                odEditable={fee.editable.opendoor}
                trEditable={fee.editable.traditional}
                title={fee.label}
                odDefaultValue={fee.odDefaultValue}
                trDefaultValue={fee.trDefaultValue}
                odUnit={fee.unit.opendoor}
                trUnit={fee.unit.traditional}
                supportText={fee?.supportText}
                onValueChange={handleChange}
              />
            ))}
          </Box>
        </Box>
        <Box py="$28x" gap="$16x" $largerThanMD={{ maxWidth: 620 }}>
          <Text tag="p" color="$contentPrimary" typography="$bodyMedium" display="block">
            {markdownToReactElement(disclosureText as string)}
          </Text>
        </Box>
        <StickyFooter />
      </Container>
    </>
  );
};

export default NetProceedsComparisonCalculator;
