/* storybook-check-ignore */
import STOP_WORDS from './stop-words.json';

const REVIEWS_API_URL =
  'https://api.reviews.co.uk/merchant/reviews?store=opendoor-com&per_page=250';

export type ReviewsStats = {
  averageRating: number;
  totalReviews: number;
  recommendedPercent: number;
};
export type Review = {
  reviewComment: string;
  rating: number;
  reviewer: {
    name?: string;
    unidentified?: boolean;
    verifiedBuyer?: boolean;
  };
  productData: {
    city: string;
    productType: 'buy' | 'sell' | 'trade-in';
  };
};

type ProductTypeFeeds = { [key: string]: Array<FeedItem> };

export type ReviewsResult = {
  productTypeFeeds: ProductTypeFeeds;
  stats: ReviewsStats;
};

export type FeedItem =
  | ReviewFeedItem
  | DisclaimerFeedItem
  | BBBFeedItem
  | ReviewsIOFeedItem
  | SeeMoreFeedItem
  | ExplainerFeedItem;

interface SharedFeedItem {
  page?: number;
}
export interface ReviewFeedItem extends SharedFeedItem {
  type: 'review';
  reviewInfo: Review;
}
export interface DisclaimerFeedItem extends SharedFeedItem {
  type: 'disclaimer';
}
export interface BBBFeedItem extends SharedFeedItem {
  type: 'bbb';
}
export interface ReviewsIOFeedItem extends SharedFeedItem {
  type: 'reviewsio';
  stats?: ReviewsStats;
}
export interface SeeMoreFeedItem extends SharedFeedItem {
  type: 'see-more';
  nextPage: number;
}
export interface ExplainerFeedItem extends SharedFeedItem {
  type: 'explainer';
  heading: string;
  description: string;
}

const answerToProductType: { [key: string]: Review['productData']['productType'] } = {
  'I bought my home with Opendoor': 'buy',
  'I sold my home to Opendoor': 'sell',
  'I sold my home to Opendoor AND bought a new one with Opendoor': 'trade-in',
};

const buildFeedItems = (reviewData: {
  reviews: Array<Review>;
  stats: ReviewsStats;
}): ProductTypeFeeds => {
  const categorizedReviews = reviewData.reviews.reduce((acc, currReview) => {
    const productType = currReview.productData.productType;
    if (!acc[productType]) {
      acc[productType] = [];
    }
    acc[productType].push({ type: 'review', reviewInfo: currReview });
    return acc;
  }, {} as ProductTypeFeeds);
  return Object.entries(categorizedReviews).reduce((acc, [key, val]) => {
    acc[key] = addFeedInserts(val, reviewData.stats).slice(0, 45);
    return acc;
  }, {} as ProductTypeFeeds);
};

// Space the disclaimers every 4th element down
const addFeedInserts = (items: Array<FeedItem>, stats: ReviewsStats): Array<FeedItem> => {
  return items.reduce((acc, curr, i) => {
    switch (i) {
      case 3:
        acc.push({ type: 'disclaimer' });
        break;
      case 6:
        acc.push({ type: 'reviewsio', stats });
        break;
      case 9:
        acc.push({ type: 'bbb' });
        break;
    }

    acc.push(curr);
    return acc;
  }, [] as Array<FeedItem>);
};

const capitalize = (input: string) => input.charAt(0).toUpperCase() + input.slice(1).toLowerCase();

const normalizeCity = (userCity: string) => {
  const regex = /(\w+)(\W?)+,(\W?)+(\w+)/;
  const match = regex.exec(userCity);

  if (match) {
    return `${capitalize(match[1])}, ${match[4]}`;
  }
  if (userCity.includes('_')) {
    return userCity.split('_').map(capitalize).join(' ');
  }
  if (userCity.includes('/')) {
    return userCity.split('/').map(capitalize).join('/');
  }
  return userCity.split(/\s+/).map(capitalize).join(' ');
};

const isReviewEligible = (review: any) => {
  //Remove ratings < 3
  if (review.rating < 3) {
    return false;
  }

  //Filter ratings that is not from a verified buyer
  if (review.reviewer.verified_buyer !== 'yes') {
    return false;
  }

  const questions: Array<any> = review.ratings;
  const cityQuestion = {
    userEntry: questions.find((q) => q.name.startsWith('In what city did you work with Opendoor')),
    branchQuestion: (review.branch || {}).name,
  };
  const cityIsAvailable = cityQuestion.branchQuestion || cityQuestion.userEntry;
  const productTypeQuestion = questions.find((q) =>
    q.name.startsWith('How did you work with Opendoor'),
  );

  // remove ratings that does not have city or product speciifcation
  if (!(cityIsAvailable && productTypeQuestion)) {
    return false;
  }

  // Remove ratings that does not have Product Type Question score
  if (!answerToProductType[productTypeQuestion.score]) {
    return false;
  }

  return true;
};

const fetchReviewerInformation = (review: any) => {
  // process reviewer information
  const reviewer = review.reviewer;

  const updatedReviewer: {
    name?: string;
    unidentified?: boolean;
    verifiedBuyer?: boolean;
  } = {
    verifiedBuyer: true,
  };

  if (reviewer.first_name && reviewer.last_name) {
    // Join first and last name
    updatedReviewer.name = [reviewer.first_name, reviewer.last_name].filter((x) => x).join(' ');
    updatedReviewer.unidentified = false;
  } else {
    updatedReviewer.unidentified = true;
  }

  const questions: Array<any> = review.ratings;

  const preferredNameQuestion = questions.find((q) => q.name.startsWith('Your name'));

  if (preferredNameQuestion && preferredNameQuestion.score) {
    updatedReviewer.name = preferredNameQuestion.score;
  }

  return updatedReviewer;
};

const fetchAllReviews = async () => {
  const firstPage = await fetch(REVIEWS_API_URL).then((res) => res.json());
  const requestUrls = [];
  for (let i = 0; i < firstPage.total_pages; i++) {
    requestUrls.push(`${REVIEWS_API_URL}&page=${i}`);
  }
  const reviewsResponse = await Promise.all(
    requestUrls.map((url) => fetch(url).then((resp) => resp.json())),
  );
  const allReviews: Array<any> = reviewsResponse.reduce(
    (acc, curr) => acc.concat(curr.reviews),
    [],
  );
  return { stats: reviewsResponse[0].stats, allReviews };
};

const processReviews = (allReviews: Array<any>) => {
  let recommended = 0;

  try {
    const adaptedReviews: Array<Review> = allReviews
      .sort((a, b) => Date.parse(b.date_created) - Date.parse(a.date_created))
      .reduce((acc, curr) => {
        if (curr.rating >= 3) {
          recommended++;
        }

        if (!isReviewEligible(curr)) return acc;

        const newReview = { reviewer: {}, productData: {} } as Review;

        newReview.rating = curr.rating;
        newReview.reviewComment = curr.comments;
        newReview.reviewer = fetchReviewerInformation(curr);

        const questions: Array<any> = curr.ratings;
        const cityQuestion = {
          userEntry: questions.find((q) =>
            q.name.startsWith('In what city did you work with Opendoor'),
          ),
          branchQuestion: (curr.branch || {}).name,
        };
        const productTypeQuestion = questions.find((q) =>
          q.name.startsWith('How did you work with Opendoor'),
        );

        newReview.productData.city = normalizeCity(
          cityQuestion.branchQuestion || cityQuestion.userEntry.score,
        );

        newReview.productData.productType = answerToProductType[productTypeQuestion.score];

        acc.push(newReview);
        return acc;
      }, []);

    return {
      filteredReviews: adaptedReviews,
      recommended,
    };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('error occured while processing reviews', err);
    return {
      filteredReviews: [],
      recommended: 0,
    };
  }
};

export const fetchReviews = async () => {
  try {
    const { allReviews, stats } = await fetchAllReviews();

    const { filteredReviews, recommended } = processReviews(allReviews);

    console.log(recommended, allReviews.length);

    const reviewsStats: ReviewsStats = {
      averageRating: Number(stats.average_rating),
      totalReviews: Number(stats.total_reviews),
      recommendedPercent: Math.round((recommended / allReviews.length) * 100),
    };
    const reviewsResult = { reviews: filteredReviews, stats: reviewsStats };
    return { productTypeFeeds: buildFeedItems(reviewsResult), stats: reviewsStats };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('error occured while processing reviews', err);
    return { productTypeFeeds: [], stats: {} };
  }
};

// Given a potential header, and a character cutoff, split the header before it
// passes the character limit, and remove up to 3 trailing "linkWords", so we
// don't end on an "and", "to", etc.
const determineHeadingSplit = (
  content: string,
  charCutoff: number,
  linkWordsLimit: number,
): [Array<string>, Array<string>, boolean] => {
  const words = content.split(/\s+/).filter((t) => t.length > 0);
  let charCount = 0;
  const headingWords: any[] = [];
  let restWords: any[] = [];
  for (let i = 0; i < words.length; i++) {
    charCount += words[i].length;
    if (charCount > charCutoff) {
      restWords = restWords.concat(words.slice(i));
      break;
    }
    headingWords.push(words[i]);
  }
  let i = 0;
  while (i < linkWordsLimit) {
    if (
      STOP_WORDS.find(
        (link) => headingWords[headingWords.length - 1].replace(/\W+/g, '').toLowerCase() === link,
      )
    ) {
      restWords.unshift(headingWords.pop());
    } else {
      break;
    }
    i++;
  }
  return [headingWords, restWords, restWords.length > 0];
};

export const commentParts: (comment: string) => {
  heading: string;
  body?: string;
  ellipsize?: boolean;
} = (comment) => {
  // fix bad Opendoor branding, and open listings branding
  const normalizedComment = comment
    .replace(/Open\s+Door|OpenDoor|OPENDOOR|opendoor|open\s+door/g, 'Opendoor')
    .replace(/open\s+[lL]istings|OpenListings|openlistings|open\s+listing/, 'Open Listings');
  const [likelyHeading, ...rest] = normalizedComment.split('.');
  const likelyBody = rest.join('. ');
  const likelyBodyWords = likelyBody.split(/\s+/);

  // Add back the period that split removed.
  // eslint-disable-next-line prefer-const
  let [headingWords, restWords, ellipsize] = determineHeadingSplit(likelyHeading, 60, 3);
  let bodyWords = restWords.concat(likelyBodyWords);

  // if the body is small enough (less than 5 words), we just return it as the
  // heading, and skip ellipsizing it
  if (bodyWords.length < 5) {
    headingWords = headingWords.concat(bodyWords);
    bodyWords = [];
    ellipsize = false;
  }
  return { heading: headingWords.join(' '), body: bodyWords.join(' '), ellipsize, STOP_WORDS };
};
