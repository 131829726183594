import { Box, Text, TextProps } from '@opendoor/bricks-next';
import { Image, Link } from '@opendoor/bricks/core';

import { EntryComponent } from 'cms/entries/entries';

import { generatePresetPictureSet } from 'components/shared/performance/pictures';

import { ILpComponentBasicPageHeader } from 'declarations/contentful';

import ContextualField from './ContextualField';
import { HERO_IMAGE_DESKTOP_PROPS, HERO_IMAGE_MOBILE_PROPS } from './HeroV2';
import { LPLink } from './shared/Buttons';
import Container from './shared/Container';
import ExtraCtas from './shared/ExtraCtas';
import {
  BNColorThemes,
  getBNComponentThemeColors,
  getComponentThemeColors,
} from './shared/styles/ComponentTheme';
import { Desc, Eyebrow } from './shared/Typography';
import { contentfulToImagesWorkerUrl, markdownToReactElement } from './shared/utils';

type ForeGroundColor = {
  Light: TextProps['color'];
  Dark: TextProps['color'];
};

const RenderBasicPageHeader = (entry: ILpComponentBasicPageHeader) => {
  const { fields } = entry;
  // TODO: Remove the 'componentTheme' variable once all components using it are migrated to bricks-next.
  const componentTheme = fields.backgroundColor
    ? getComponentThemeColors(fields.backgroundColor)
    : getComponentThemeColors('White 0');

  const foregroundColor: ForeGroundColor = {
    Light: '$contentInversePrimary',
    Dark: '$contentPrimary',
  };
  const theme = getBNComponentThemeColors(
    fields.backgroundColor in BNColorThemes
      ? (fields.backgroundColor as BNColorThemes)
      : BNColorThemes.Light,
  );

  const imagePosition = fields.imagePosition || 'None';
  const bgImage = imagePosition == 'Background' && fields.image ? fields.image.fields.file.url : '';
  const noImage = fields.image === null || fields.image === undefined;
  const headlineLength = fields?.title?.length || 0;
  const longHeadline = headlineLength > 20;
  const customCTAconfig = fields.customCtaConfig;

  const showCustomCTAButton = fields.customCtaButton,
    showDefaultCTALink =
      !showCustomCTAButton && (fields.ctaDisplayText || fields.ctaUrl || fields.extraCtas);

  const showAppStoreLinks = showCustomCTAButton && customCTAconfig?.buttonStyle === 'appLinks';

  const appleStoreLink = customCTAconfig?.appStoreLink as string,
    googlePlayStoreLink = customCTAconfig?.playStoreLink as string;

  return (
    <Box
      className="lp-section"
      backgroundColor={theme.bgPrimary}
      style={{
        backgroundSize: 'cover',
        backgroundPosition: 'center center',
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <Container
        px="$12x"
        py={fields.titleSize === 'Large' ? '$50x' : '$44x'}
        $largerThanSM={{ py: fields.titleSize === 'Large' ? '$32x' : '$28x', px: '$20x' }}
      >
        {fields.title && (
          <Box
            minHeight={noImage ? 'unset' : 510}
            gap="$24x"
            $largerThanMD={{
              minHeight: fields.layout === 'Large' || noImage ? 'unset' : 430,
              maxHeight: fields.layout === 'Large' || noImage ? 'unset' : 744,
              flexDirection: fields.layout === 'Large' ? 'column' : 'row',
            }}
            justifyContent="center"
          >
            <Box
              gap="$12x"
              $largerThanMD={{
                flex: 1,
                flexBasis: fields.layout === 'Large' ? 'unset' : 0,
                alignItems: fields.layout === 'Large' ? 'center' : 'unset',
              }}
              justifyContent="center"
            >
              {fields.eyebrow && (
                <Eyebrow
                  color={foregroundColor[fields.foregroundColor || 'Dark']}
                  size={fields.eyebrowSize === 'Large' ? '$bodyXlarge' : '$bodySmall'}
                >
                  {fields.eyebrow}
                </Eyebrow>
              )}
              <Box gap="$16x">
                <Box>
                  <Text
                    tag="h1"
                    typography={
                      fields.titleSize === 'Large' || !longHeadline
                        ? '$headerMedium'
                        : '$headerSmall'
                    }
                    $largerThanSM={{
                      typography:
                        fields.titleSize === 'Large' || !longHeadline
                          ? '$displaySmall'
                          : '$headerMedium',
                    }}
                    $largerThanMD={{
                      textAlign: fields.layout === 'Large' ? 'center' : 'unset',
                      typography:
                        fields.titleSize === 'Large' || !longHeadline
                          ? '$displaySmall'
                          : '$headerMedium',
                    }}
                    display="block"
                    color={foregroundColor[fields.foregroundColor || 'Dark']}
                  >
                    {markdownToReactElement(fields.title || '')}
                  </Text>
                  {fields.titleAccent && (
                    <Text
                      tag="span"
                      color={theme.contentPrimaryAccent}
                      typography={
                        fields.titleSize === 'Large' || !longHeadline
                          ? '$headerMedium'
                          : '$headerSmall'
                      }
                      $largerThanSM={{
                        typography:
                          fields.titleSize === 'Large' || !longHeadline
                            ? '$displaySmall'
                            : '$headerMedium',
                      }}
                      $largerThanMD={{
                        textAlign: fields.layout === 'Large' ? 'center' : 'unset',
                        typography:
                          fields.titleSize === 'Large' || !longHeadline
                            ? '$displaySmall'
                            : '$headerMedium',
                      }}
                    >
                      {markdownToReactElement(fields.titleAccent || '')}
                    </Text>
                  )}
                </Box>
                {fields.desc && (
                  <Box>
                    <Desc
                      color={foregroundColor[fields.foregroundColor || 'Dark']}
                      $largerThanMD={{
                        textAlign: fields.layout === 'Large' ? 'center' : 'unset',
                        marginRight: fields.layout === 'Large' ? 0 : '$16x',
                      }}
                    >
                      {fields.desc}
                    </Desc>
                  </Box>
                )}

                {fields.contextualField && (
                  <Box
                    display="flex"
                    alignItems="flex-start"
                    width="100%"
                    mt={7}
                    $largerThanMD={{
                      alignItems: fields.layout === 'Large' ? 'center' : 'flex-start',
                    }}
                  >
                    <ContextualField
                      theme={componentTheme}
                      contextFields={fields.contextualField}
                      inputLocation="basic-page-header"
                    />
                  </Box>
                )}
                {showDefaultCTALink && (
                  <Box
                    display="flex"
                    flexDirection="row"
                    flexWrap="wrap"
                    alignItems="center"
                    gap={5}
                    mt={8}
                    $largerThanMD={{
                      justifyContent: fields.layout === 'Large' ? 'center' : 'unset',
                    }}
                  >
                    {fields.ctaDisplayText && fields.ctaUrl && (
                      <LPLink
                        aria-label={`Go to page ${fields?.ctaDisplayText}`}
                        analyticsName={`cosmos-landing-page-basic-page-header-${fields?.analyticsId}`}
                        componentTheme={componentTheme}
                        bnTheme={theme}
                        ctaStyle={fields.ctaStyle}
                        ctaDisplayText={fields.ctaDisplayText}
                        ctaUrl={fields.ctaUrl}
                        mt="0"
                      >
                        {fields.ctaDisplayText}
                      </LPLink>
                    )}
                    {fields.extraCtas && (
                      <ExtraCtas ctas={fields.extraCtas} componentTheme={componentTheme} />
                    )}
                  </Box>
                )}

                {showAppStoreLinks && (
                  <Box
                    display="flex"
                    flexDirection="column"
                    $largerThanSM={{ flexDirection: 'row' }}
                    justifyContent="space-between"
                    backgroundColor="$backgroundTertiary"
                    padding="$6x"
                    gap="$4x"
                    borderRadius="$8x"
                    width="100%"
                    marginTop="$8x"
                    $largerThanMD={{ maxWidth: fields.layout === 'Large' ? '100%' : '80%' }}
                  >
                    <Text
                      tag="p"
                      minWidth={'max-content'}
                      typography="$bodySmall"
                      fontWeight="$medium"
                    >
                      Get the app
                    </Text>

                    <Box display="flex" flexDirection="row" flexWrap="wrap" gap="$4x">
                      <Link
                        target="_blank"
                        href={appleStoreLink}
                        aria-label="Opendoor Applestore button"
                        analyticsName="cosmos-landing-page-v2-header-cta-applestore-link"
                      >
                        <Image
                          src="https://images.opendoor.com/source/s3/imgdrop-production/2023-12-1702502078078-95093.png?preset=square-2048"
                          loading="lazy"
                          alt="Opendoor Applestore"
                          width={135}
                          height={45}
                        />
                      </Link>
                      <Link
                        target="_blank"
                        href={googlePlayStoreLink}
                        aria-label="Opendoor Google play button"
                        analyticsName="cosmos-landing-page-v2-header-cta-google-playstore-link"
                      >
                        <Image
                          src="https://images.opendoor.com/source/s3/imgdrop-production/2023-12-1702502078081-28903.png?preset=square-2048"
                          loading="lazy"
                          alt="Opendoor Google Play"
                          width={135}
                          height={45}
                        />
                      </Link>
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>
            {fields.image && imagePosition === 'Right' && (
              <>
                {/* Desktop hero image */}
                <Box
                  $largerThanMD={{
                    flex: 1,
                    flexBasis: fields.layout === 'Large' ? 'unset' : 0,
                    minHeight: 430,
                    maxHeight: 744,
                    display: 'flex',
                  }}
                  $largerThanSM={{
                    display: 'block',
                  }}
                  $smallerThanMD={{
                    display: 'none',
                  }}
                  justifyContent="center"
                  borderRadius={'$16x'}
                  overflow="hidden"
                >
                  {generatePresetPictureSet({
                    ...HERO_IMAGE_DESKTOP_PROPS,
                    photoUrl: contentfulToImagesWorkerUrl(fields.image.fields.file.url),
                  })}
                </Box>
                {/* Mobile hero image */}
                <Box
                  $largerThanSM={{
                    display: 'none',
                  }}
                  $smallerThanMD={{
                    display: 'block',
                  }}
                  justifyContent="center"
                  borderRadius={'$16x'}
                  overflow="hidden"
                >
                  {generatePresetPictureSet({
                    ...HERO_IMAGE_MOBILE_PROPS,
                    photoUrl: contentfulToImagesWorkerUrl(fields.image.fields.file.url),
                  })}
                </Box>
              </>
            )}
          </Box>
        )}
      </Container>
    </Box>
  );
};

const BasicPageHeader: EntryComponent<ILpComponentBasicPageHeader> = {
  render: RenderBasicPageHeader,
};

export default BasicPageHeader;
