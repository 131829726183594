// storybook-check-ignore
import { useState } from 'react';

import { css } from '@emotion/react';
import { Box, useMedia } from '@opendoor/bricks-next';
import { Link } from '@opendoor/bricks/core';
import { novo } from '@opendoor/bricks/theme';

import { AddressSearchLocation } from 'components/globals';
import AddressSearch from 'components/shared/AddressSearch';

import { ILpComponentContextualFields } from 'declarations/contentful';

import { AgentSelectListBox, RegionListBox, SupportListBox } from './shared/ListBox';
import { IComponentThemeOptions } from './shared/styles/ComponentTheme';

interface IContextualFieldProps {
  contextFields: ILpComponentContextualFields;
  theme: IComponentThemeOptions;
  inputLocation: AddressSearchLocation;
}

const LinkToggle = ({
  text,
  analyticsId,
  active,
  onClick,
}: {
  text: string;
  analyticsId?: string;
  active: boolean;
  onClick?: () => void;
}) => {
  const backgroundColor = active ? 'brandBlue600' : 'transparent';
  const color = active ? 'white0' : 'brandBlue600';
  const hoverColor = active ? novo.colors.white0 : novo.colors.blue500;

  return (
    <Link
      aria-label={`Go to page ${text}`}
      analyticsName={`cosmos-landing-page-link-list-${analyticsId || ''}-${text}`}
      backgroundColor={backgroundColor}
      color={color}
      cursor="pointer"
      py={2}
      px={6}
      fontWeight="medium"
      letterSpacing="-1.4"
      onClick={onClick}
      _hover={{
        color: hoverColor,
      }}
      css={css`
        border-radius: 99em;
      `}
    >
      {text}
    </Link>
  );
};

const BuySellToggle = ({
  regionSelectBox,
  addressInput,
}: {
  regionSelectBox: React.ReactElement;
  addressInput: React.ReactElement;
}) => {
  const [sellActive, setSellActive] = useState(true);
  return (
    <Box
      display="flex"
      flexDirection="row"
      gap="$2x"
      alignItems="center"
      justifyContent="space-between"
      maxWidth={480}
      width="100%"
      zIndex={500}
    >
      <Box
        tag="ul"
        margin={0}
        padding={3}
        role="list"
        display="inline-flex"
        backgroundColor="$backgroundPrimary"
        width="min-content"
        borderRadius="$10x"
        css={css`
          list-style: none;
          box-shadow: inset 0 0 0 1px ${novo.colors.warmgrey300};
        `}
      >
        <Box tag="li" display="inline-flex">
          <LinkToggle
            text="Sell"
            analyticsId=""
            active={sellActive}
            onClick={() => setSellActive(true)}
          />
        </Box>
        <Box tag="li" display="inline-flex">
          <LinkToggle
            text="Buy"
            analyticsId=""
            active={!sellActive}
            onClick={() => setSellActive(false)}
          />
        </Box>
      </Box>
      <Box width="80%">{sellActive ? addressInput : regionSelectBox}</Box>
    </Box>
  );
};

const ContextualField = ({ contextFields, theme, inputLocation }: IContextualFieldProps) => {
  const fields = contextFields.fields;
  const isBuySell = contextFields.fields.showBuysellSwitch;

  // set the page set for address entry on home guide alt landing page
  const pageSetPath =
    contextFields.fields.analyticsId === 'homeguide-ae'
      ? '?seller_flow=home_guide_nav_entry'
      : undefined;
  const { largerThanMD } = useMedia();

  // build agent links array
  const agentLinkOptions = [];
  if (contextFields.fields.agentLinkOneText && contextFields.fields.agentLinkOneUrl) {
    agentLinkOptions.push({
      label: contextFields.fields.agentLinkOneText,
      href: contextFields.fields.agentLinkOneUrl,
    });
  }

  if (contextFields.fields.agentLinkTwoText && contextFields.fields.agentLinkTwoUrl) {
    agentLinkOptions.push({
      label: contextFields.fields.agentLinkTwoText,
      href: contextFields.fields.agentLinkTwoUrl,
    });
  }

  if (contextFields.fields.agentLinkThreeText && contextFields.fields.agentLinkThreeUrl) {
    agentLinkOptions.push({
      label: contextFields.fields.agentLinkThreeText,
      href: contextFields.fields.agentLinkThreeUrl,
    });
  }

  if (isBuySell)
    return (
      <BuySellToggle
        addressInput={
          <Box className="hp-reskin-v2-address-entry-wrapper">
            <AddressSearch
              hideLabel
              inputLocation={inputLocation}
              analyticsPrefix={contextFields.fields.analyticsId || ''}
              trackingTaxonomy="new_homepage"
              actionText={fields?.ctaButtonText || 'Get cash offer'}
              actionTextSmall={fields?.addressCtaTextSmall || 'Get offer'}
              placeholderText={
                largerThanMD
                  ? fields.addressInputPlaceholderText
                  : fields?.addressInputPlaceholderTextSmall
              }
              showShadow={false}
              channel={fields.channel ?? 'DEFAULT'}
              trackingKeyword={contextFields.fields.trackingKeyword}
            />
          </Box>
        }
        regionSelectBox={<RegionListBox theme={theme} onSelect={() => {}} />}
      />
    );

  switch (fields.contextualField) {
    case 'address-search':
      return (
        <Box className="hp-reskin-v2-address-entry-wrapper" $largerThanMD={{ width: 512 }}>
          <AddressSearch
            hideLabel
            inputLocation={inputLocation}
            analyticsPrefix={contextFields.fields.analyticsId || ''}
            trackingTaxonomy="new_homepage"
            actionText={fields?.ctaButtonText || 'Get cash offer'}
            actionTextSmall={fields?.addressCtaTextSmall || 'Get offer'}
            placeholderText={
              largerThanMD
                ? fields.addressInputPlaceholderText
                : fields?.addressInputPlaceholderTextSmall
            }
            showShadow={false}
            channel={fields.channel ?? 'DEFAULT'}
            trackingKeyword={contextFields.fields.trackingKeyword}
            pageSetPath={pageSetPath}
          />
        </Box>
      );

    case 'agents':
      return <AgentSelectListBox theme={theme} linkOptions={agentLinkOptions} />;

    case 'region':
      return <RegionListBox theme={theme} onSelect={() => {}} />;

    case 'support':
      return <SupportListBox theme={theme} />;

    default:
      return null;
  }
};

export default ContextualField;
