// storybook-check-ignore
import { useCallback, useEffect, useRef, useState } from 'react';

import { css } from '@emotion/react';
import { Box, Link, Text } from '@opendoor/bricks/core';
import { motion } from 'framer-motion';

import { IMenuLink } from '../header/Header';
import { defaultEase } from '../motion/MotionVariants';
import { trapFocus } from '../trapFocus';

const menuVariants = {
  hidden: {
    opacity: 0,
    transition: {
      ease: 'linear',
      duration: 0.1,
      delay: 1,
    },
    transitionEnd: {
      display: 'none',
    },
  },
  visible: {
    opacity: 1,
    transition: {
      ease: 'linear',
      duration: 0.1,
    },
    display: 'block',
  },
};

const backdropVariants = {
  hidden: {
    opacity: 0,
    transition: {
      ease: defaultEase,
      delay: 0.3,
      duration: 0.2,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      ease: defaultEase,
      duration: 0.2,
    },
  },
};

const boxVariants = {
  hidden: {
    x: '100%',
    transition: {
      ease: defaultEase,
      duration: 0.5,
    },
  },
  visible: {
    x: 0,
    transition: {
      ease: defaultEase,
      duration: 0.5,
    },
  },
};

const navList = {
  hidden: {
    opacity: 0,
    x: 64,
    transition: {
      duration: 0.4,
      staggerChildren: 0.05,
    },
  },
  visible: {
    opacity: 1,
    x: 0,
    transition: {
      duration: 0.4,
      // The first child will appear AFTER the parent has appeared on the screen
      staggerChildren: 0.05,
    },
  },
};

const ctaVariant = {
  hidden: {
    opacity: 0,
    transition: {
      ease: 'linear',
      duration: 0.3,
    },
  },
  visible: {
    opacity: 1,
    transition: {
      ease: 'linear',
      delay: 0.3,
      duration: 0.3,
    },
  },
};

interface IMenuProps {
  slug: string;
  menuPanelNavLinks: IMenuLink[];
  mainNavLinks: IMenuLink[];
  ctaTitle?: string;
  ctaLink?: IMenuLink;
  isVisible?: boolean;
  setIsVisible: (isVisible: boolean) => void;
  exitComplete: () => void;
}

const Menu = ({
  slug,
  menuPanelNavLinks,
  mainNavLinks,
  ctaLink,
  ctaTitle,
  isVisible,
  setIsVisible,
  exitComplete,
}: IMenuProps) => {
  const dialogId = 'dialog-main-menu';
  const containerRef = useRef<HTMLDivElement>(null);
  const animate = isVisible ? 'visible' : 'hidden';
  const onComplete = (definition: 'visible' | 'hidden') => {
    // Only focus on main container when the visible animation is complete
    // or it messes up the animation
    if (definition === 'visible') {
      containerRef.current?.focus();
    } else {
      exitComplete();
    }
  };

  // when there are more than two nav links we collapse into menu panel for tablet
  const tabletDisplay = mainNavLinks.length > 2 ? 'flex' : 'none';

  return (
    <Box
      as={motion.div}
      variants={menuVariants}
      animate={animate}
      onKeyDown={(e) => trapFocus(e, containerRef)}
      bottom={0}
      display="grid"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex={1000}
    >
      <Box
        onAnimationComplete={(definition: any) => {
          onComplete(definition);
        }}
        as={motion.div}
        variants={boxVariants}
        initial="hidden"
        animate={isVisible ? 'visible' : 'hidden'}
        exit="hidden"
        tabIndex={-1}
        ref={containerRef}
        backgroundColor="white0"
        gridArea="1 / 1 / -1 / -1"
        pt={[200, 168, 168]}
        pl={[7, null, 10]}
        pr={[7, null, 10]}
        pb={[7, null, 10]}
        maxW={['100%', null, '424px']}
        bottom="0px"
        left={[0, null, 'auto']}
        right="0"
        position="fixed"
        top="0px"
        w="100%"
        zIndex="10"
        role="dialog"
        aria-labelledby={dialogId}
        aria-modal="true"
        display="grid"
        gridTemplate="1fr / 1fr auto"
        overflow="auto"
        outline="0"
        css={css`
          overscroll-behavior-y: contain;
        `}
      >
        <Box
          as={motion.ul}
          animate={animate}
          variants={navList}
          gridColumn="1 / -1"
          gridRow="1 / 2"
          m="0"
          p="0"
          role="list"
          css={css`
            list-style: none;
          `}
        >
          {mainNavLinks.map((nav, idx) => (
            <Text
              as={motion.li}
              mb="32px"
              size="160"
              fontWeight="medium"
              key={`main-${idx}`}
              display={['flex', null, tabletDisplay, 'none']}
            >
              <Link
                aria-current={slug === nav.href}
                href={nav.href}
                aria-label="View link"
                analyticsName={`cosmos-landing-page-v2-nav-link-${nav.name}`}
                alignItems="center"
                color="neutrals100"
                display="inline-flex"
                lineHeight="100"
                fontWeight="medium"
              >
                {nav.name}
              </Link>
            </Text>
          ))}
          {menuPanelNavLinks?.map((link, idx) => (
            <Text
              as={motion.li}
              mb="32px"
              size="160"
              fontWeight="medium"
              key={`panel-${idx}`}
              variants={navList}
            >
              <Link
                aria-current={slug === link.href}
                alignItems="center"
                color="neutrals100"
                display="inline-flex"
                lineHeight="100"
                fontWeight="medium"
                analyticsName={`cosmos-landing-page-v2-menu-link-${link.name}`}
                aria-label="View link"
                href={link.href}
              >
                {link.name}
              </Link>
            </Text>
          ))}
        </Box>
        <Box
          as={motion.div}
          variants={ctaVariant}
          animate={animate}
          gridColumn="1 / -1"
          gridRow="2 / -1"
          pt={[7, null, 9]}
          w="100%"
        >
          {ctaLink?.name && ctaLink?.href && (
            <Link
              href={ctaLink.href}
              aria-label=""
              analyticsName={`cosmos-landing-page-v2-menu-cta-link-${ctaLink.name}`}
              backgroundColor="blue50"
              borderRadius="semiRounded"
              color="warmgrey950"
              display="block"
              fontWeight="medium"
              p="16px"
              _hover={{
                color: 'brandBlue600',
              }}
            >
              {ctaTitle && (
                <Text as="span" display="block" size="90" fontWeight="inherit">
                  {ctaTitle}
                </Text>
              )}
              <Text as="span" color="inherit" display="block" size="110" fontWeight="inherit">
                {ctaLink.name}
              </Text>
            </Link>
          )}
        </Box>
      </Box>
      <Box
        as={motion.div}
        variants={backdropVariants}
        initial="hidden"
        animate={isVisible ? 'visible' : 'hidden'}
        exit="hidden"
        bottom={0}
        position="fixed"
        top={0}
        left={0}
        right={0}
        aria-hidden="true"
        zIndex="0"
        onClick={() => setIsVisible(false)}
        css={css`
          background-color: rgba(0, 0, 0, 0.2);
        `}
      />
    </Box>
  );
};

const MenuPanel = ({
  slug,
  menuPanelNavLinks,
  mainNavLinks,
  ctaLink,
  ctaTitle,
  isVisible,
  setIsVisible,
  exitComplete,
}: IMenuProps) => {
  const [mounted, setMounted] = useState(false);
  useEffect(() => setMounted(true), []);

  useEffect(() => {
    document.body.classList.toggle('has-sheet', isVisible);
  }, [isVisible]);

  const escFunction = useCallback((event: { key: string }) => {
    if (event.key === 'Escape') {
      setIsVisible(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', escFunction, false);

    return () => {
      document.removeEventListener('keydown', escFunction, false);
    };
  }, [escFunction]);

  return mounted ? (
    <Menu
      slug={slug}
      menuPanelNavLinks={menuPanelNavLinks}
      mainNavLinks={mainNavLinks}
      ctaLink={ctaLink}
      ctaTitle={ctaTitle}
      isVisible={isVisible}
      setIsVisible={setIsVisible}
      exitComplete={exitComplete}
    />
  ) : null;
};

export default MenuPanel;
