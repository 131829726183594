/* storybook-check-ignore */
import { useState } from 'react';

import { Box, Button, Dropdown, Icons, Image, Text } from '@opendoor/bricks-next';

import { ILpComponentOpenPositions } from 'declarations/contentful';

import { LandingPageSectionContainer } from '../shared/Container';
import {
  commentParts,
  FeedItem,
  fetchReviews,
  Review,
  ReviewFeedItem,
  ReviewsIOFeedItem,
} from './api';

enum ProductType {
  buy = 'buy',
  sell = 'sell',
  tradeIn = 'trade-in',
}

const FILTER_OPTIONS = [
  {
    label: 'Sold to Opendoor',
    value: ProductType.sell,
  },
  {
    label: 'Bought with Opendoor',
    value: ProductType.buy,
  },
  {
    label: 'Bought and sold with Opendoor',
    value: ProductType.tradeIn,
  },
];

const productTypeToExplainer: {
  [key in Review['productData']['productType']]: { heading: string; description: string };
} = {
  sell: {
    heading: 'Seller',
    description: 'These reviews are from customers that sold their home directly to Opendoor.',
  },
  buy: {
    heading: 'Buyer',
    description: 'These reviews are from customers that bought their home with Opendoor.',
  },
  'trade-in': {
    heading: 'Trade-in',
    description:
      'These reviews are from customers that sold their home directly to Opendoor and purchased their new home at the same time.',
  },
};

const typeToHeading: { [key in Review['productData']['productType']]: string } = {
  buy: 'Bought with Opendoor',
  sell: 'Sold to Opendoor',
  'trade-in': 'Bought and sold with Opendoor',
};
const typeToProduct: { [key in Review['productData']['productType']]: string } = {
  buy: 'Buyer',
  sell: 'Seller',
  'trade-in': 'Buyer and Seller',
};

const ThirdParyVerification = () => (
  <Box
    backgroundColor="$backgroundSecondary"
    paddingVertical="$16x"
    paddingHorizontal="$10x"
    borderRadius="$12x"
    gap="$12x"
    $largerThanSM={{
      paddingHorizontal: '$56x',
    }}
  >
    <Box
      $largerThanSM={{
        flexDirection: 'row',
      }}
      alignItems="center"
      gap="$4x"
      justifyContent="center"
    >
      <Text tag="p" typography="$subheaderSmall">
        Third Party
      </Text>
      <Box flexDirection="row" gap="$4x" alignItems="center">
        <Text tag="p" typography="$labelMedium" color="$contentAccentBrand">
          Verified Review
        </Text>
        <Icons.Checkmark size={20} color="$contentAccentBrand" />
      </Box>
    </Box>
    <Text tag="p" typography="$bodyLarge" justifyContent="center" textAlign="center">
      All reviews published on this page were submitted to a 3rd party review site. Each review has
      been been verified using additional trust signals to ensure it is genuine/authentic.
    </Text>
  </Box>
);

const ReviewsIoCard = ({ reviewsIo }: { reviewsIo: ReviewsIOFeedItem }) => (
  <Box
    backgroundColor="$backgroundSecondary"
    paddingVertical="$16x"
    borderRadius="$12x"
    gap="$12x"
    $largerThanSM={{
      flexDirection: 'row',
      paddingHorizontal: '$56x',
    }}
  >
    <Box alignItems="center" gap="$8x" justifyContent="space-around" flex={1}>
      <Image
        alt="Reviews io logo"
        source={{
          height: 28,
          uri: 'https://imgdrop.imgix.net/2024-11-1732437828073-65898.png',
          width: 175,
        }}
      />
      <Box flexDirection="row">
        {new Array(5).fill('star').map((_val, index) => (
          <Image
            key={index}
            alt="Star Rating"
            source={{
              height: 16,
              uri: 'https://imgdrop.imgix.net/2024-11-1732263853253-76957.svg',
              width: 16,
            }}
          />
        ))}
      </Box>
      <Text typography="$bodySmall" tag="p">
        {reviewsIo.stats?.averageRating} Rating | {reviewsIo.stats?.totalReviews} reviews
      </Text>
    </Box>
    <Box gap="$8x" justifyContent="space-around" alignItems="center" flex={1}>
      <Text typography="$headerSmall" tag="p" textAlign="center">
        {reviewsIo.stats?.recommendedPercent}%
      </Text>
      <Text typography="$bodySmall" tag="p" textAlign="center">
        of reviewers recommend Opendoor
      </Text>
      <Button
        variant="secondaryInverse"
        label="See reviews.io"
        size="small"
        analyticsName="reviews-io-link"
        href="https://www.reviews.io/company-reviews/store/opendoor-com"
        target="_blank"
      ></Button>
    </Box>
  </Box>
);

const BbbOrgCard = () => (
  <Box
    backgroundColor="$backgroundSecondary"
    paddingVertical="$16x"
    borderRadius="$12x"
    gap="$12x"
    $largerThanSM={{
      flexDirection: 'row',
      paddingHorizontal: '$56x',
    }}
  >
    <Box alignItems="center" gap="$8x" justifyContent="center" flex={1}>
      <Image
        alt="BBB logo"
        source={{
          height: 54,
          uri: 'https://imgdrop.imgix.net/2024-11-1732441222067-81668.png',
          width: 175,
        }}
      />
      <Text typography="$bodySmall" tag="p">
        Accredited since: 10/15/2015
      </Text>
    </Box>
    <Box gap="$8x" justifyContent="space-around" alignItems="center" flex={1}>
      <Text
        typography="$headerSmall"
        tag="p"
        textAlign="center"
        $platform-web={{
          color: '#015A78',
        }}
      >
        A+
      </Text>
      <Text typography="$bodySmall" tag="p" textAlign="center">
        BBB rating
      </Text>
      <Button
        variant="secondaryInverse"
        label="Visit BBB.org"
        size="small"
        analyticsName="BBB Link Button"
        href="https://www.bbb.org/us/az/tempe/profile/real-estate-services/opendoor-1126-1000060421/customer-reviews"
        target="_blank"
        $platform-web={{
          backgroundColor: '#015A78',
        }}
      ></Button>
    </Box>
  </Box>
);

const ReviewCard = ({ review }: { review: Review }) => {
  const { reviewer } = review;
  const isAnonymous = !review.reviewer.name;

  const { heading, body, ellipsize } = commentParts(review.reviewComment);

  const renderRatings = () => (
    <Box flexDirection="row">
      {new Array(review.rating).fill('star').map((_val, index) => (
        <Image
          key={index}
          alt="Rating star"
          source={{
            height: 16,
            uri: 'https://imgdrop.imgix.net/2024-11-1732263853253-76957.svg',
            width: 16,
          }}
        />
      ))}
    </Box>
  );

  return (
    <Box
      paddingHorizontal="$20x"
      paddingVertical="$16x"
      borderWidth="$0dot5x"
      borderRadius="$10x"
      borderColor="$borderPrimary"
      gap="$12x"
    >
      {renderRatings()}
      <Box
        gap="$6x"
        $largerThanSM={{
          flexDirection: 'row',
        }}
      >
        <Text typography="$subheaderSmall" tag="p" flexDirection="row">
          {isAnonymous
            ? `${typeToProduct[review.productData.productType]} in ${review.productData.city}`
            : reviewer.name}
        </Text>
        <Box flexDirection="row" alignItems="center" gap="$2x">
          <Text tag="p" typography="$labelMedium" color="$contentAccentBrand">
            Verified Review
          </Text>
          <Icons.Checkmark size={20} color="$contentAccentBrand" />
        </Box>
      </Box>
      {!isAnonymous && (
        <Text typography="$labelMedium" tag="p">
          {typeToHeading[review.productData.productType]} in {review.productData.city}
        </Text>
      )}
      <Text typography="$subheaderLarge" tag="p">
        {heading}
        {ellipsize && '...'}
      </Text>
      <Text typography="$bodyLarge" tag="p">
        {body?.trim()}
      </Text>
    </Box>
  );
};

const ReviewList = (_entry: ILpComponentOpenPositions, resolvedData: any) => {
  const [filterType, setFilterType] = useState<ProductType>(ProductType.sell);

  const [reviewCount, setReviewCount] = useState(10);

  const allFeedData = resolvedData.productTypeFeeds[filterType || ProductType.sell];

  const explainer = productTypeToExplainer[filterType || ProductType.sell];

  const paginatedFeedData = allFeedData.slice(0, reviewCount);

  const renderFeedItem = (feedItem: FeedItem, key: number) => {
    switch (feedItem.type) {
      case 'review':
        return <ReviewCard key={key} review={feedItem.reviewInfo} />;

      case 'disclaimer':
        return <ThirdParyVerification />;

      case 'reviewsio':
        return <ReviewsIoCard reviewsIo={feedItem} />;

      case 'bbb':
        return <BbbOrgCard />;

      default:
        return null;
    }
  };

  return (
    <LandingPageSectionContainer minHeight={400}>
      <Box
        gap="$12x"
        $largerThanSM={{
          flexDirection: 'row',
          justifyContent: 'space-between',
        }}
      >
        <Box
          justifyContent="space-between"
          gap="$8x"
          $largerThanSM={{
            width: 315,
          }}
          $largerThanMD={{
            width: 525,
          }}
        >
          <Text tag="h3" typography="$subheaderLarge">
            {explainer.heading}
          </Text>
          <Text tag="h3" typography="$bodyLarge">
            {explainer.description}
          </Text>
        </Box>
        <Box
          width="100%"
          $largerThanSM={{
            width: 315,
          }}
        >
          <Dropdown
            required
            title="Review types"
            size="small"
            options={FILTER_OPTIONS}
            prefilledValue={ProductType.sell}
            onValueChange={(value) => {
              setReviewCount(10);
              setFilterType(value as ProductType);
            }}
          />
        </Box>
      </Box>
      <Box marginVertical="$16x" gap="$16x">
        {paginatedFeedData.map((feedItem: ReviewFeedItem, index: number) =>
          renderFeedItem(feedItem, index),
        )}
      </Box>

      {reviewCount < allFeedData.length && (
        <Box justifyContent="center" alignItems="center" paddingVertical="$8x">
          <Button
            onPress={() => setReviewCount((count) => count + 10)}
            variant="primary"
            size="medium"
            label="Load More"
            analyticsName="load-more-reviews"
          />
        </Box>
      )}
    </LandingPageSectionContainer>
  );
};

const reviewsLoader = async () => {
  try {
    const { productTypeFeeds, stats } = await fetchReviews();
    return { productTypeFeeds, stats };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('error occured while processing reviews', err);
    return { productTypeFeeds: [], stats: {} };
  }
};

const Reviews = {
  render: ReviewList,
  loader: reviewsLoader,
};

export default Reviews;
