/* storybook-check-ignore */
import { ChevronForwardButton, Pill, PressableBox, Text } from '@opendoor/bricks-next';

const HomeGuideMobileBanner = () => {
  return (
    <PressableBox
      analyticsName="cosmos-home-guide-banner"
      aria-label="Pressable Box"
      backgroundColor="$backgroundInversePureBlack"
      flexDirection="row"
      justifyContent="center"
      p={20}
      href="/home-insights"
      $largerThanSM={{
        display: 'none',
      }}
      $smallerThanMD={{
        display: 'flex',
      }}
    >
      <Pill label="New" size="small" variant="inverse" weight="regularWeight" />
      <Text
        maxWidth="80%"
        alignSelf="center"
        typography="$labelXsmall"
        tag="p"
        ml={10}
        color="$contentInversePrimary"
      >
        Just getting started? Start with exclusive home data
      </Text>
      <ChevronForwardButton
        analyticsName="cosmos-home-guide-banner-chevron"
        size="small"
        variant="inverse"
      />
    </PressableBox>
  );
};

export default HomeGuideMobileBanner;
