import { useEffect, useState } from 'react';

import {
  Accordion,
  Box,
  Dropdown,
  Icons,
  Text,
  TextInput,
  TextInputWithToggle,
} from '@opendoor/bricks-next';

import StatesWithTaxRates from './constants/state-tax-rate.json';
import { markdownToReactElement } from './shared/utils';
import { useMortgageCalculator } from './utils/useMortgageCalculator';

const STATES_WITH_TAX_RATE = StatesWithTaxRates.taxRates;

const STATES_DROPDOWN_OPTIONS = STATES_WITH_TAX_RATE.map(({ name, code }) => ({
  label: name[0].toUpperCase() + name.slice(1),
  value: code,
}));

const MortgageRow = ({ name, value, percentage, pillColor }: any) => (
  <Box
    display="flex"
    flexDirection="row"
    gap="$10x"
    alignItems="center"
    px="$12x"
    justifyContent="space-between"
  >
    <Box borderRadius="$full" backgroundColor={pillColor} height={8} width={8}></Box>
    <Text typography="$bodySmall" tag="span" minWidth={140}>
      {name}
    </Text>
    <Text typography="$labelSmall" tag="span" minWidth={40} flexGrow={1}>
      ${value}
    </Text>
    <Text typography="$bodySmall" tag="span" minWidth={40}>
      {percentage}%
    </Text>
  </Box>
);

const MortgageCalculator = ({ disclosureText }: { disclosureText: string }) => {
  const [state, setState] = useState<string>('CA');

  const [homePrice, setHomePrice] = useState<number>(220000);
  const [homePriceError, setHomePriceError] = useState<string>('');

  const [downPayment, setDownPayment] = useState<number>(44000);
  const [isDownPaymentInPercentage, setIsDownPaymentInPercentage] = useState<boolean>(false);

  const [interestRate, setInterestRate] = useState<string>('3.94');
  const [interestRateError, setInterestRateError] = useState<string>('');

  const [homeInsurance, setHomeInsurance] = useState<number>(900);

  const [propertyTax, setPropertyTax] = useState<number>(1606);
  const [isPropertyTaxInPercentage, setIsPropertyTaxInPercentage] = useState<boolean>(false);

  const propertyTaxInDollars = isPropertyTaxInPercentage
    ? (homePrice / 100) * propertyTax
    : propertyTax;

  const downPaymentInDollars = isDownPaymentInPercentage
    ? (homePrice / 100) * downPayment
    : downPayment;

  const {
    totalMonthlyPayment,
    principalTotalPercentage,
    propertyTaxTotalPercentage,
    insuranceTotalPercentage,
    roundedPrincipalPerMonth,
    propertyTaxDollarsValuePerMonth,
    insuranceDollarsValue,
  } = useMortgageCalculator({
    homePrice,
    homeInsurance,
    downPayment: downPaymentInDollars,
    interestRate: Number(interestRate),
    propertyTax: propertyTaxInDollars,
  });

  const totalMontlyPaymentDisplayValue = Number(totalMonthlyPayment)
    .toLocaleString('en-US', {
      style: 'currency',
      currency: 'USD',
    })
    .slice(0, -3);

  //Compute Input errors
  let downPaymentError;

  if (downPaymentInDollars < 0 || downPaymentInDollars > homePrice) {
    downPaymentError = 'The down payment cannot exeed the home price';
  }

  let propertyTaxError;

  const propertyTaxPercentage = (propertyTaxInDollars * 100) / homePrice;

  if (propertyTaxPercentage > 5) {
    propertyTaxError = 'Please enter a valid value between 0 and 5% of homeprice';
  }

  const validateAndSetHomePrice = (value?: string) => {
    const price = Number(value?.replaceAll(',', ''));
    if (isNaN(price)) {
      return;
    }

    setHomePrice(price);

    if (price < 5000 || price > 100000000) {
      setHomePriceError('Invalid home price');
      return;
    }

    setHomePriceError('');
  };

  const validateAndSetDownPayment = (value?: string) => {
    const payment = Number(value?.replaceAll(',', ''));
    if (isNaN(payment)) {
      return;
    }

    setDownPayment(payment);
  };

  const validateAndSetInterestRate = (value?: string) => {
    setInterestRate(value || '');

    const interestRate = value ? Number(value) : 0;

    if (isNaN(interestRate) || interestRate < 0 || interestRate > 100) {
      setInterestRateError('Invalid interest rate');
      return;
    }

    setInterestRateError('');
  };

  const validateAndSetHomeInsurance = (value?: string) => {
    const homeInsurancePrice = Number(value?.replaceAll(',', ''));
    if (isNaN(homeInsurancePrice)) {
      return;
    }

    setHomeInsurance(homeInsurancePrice);
  };

  const validateAndSetPropertyTax = (value?: string) => {
    const propertyTax = Number(value?.replaceAll(',', ''));
    if (isNaN(propertyTax)) {
      return;
    }

    setPropertyTax(propertyTax);
  };

  const updatePropertyTax = (value: string) => {
    const state = STATES_WITH_TAX_RATE.find((state) => state.code === value);

    if (isPropertyTaxInPercentage) {
      setPropertyTax(Number(state?.taxRate));
    } else {
      const propertyTax = (homePrice / 100) * (Number(state?.taxRate) || 1.15);

      setPropertyTax(propertyTax);
    }
  };

  useEffect(() => {
    updatePropertyTax(state);
  }, [state]);

  //Equivalent values in Toggle Input

  const downPaymentEquivalent = isDownPaymentInPercentage
    ? Math.round((homePrice / 100) * downPayment)
        .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
        .slice(0, -3)
    : `${((downPayment / homePrice) * 100).toFixed(2)}%`;

  const propertyTaxEquivalent = isPropertyTaxInPercentage
    ? Math.round((homePrice / 100) * propertyTax)
        .toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
        })
        .slice(0, -3)
    : `${((propertyTax / homePrice) * 100).toFixed(2)}%`;

  console.log(propertyTaxDollarsValuePerMonth, propertyTax);

  return (
    <>
      <Box
        py="$12x"
        gap="$28x"
        $largerThanMD={{ flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <Box
          py="$24x"
          px="$12x"
          display="none"
          backgroundColor="$backgroundTertiary"
          borderRadius="$12x"
          justifyContent="center"
          alignItems="center"
          testID="Mortgage-payment-container"
          $largerThanMD={{
            height: 480,
            width: 630,
            display: 'flex',
          }}
          style={{
            position: 'sticky',
            top: 60,
          }}
        >
          <Text
            typography="$labelXsmall"
            tag="span"
            color="$contentTertiary"
            $largerThanSM={{
              typography: '$labelSmall',
            }}
          >
            YOUR MONTHLY PAYMENT
          </Text>
          <Text
            typography="$displaySmall"
            tag="h2"
            $largerThanSM={{
              typography: '$displayMedium',
            }}
          >
            {totalMontlyPaymentDisplayValue}
          </Text>

          <Box
            display="flex"
            flexDirection="row"
            width="100%"
            py="$24x"
            maxWidth={330}
            overflow="hidden"
          >
            <Box
              width={`${principalTotalPercentage}%`}
              backgroundColor="$backgroundAccentBrand"
              height="$10x"
              borderTopLeftRadius="$12x"
              borderBottomLeftRadius="$12x"
            ></Box>
            <Box
              width={`${propertyTaxTotalPercentage}%`}
              backgroundColor="$backgroundStateHoverTertiary"
              height="$10x"
            ></Box>
            <Box
              width={`${insuranceTotalPercentage}%`}
              backgroundColor="$backgroundInverseStateHoverTertiary"
              height="$10x"
              borderTopRightRadius="$12x"
              borderBottomRightRadius="$12x"
            ></Box>
          </Box>

          <Box gap="$10x">
            <MortgageRow
              name="Principal and interest"
              value={roundedPrincipalPerMonth}
              percentage={principalTotalPercentage}
              pillColor="$backgroundAccentBrand"
            />
            <MortgageRow
              name="Property tax"
              value={propertyTaxDollarsValuePerMonth}
              percentage={propertyTaxTotalPercentage}
              pillColor="$backgroundInverseStateHoverTertiary"
            />
            <MortgageRow
              name="Home insurance"
              value={insuranceDollarsValue}
              percentage={insuranceTotalPercentage}
              pillColor="$backgroundStateHoverTertiary"
            />
          </Box>
        </Box>

        <Box
          testID="Form-Container"
          gap="$28x"
          $largerThanMD={{
            width: '50%',
            maxWidth: 630,
            gap: '$40x',
          }}
        >
          <Dropdown
            required
            title="Location"
            supportText="Select your state, property taxes and interest rates can vary by location."
            size="medium"
            aria-labelledby="Select Department"
            options={STATES_DROPDOWN_OPTIONS}
            prefilledValue="CA"
            onValueChange={(value) => {
              setState(value);
            }}
          />

          <TextInput
            size="medium"
            title="Home price"
            value={homePrice.toLocaleString('en-US')}
            onChangeText={(text: string) => {
              validateAndSetHomePrice(text);
            }}
            errorText={homePriceError}
            supportText="Enter the purchase price of the home you want to buy."
            prefix="$"
          ></TextInput>

          <TextInputWithToggle
            id="downpayment-input"
            value={downPayment.toLocaleString('en-US')}
            onChangeText={(text: string) => {
              validateAndSetDownPayment(text);
            }}
            errorText={downPaymentError}
            size="medium"
            title="Down payment"
            supportText="Most home loans require a down payment of at least 3%."
            prefix={isDownPaymentInPercentage ? '' : '$'}
            suffix={isDownPaymentInPercentage ? '%' : ''}
            toggleProps={{
              leftIcon: { Component: Icons.Dollar, label: 'dollars' },
              rightIcon: { Component: Icons.Percentage, label: 'percent' },
              defaultChecked: false,
              onCheckedChange: (isPercentage) => {
                setIsDownPaymentInPercentage(isPercentage);
                if (isPercentage !== isDownPaymentInPercentage) {
                  let paymentValue;
                  if (isPercentage) {
                    paymentValue = Math.ceil((downPayment / homePrice) * 100);
                  } else {
                    paymentValue = (homePrice / 100) * downPayment;
                  }
                  setDownPayment(paymentValue);
                }
              },
            }}
          >
            <Box justifyContent="center">
              <Text tag="span" typography="$bodyLarge" color="$contentPrimary">
                {downPaymentEquivalent}
              </Text>
            </Box>
          </TextInputWithToggle>

          <TextInput
            id="interest-rate-input"
            value={String(interestRate)}
            onChangeText={(text: string) => {
              validateAndSetInterestRate(text);
            }}
            errorText={interestRateError}
            size="medium"
            title="Interest rate"
            supportText="Enter an interest rate or we'll use the US weekly average."
            suffix="%"
          ></TextInput>

          <Dropdown
            title="Mortgage type"
            supportText="This calculator estimates payments for a 30-year fixed loan program."
            size="medium"
            aria-labelledby="Select Department"
            prefilledValue={'30'}
            options={[
              {
                label: '30 Year Fixed Loan',
                value: '30',
              },
            ]}
            disabled
          />

          <Accordion
            size="large"
            variant="medium"
            items={[
              {
                title: 'Advanced',

                description: (
                  <Box gap="$40x">
                    <TextInput
                      value={homeInsurance.toLocaleString('en-US')}
                      onChangeText={(text: string) => validateAndSetHomeInsurance(text)}
                      size="medium"
                      title="Home insurance"
                      supportText="Enter your homeowner’s insurance, if known. Otherwise, the rate will default to the US average. "
                      prefix="$"
                    ></TextInput>

                    <TextInputWithToggle
                      id="property-tax-input"
                      value={propertyTax.toLocaleString('en-US')}
                      onChangeText={(text: string) => validateAndSetPropertyTax(text)}
                      errorText={propertyTaxError}
                      size="medium"
                      title="Property tax"
                      supportText="Enter the property tax rate, if known. Otherwise, the rate will default to the Mean Effective Property Tax for your State."
                      prefix={isDownPaymentInPercentage ? '' : '$'}
                      suffix={isDownPaymentInPercentage ? '%' : ''}
                      toggleProps={{
                        leftIcon: { Component: Icons.Dollar, label: 'dollars' },
                        rightIcon: { Component: Icons.Percentage, label: 'percent' },
                        defaultChecked: false,
                        onCheckedChange: (isPercentage) => {
                          setIsPropertyTaxInPercentage(isPercentage);
                          if (isPercentage !== isPropertyTaxInPercentage) {
                            let paymentValue;
                            if (isPercentage) {
                              paymentValue = Number(((propertyTax / homePrice) * 100).toFixed(2));
                            } else {
                              paymentValue = (homePrice / 100) * propertyTax;
                            }
                            setPropertyTax(paymentValue);
                          }
                        },
                      }}
                    >
                      <Box justifyContent="center">
                        <Text tag="span" typography="$bodyLarge" color="$contentPrimary">
                          {propertyTaxEquivalent}
                        </Text>
                      </Box>
                    </TextInputWithToggle>
                  </Box>
                ),
              },
            ]}
            orientation="bottom"
            titleAlign="right"
          />
        </Box>
      </Box>
      <Box py="$28x" gap="$16x" $largerThanMD={{ maxWidth: 620 }}>
        <Text tag="h2" typography="$subheaderLarge">
          Disclosure
        </Text>
        <Text tag="p" color="$contentPrimary" typography="$bodyMedium" display="block">
          {markdownToReactElement(disclosureText as string)}
        </Text>
      </Box>

      {/** Sticky bar at the bottom for mobile */}
      <Box
        p="$12x"
        backgroundColor="$backgroundTertiary"
        borderRadius="$12x"
        testID="Mortgage-payment-container"
        $largerThanMD={{
          display: 'none',
        }}
        style={{
          position: 'sticky',
          zIndex: 200,
          bottom: 20,
        }}
        marginBottom="$12x"
        gap="$8x"
      >
        <Box flexDirection="row" justifyContent="space-between">
          <Text typography="$bodySmall" tag="span" width="60%">
            Your monthly Payment
          </Text>
          <Text tag="span" typography="$bodySmall"></Text>
          <Text
            typography="$labelLarge"
            $largerThanSM={{ typography: '$subheaderSmall' }}
            tag="span"
          >
            {totalMontlyPaymentDisplayValue}
          </Text>
        </Box>

        <Box flexDirection="row" justifyContent="space-between">
          <Text typography="$bodySmall" tag="span" width="60%">
            Principal and interest
          </Text>
          <Text tag="span" typography="$labelLarge" color="$contentTertiary">
            {`(${principalTotalPercentage}%)`}
          </Text>
          <Text
            typography="$labelLarge"
            tag="span"
            $largerThanSM={{ typography: '$subheaderSmall' }}
          >
            {`$${roundedPrincipalPerMonth}`}
          </Text>
        </Box>

        <Box flexDirection="row" justifyContent="space-between">
          <Text typography="$bodySmall" tag="span" width="60%">
            Property tax
          </Text>
          <Text tag="span" typography="$labelLarge" color="$contentTertiary">
            {`(${propertyTaxTotalPercentage}%)`}
          </Text>
          <Text
            typography="$labelLarge"
            tag="span"
            $largerThanSM={{ typography: '$subheaderSmall' }}
          >
            {`$${propertyTaxDollarsValuePerMonth}`}
          </Text>
        </Box>

        <Box flexDirection="row" justifyContent="space-between">
          <Text typography="$bodySmall" tag="span" width="60%">
            Home insurance
          </Text>
          <Text tag="span" typography="$labelLarge" color="$contentTertiary">
            {`(${insuranceTotalPercentage}%)`}
          </Text>
          <Text
            typography="$labelLarge"
            tag="span"
            $largerThanSM={{ typography: '$subheaderSmall' }}
          >
            {`$${insuranceDollarsValue}`}
          </Text>
        </Box>
      </Box>
    </>
  );
};

export default MortgageCalculator;
