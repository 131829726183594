import { css } from '@emotion/react';
import { Box } from '@opendoor/bricks-next';
import { Link } from '@opendoor/bricks/core';
import { novo } from '@opendoor/bricks/theme';

import { EntryComponent } from 'cms/entries/entries';

import { ILpComponentLinkList, ILpElementLink } from 'declarations/contentful';

import Container from './shared/Container';

const LinkToggle = ({ link, analyticsId }: { link: ILpElementLink; analyticsId?: string }) => {
  const { fields } = link;
  const backgroundColor = fields.active ? 'brandBlue600' : 'transparent';
  const color = fields.active ? 'white0' : 'brandBlue600';
  const hoverColor = fields.active ? novo.colors.white0 : novo.colors.blue500;

  return (
    <Link
      aria-label={`Go to page ${fields.text}`}
      analyticsName={`cosmos-landing-page-link-list-${analyticsId || ''}-${fields.text}`}
      backgroundColor={backgroundColor}
      color={color}
      href={fields.url}
      cursor="pointer"
      p="5px 20px"
      fontWeight="medium"
      letterSpacing="-1.4"
      _hover={{
        color: hoverColor,
      }}
      css={css`
        border-radius: 99em;
      `}
    >
      {fields.text}
    </Link>
  );
};

const RenderLinkList = (entry: ILpComponentLinkList) => {
  const { fields } = entry;
  return (
    <Container paddingVertical={16} justifyContent="center" display="flex">
      <Box
        tag="ul"
        marginHorizontal="auto"
        padding={0}
        role="list"
        display="inline-flex"
        flexDirection="row"
        css={css`
          border-radius: 99em;
          list-style: none;
          box-shadow: inset 0 0 0 1px ${novo.colors.warmgrey300};
        `}
      >
        {fields.links?.map((link, idx) => (
          <Box tag="li" key={idx} display="inline-flex">
            <LinkToggle link={link} analyticsId={fields.analyticsId} />
          </Box>
        ))}
      </Box>
    </Container>
  );
};

const LinkList: EntryComponent<ILpComponentLinkList> = {
  render: RenderLinkList,
};

export default LinkList;
