// storybook-check-ignore
import { ILpElementCtaButton } from 'declarations/contentful';

import { LPLink } from './Buttons';
import { IBNComponentThemeConfig, IComponentThemeOptions } from './styles/ComponentTheme';

interface ExtraCtasProps {
  ctas: ILpElementCtaButton[];
  componentTheme: IComponentThemeOptions;
  bnTheme?: IBNComponentThemeConfig;
}

const ExtraCtas = ({ ctas, componentTheme, bnTheme }: ExtraCtasProps) => {
  return (
    <>
      {ctas.map((cta, idx) => {
        return (
          cta.fields.text &&
          cta.fields.url && (
            <LPLink
              key={idx}
              aria-label={`Go to page ${cta.fields.text}`}
              analyticsName={`cosmos-landing-page-hero-cta-${cta.fields.analyticsId}`}
              componentTheme={componentTheme}
              bnTheme={bnTheme}
              ctaStyle={cta.fields.ctaStyle}
              ctaDisplayText={cta.fields.text}
              ctaUrl={cta.fields.url}
              mt="0"
            />
          )
        );
      })}
    </>
  );
};

export default ExtraCtas;
