import { CSSProperties, KeyboardEvent, useRef, useState } from 'react';

import { css } from '@emotion/react';
import { Box, getCSSVariable, Text } from '@opendoor/bricks-next';
import { Link, LinkProps } from '@opendoor/bricks/core';
import { novo } from '@opendoor/bricks/theme';

import { EntryComponent } from 'cms/entries/entries';

import { ILpComponentList, ILpComponentListItem } from '../../declarations/contentful';
import Container from './shared/Container';
import Sheet from './shared/Sheet';
import {
  BNColorThemes,
  getBNComponentThemeColors,
  IBNComponentThemeConfig,
} from './shared/styles/ComponentTheme';
import { Gutter, GutterLarge, StackStyle } from './shared/styles/SharedStyles';
import { Desc, Eyebrow, TitleWithAccent } from './shared/Typography';
import { markdownToReactElement } from './shared/utils';

const styles = { ...StackStyle };

interface ItemDetailProps {
  item: ILpComponentListItem;
  theme: IBNComponentThemeConfig;
  listItemTitleSize: '18px' | '24px';
}

const ItemDetails = ({ item, theme = {} as IBNComponentThemeConfig }: ItemDetailProps) => {
  return (
    <Box
      css={{ ...StackStyle }}
      position="relative"
      paddingRight={item.fields.url ? '$10x' : 0}
      gap={8}
    >
      {item.fields.eyebrow && (
        <Eyebrow mt={3} color={theme.contentPrimary}>
          {item.fields.eyebrow}
        </Eyebrow>
      )}
      <Text tag="h4" color={theme.contentPrimary} typography="$subheaderSmall">
        {item.fields.title}
      </Text>
      {item.fields.desc && (
        <Text tag="p" color={theme.contentPrimary} typography="$bodyLarge" display="block">
          {markdownToReactElement(item.fields.desc)}
        </Text>
      )}
      {(item.fields.url || item.fields.ctaSheet) && (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
          style={{
            color: getCSSVariable('$contentTertiary', 'color'),
            display: 'block',
            position: 'absolute',
            left: '100%',
            marginTop: '-8px',
            top: '50%',
          }}
        >
          <path
            d="M5 14L11 8L5 2"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      )}
    </Box>
  );
};

interface ItemSheetProps extends LinkProps {
  item: ILpComponentListItem;
  theme: IBNComponentThemeConfig;
  listItemTitleSize: '18px' | '24px';
}

const ItemSheet = ({ item, listItemTitleSize, theme, ...props }: ItemSheetProps) => {
  const [isVisible, setIsVisible] = useState(false);
  const ctaRef = useRef<HTMLAnchorElement>(null);
  // When animation is complete
  const exitComplete = () => {
    ctaRef.current?.focus();
  };

  // Make visual buttons work like Buttons
  const onKeyDown = (e: KeyboardEvent) => {
    if (e.key === ' ' || e.key === 'Spacebar') {
      setIsVisible(true);
    }
  };

  return (
    <>
      <Link
        alignItems="center"
        ref={ctaRef}
        onKeyDown={onKeyDown}
        onClick={() => setIsVisible(true)}
        cursor="pointer"
        outline="0"
        tabIndex={0}
        {...props}
        data-group
      >
        <ItemDetails item={item} theme={theme} listItemTitleSize={listItemTitleSize} />
      </Link>
      <Sheet
        entry={item.fields.ctaSheet}
        isVisible={isVisible}
        setIsVisible={setIsVisible}
        exitComplete={exitComplete}
      />
    </>
  );
};

const RenderList = (entry: ILpComponentList) => {
  const { fields } = entry;

  const bgColor =
    fields.listItemBg in BNColorThemes ? (fields.listItemBg as BNColorThemes) : BNColorThemes.Light;

  const theme = getBNComponentThemeColors(bgColor);

  // List Item Icon Options
  const hasListIcon = fields.listItemIcon && fields.listItemIcon !== 'None';
  const isNumbered = fields.listItemIcon === 'Number';
  const hasCheckmark = fields.listItemIcon === 'Checkmark';
  const iconBg =
    bgColor === BNColorThemes.Light
      ? getCSSVariable('$backgroundTertiary', 'color')
      : 'transparent';

  let before: CSSProperties;
  let beforeBase: CSSProperties;
  let pl: any;
  let pr: any;
  let py: any;
  let gap: any;

  if (bgColor === BNColorThemes.Light) {
    beforeBase = {
      alignItems: 'center',
      backgroundColor: iconBg,
      color: '#000000',
      borderRadius: '8px',
      display: 'flex',
      height: '40px',
      justifyContent: 'center',
      lineHeight: '1',
      width: '40px',
    };

    pl = 0;
    pr = [0, null, GutterLarge];
    py = GutterLarge;
    gap = '20px';
  } else {
    beforeBase = {
      alignItems: 'center',
      backgroundColor: iconBg,
      borderRadius: '8px',
      // @ts-expect-error theme.contentPrimary is a bricks-next color token
      color: getCSSVariable(theme.contentPrimary, 'color'),
      display: 'flex',
      justifyContent: 'center',
      lineHeight: '1',
      width: '24px',
    };

    pl = ['24px'];
    pr = ['28px'];
    py = ['26px'];
    gap = ['20px'];
  }

  if (isNumbered) {
    before = {
      ...beforeBase,
      content: 'counter(li)',
      counterIncrement: 'li',
      fontSize: novo.fontSizes['18px'],
      fontWeight: novo.fontWeights.medium,
    };
  }
  if (hasCheckmark) {
    if (bgColor === BNColorThemes.Dark) {
      before = {
        ...beforeBase,
        content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M4 8.69231L6.52632 11L12 6' stroke='%23FFFFFF' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`,
      };
    } else {
      before = {
        ...beforeBase,
        content: `url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16' fill='none'%3E%3Cpath d='M4 8.69231L6.52632 11L12 6' stroke='%23000000' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E")`,
      };
    }
  }

  // List Layout Options
  const headerAbove =
    fields.listLayout === 'Header Above' ||
    fields.listLayout === 'Header Above with Two Column List';
  const twoColumnList =
    fields.listLayout === 'Header Above with Two Column List' ||
    fields.listLayout === 'Header Left with Two Column List';
  const listItemTitleSize = headerAbove ? '18px' : '24px';

  return (
    <Container>
      <Box $largerThanSM={{ flexDirection: headerAbove ? 'column' : 'row' }} columnGap="$7x">
        <Box
          css={styles}
          flex={4}
          $largerThanSM={{ flexBasis: '100%' }}
          marginBottom="$10x"
          paddingRight="$10x"
        >
          {fields.title && (
            <TitleWithAccent
              overRideTitleTag={fields.titleTag as 'h1' | 'h2' | 'h3'}
              title={fields.title}
              titleAccent={fields.titleAccent}
              titleSize={fields.titleSize}
              $largerThanSM={{ typography: '$headerLarge' }}
            />
          )}
          <Box
            // @ts-expect-error "ch" units aren't accepted by bricks-next but they're valid for web styles
            $largerThanSM={{ maxWidth: headerAbove ? '60ch' : '100%' }}
          >
            {fields.subhead && (
              <Text
                tag="h4"
                display="block"
                typography={fields.titleSize === 'Large' ? '$subheaderXsmall' : '$labelMedium'}
                $largerThanSM={{
                  typography: fields.titleSize === 'Large' ? '$subheaderSmall' : '$labelMedium',
                }}
                fontWeight="$medium"
                color={'$contentPrimary'}
              >
                {fields.subhead}
              </Text>
            )}
            {fields.desc && <Desc>{fields.desc}</Desc>}
          </Box>
        </Box>
        <Box flex={6} $largerThanSM={{ flexBasis: '100%' }}>
          <Box
            tag={isNumbered ? 'ol' : 'ul'}
            margin={0}
            padding={0}
            columnGap="$8x"
            role="list"
            justifyContent="space-between"
            $largerThanSM={{ flexDirection: twoColumnList ? 'row' : 'column' }}
            flexWrap="wrap"
            css={css`
              counter-reset: li;
              list-style: none;
            `}
          >
            {fields.listItems?.map((item, idx) => {
              const itemDisplay = hasListIcon ? 'grid' : 'block';
              const itemGap = gap;
              const itemGridTemplateColumns = 'auto 1fr';
              const itemPt =
                idx === 0 && bgColor === BNColorThemes.Light && !twoColumnList ? 0 : py;
              const itemPb = py;
              const itemPl = pl;
              const itemPr =
                bgColor === BNColorThemes.Light && (item.fields.url || item.fields.ctaSheet)
                  ? Gutter
                  : pr;

              return (
                <Box
                  key={idx}
                  backgroundColor={theme.bgPrimary}
                  flexBasis={twoColumnList ? '47%' : 'unset'}
                  borderRadius={bgColor === BNColorThemes.Light ? undefined : '$8x'}
                  tag="li"
                  borderBottomColor={bgColor === BNColorThemes.Light ? '$borderPrimary' : undefined}
                  borderBottomWidth={bgColor === BNColorThemes.Light ? 1 : 0}
                  marginBottom={bgColor !== BNColorThemes.Light ? '$8x' : undefined}
                >
                  {item.fields.url ? (
                    <Link
                      href={item.fields.url}
                      aria-label=""
                      analyticsName={`cosmos-landing-page-list-item-${item.fields?.analyticsId}`}
                      alignItems="center"
                      display={itemDisplay}
                      gap={itemGap}
                      gridTemplateColumns={itemGridTemplateColumns}
                      pt={itemPt}
                      pb={itemPb}
                      pl={itemPl}
                      pr={itemPr}
                      _before={before}
                      data-group
                      css={css`
                        &:before {
                          margin-top: 2px;
                        }
                        @media (min-width: 768px) {
                          &:before {
                            margin-top: 2px;
                          }
                        }
                      `}
                    >
                      <ItemDetails
                        item={item}
                        theme={theme}
                        listItemTitleSize={listItemTitleSize}
                      />
                    </Link>
                  ) : item.fields.ctaSheet ? (
                    <ItemSheet
                      item={item}
                      theme={theme}
                      display={itemDisplay}
                      gap={itemGap}
                      gridTemplateColumns={itemGridTemplateColumns}
                      pt={itemPt}
                      pb={itemPb}
                      pl={itemPl}
                      pr={itemPr}
                      aria-label={item.fields.ctaSheetLabel || 'Open dialog'}
                      analyticsName={`cosmos-landing-page-list-item-${item.fields?.analyticsId}`}
                      listItemTitleSize={listItemTitleSize}
                      _before={before}
                    />
                  ) : (
                    <Link
                      _hover={{ cursor: 'default' }}
                      aria-label=""
                      alignItems="center"
                      analyticsName={`cosmos-landing-page-list-item-${item.fields?.analyticsId}`}
                      display={itemDisplay}
                      gap={itemGap}
                      gridTemplateColumns={itemGridTemplateColumns}
                      pt={itemPt}
                      pb={itemPb}
                      pl={itemPl}
                      pr={itemPr}
                      _before={before}
                      data-group
                      css={css`
                        &:before {
                          margin-top: 2px;
                        }
                        @media (min-width: 768px) {
                          &:before {
                            margin-top: 4px;
                          }
                        }
                      `}
                    >
                      <ItemDetails
                        item={item}
                        theme={theme}
                        listItemTitleSize={listItemTitleSize}
                      />
                    </Link>
                  )}
                </Box>
              );
            })}
          </Box>
          {fields.seeMoreLinkText && fields.seeMoreLinkUrl && (
            <Box marginTop="$16x">
              <Link
                analyticsName=""
                aria-label="See More"
                href={fields.seeMoreLinkUrl}
                color="blue700"
                fontWeight="medium"
                _hover={{ color: novo.colors.blue500 }}
              >
                {fields.seeMoreLinkText}
              </Link>
            </Box>
          )}
        </Box>
      </Box>
    </Container>
  );
};

const List: EntryComponent<ILpComponentList> = {
  render: RenderList,
};

export default List;
