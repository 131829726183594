import { ReactNode } from 'react';

import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { Document } from '@contentful/rich-text-types';
import { globalObservability } from '@opendoor/observability/slim';
import { Entry } from 'contentful';

import {
  IArticle,
  IArticleFields,
  ILandingPageV2,
  ILandingPageV2Fields,
  ILpComponentGetCashOfferModalFields,
  IOpenGraphFields,
  ISeoBaseFields,
  ISiteFooter,
  ISiteHeader,
  ITopicPageFields,
} from '../declarations/contentful';
import { createContentfulClient, fetchEntriesById, fetchEntryById } from './api';
import articleEntryLookup from './entries/article-v2';
import landingPageV2EntryLookup from './entries/landingPageV2';
import topicPageEntryLookup from './entries/topicPage-v2';
import { hydrateEntries, IIdToLoaderData } from './loaders';

export type ArticleProps = {
  sys: { createdAt: string; updatedAt: string; tags: Array<string> };
  fields: IArticleFields;
  readingTimeMinutes: number | null;
  idToLoaderData: IIdToLoaderData;
};

export type CollectionProps = {
  total: number;
  items: Array<Entry<any>>;
};

export type TopicPageProps = {
  sys: {
    createdAt: string;
    updatedAt: string;
    locale: string;
  };
  fields: ITopicPageFields;
  idToLoaderData: IIdToLoaderData;
};

export type LandingPageV2Props = {
  children?: ReactNode;
  fields: ILandingPageV2Fields;
  idToLoaderData: IIdToLoaderData;
  markets: [];
  public: boolean;
  showModal?: boolean;
  modalFields?: ILpComponentGetCashOfferModalFields | null;
};

export interface ILandingPageV2LayoutFields extends Partial<ILpComponentGetCashOfferModalFields> {
  /** Slug */
  slug: string;

  /** Header */
  header: ISiteHeader;

  /** Footer */
  footer?: ISiteFooter | undefined;

  /** SEO Base */
  seoBase?: ISeoBaseFields | undefined;

  /** Show Return Experience Banner */
  showReturnExperienceBanner?: boolean | undefined;

  /** Extra Legal Text */
  extraLegalText?: Document | undefined;

  /** Show Eligibility Legal */
  showEligibilityLegal?: boolean | undefined;

  /** Show Customer Testimonial Legal */
  showCustomerTestimonialLegal?: boolean | undefined;

  /** Show the PDP Copyright Legal */
  showThePdpCopyrightLegal?: boolean | undefined;

  /** Show Promotion Legal */
  showPromotionLegal?: boolean | undefined;

  /** Open Graph Fields */
  openGraph?: IOpenGraphFields;
}

export type ILandingPageV2LayoutProps<T> = {
  children?: ReactNode;
  fields: T;
  idToLoaderData: IIdToLoaderData;
  hideGoogleOneTap?: boolean;
  showModal?: boolean;
  modalFields?: ILpComponentGetCashOfferModalFields | null;
};

const sysFields = ['createdAt', 'updatedAt'];
const metaFields = ['tags'];
const selectedFields: Array<keyof IArticleFields> = [
  'slug',
  'seoBase',
  'authors',
  'showAuthorFooter',
  'articleName',
  'articleSubtitle',
  'header',
  'footer',
  // no relatedArticles
  'headerSummary',
  'headerImage',
  'keyTakeaways',
  'body',
  'editors',
  'reviewers',
  'openGraph',
  'openGraphArticle',
  'twitterCard',
  'publisher',
  'publishDate',
  'hideReadingTime',
];

type FetchBySlug = {
  slug: string;
  requestUrl: string;
  pageNum?: number;
};

export async function fetchArticleBySlug({
  slug,
  requestUrl,
}: FetchBySlug): Promise<ArticleProps | null> {
  const client = createContentfulClient();

  // Pretty much the same implementation as more complicated approaches
  // like those in the packages reading-time and worder
  const calculateReadingTime = (input: string) => {
    const WORDS_PER_MINUTE = 200;
    const matches = input.match(/(\w+)/g);
    if (!matches) {
      return null;
    }
    return matches.length / WORDS_PER_MINUTE;
  };

  const entry = await client.getEntries<Omit<IArticleFields, 'relatedArticles'>>({
    content_type: 'article',
    /*
      The field "include" specifies how many levels down of nested
      data that this fetch call should return. Please be aware that any
      value greater than 2 has significant impact on page performance.
    */
    include: 3,
    'fields.slug': slug,
    select: sysFields
      .map((x) => `sys.${x}`)
      .concat(selectedFields.map((x) => `fields.${x}`))
      .concat(metaFields.map((x) => `metadata.${x}`))
      .join(','),
    limit: 1,
  });
  if (entry.items.length === 0) {
    return null;
  }
  const safeEntry = JSON.parse(entry.stringifySafe()) as Omit<
    typeof entry,
    'toPlainObject' | 'stringifySafe'
  >;

  const article = safeEntry.items[0] as IArticle;
  // we can't just fetch relatedArticles, contentful doesn't allow for different "includes"
  // levels in queries, so relatedArticles pulls in megabytes of data
  article.fields.relatedArticles = await getRelatedArticles(article).catch(() => []);
  // calculate the reading time by adding together all of the string fields
  const minutes = calculateReadingTime(
    [
      article.fields.articleName,
      article.fields.headerSummary,
      article.fields.keyTakeaways && documentToPlainTextString(article.fields.keyTakeaways),
      documentToPlainTextString(article.fields.body),
    ]
      .filter(Boolean)
      .join(''),
  );

  let hydratedEntries: Array<[string, any]> = [];
  try {
    hydratedEntries = await Promise.all(
      hydrateEntries({
        includes: safeEntry.includes?.Entry ?? [],
        entryLookup: articleEntryLookup,
        root: article,
        pageContext: {
          url: requestUrl,
        },
      }),
    );
  } catch (e) {
    globalObservability.getSentryClient().captureException?.(e, {
      data: {
        slug,
      },
    });
  }
  const idToLoaderData = Object.fromEntries(hydratedEntries);
  return {
    sys: {
      createdAt: article.sys.createdAt,
      updatedAt: article.sys.updatedAt,
      tags: article.metadata.tags?.map((item) => item?.sys?.id).filter(Boolean) || [],
    },
    fields: article.fields,
    readingTimeMinutes: minutes !== null ? Math.round(minutes) : null,
    idToLoaderData,
  };
}

export async function fetchTopicPageBySlug({
  slug,
  pageNum,
  requestUrl,
}: FetchBySlug): Promise<TopicPageProps | null> {
  const client = createContentfulClient();
  const entries = await client.getEntries<ITopicPageFields>({
    content_type: 'topicPage',
    /*
      The field "include" specifies how many levels down of nested
      data that this fetch call should return. Please be aware that any
      value greater than 2 has significant impact on page performance.
    */
    include: 2,
    'fields.slug': slug,
    limit: 1,
  });
  if (entries.items.length === 0) {
    return null;
  }
  const safeEntries = JSON.parse(entries.stringifySafe()) as Omit<
    typeof entries,
    'toPlainObject' | 'stringifySafe'
  >;
  const topicPage = safeEntries.items[0];
  let hydratedEntries: Array<[string, any]> = [];
  try {
    hydratedEntries = await Promise.all(
      hydrateEntries(
        {
          includes: safeEntries.includes?.Entry ?? [],
          entryLookup: topicPageEntryLookup,
          root: topicPage,
          pageContext: { pageNum, url: requestUrl },
        },
        // transform to new options based
      ),
    );
  } catch (e) {
    globalObservability.getSentryClient().captureException?.(e, {
      data: {
        slug,
      },
    });
  }
  const idToLoaderData = Object.fromEntries(hydratedEntries);

  return {
    sys: {
      createdAt: topicPage.sys.createdAt,
      updatedAt: topicPage.sys.updatedAt,
      locale: topicPage.sys.locale,
    },
    fields: topicPage.fields,
    idToLoaderData,
  };
}

async function getRelatedArticles(article: IArticle): Promise<Array<IArticle>> {
  // grab the article that we want to look up relatedArticles with specifically
  const articleWithRelated = (
    await fetchEntriesById<Pick<IArticleFields, 'relatedArticles'>>('article', [article.sys.id], {
      select: 'fields.relatedArticles',
      include: 0,
    })
  )[0];

  return (
    await fetchEntriesById<Pick<IArticleFields, 'slug' | 'authors' | 'articleName'>>(
      'article',
      (articleWithRelated?.fields?.relatedArticles || []).map((a) => a.sys.id),
      { select: 'fields.slug,fields.authors,fields.articleName' },
    )
  ).filter((article) => !!article) as Array<IArticle>;
}

export async function getCashOfferModal() {
  try {
    const cashOfferModal = await fetchEntryById<ILpComponentGetCashOfferModalFields>(
      'lpComponentGetCashOfferModal',
      '29FQNyPKP58Niqf5jcSM58',
      {
        include: 2,
      },
    );
    return cashOfferModal;
  } catch (e) {
    // Capture the exception using the Sentry client
    globalObservability.getSentryClient().captureException?.(e, {
      data: {
        entryId: '29FQNyPKP58Niqf5jcSM58',
      },
    });
  }
  return undefined;
}

export async function fetchLandingPageV2BySlug({
  slug,
  pageNum,
  requestUrl,
}: FetchBySlug): Promise<LandingPageV2Props | null> {
  const client = createContentfulClient();

  const entry = await client.getEntries<ILandingPageV2Fields>({
    content_type: 'landingPageV2',
    /*
      The field "include" specifies how many levels down of nested
      data that this fetch call should return. Please be aware that any
      value greater than 2 may have a significant impact on page performance,
      so it should be used sparingly and content authors should understand the
      impact of overusing components with several levels of nested data.
    */
    include: 3,
    'fields.slug': slug,
    limit: 1,
  });

  if (entry.items.length === 0) {
    return null;
  }

  const safeEntry = JSON.parse(entry.stringifySafe()) as Omit<
    typeof entry,
    'toPlainObject' | 'stringifySafe'
  >;
  const page = safeEntry.items[0] as ILandingPageV2;

  let hydratedEntries: Array<[string, any]> = [];
  try {
    hydratedEntries = await Promise.all(
      hydrateEntries({
        includes: safeEntry.includes?.Entry ?? [],
        entryLookup: landingPageV2EntryLookup,
        root: page,
        pageContext: {
          url: requestUrl,
          pageNum,
        },
      }),
    );
  } catch (e) {
    globalObservability.getSentryClient().captureException?.(e, {
      data: {
        slug,
      },
    });
  }
  const idToLoaderData = Object.fromEntries(hydratedEntries);
  return {
    fields: page.fields,
    idToLoaderData,
    markets: [], // gets populated by the landing page component
    public: page.fields.public,
  };
}
