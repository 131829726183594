import { useState } from 'react';

import { Box, Link, Text, TextButton, TextInput, useMedia } from '@opendoor/bricks-next';

import { SellerFlowAddressInput } from 'components/shared/SellerFlowAddressInput';

import FeeInput from './HomeSaleFeeFormat';
import { markdownToReactElement } from './shared/utils';

export interface FeeInput {
  id: string;
  title: string;
  supportText: string;
  defaultValue: number;
  isFormatPercentage: boolean;
  learnMoreLink: string;
}
interface FeesState {
  [key: string]: {
    value: number;
    isPercentage: boolean;
  };
}
const HomeSaleCalculator = ({
  disclosureText,
  feeInputFields,
}: {
  disclosureText: string;
  feeInputFields: FeeInput[];
}) => {
  const [homeSalePrice, setHomeSalePrice] = useState<number>(200000);
  const [homeSalePriceError, setHomeSalePriceError] = useState<string>('');
  const [repairsNeeded, setRepairsNeeded] = useState(0);
  const [mortgagePayoffAmount, setMortgagePayoffAmount] = useState(0);
  const { largerThanXS } = useMedia();

  const [fees, setFees] = useState<FeesState>(() => {
    const initialState: FeesState = {};
    feeInputFields.forEach((fee) => {
      initialState[fee.id] = { value: fee.defaultValue, isPercentage: fee.isFormatPercentage };
    });
    return initialState;
  });
  const calculateTotalFees = () => {
    return Object.values(fees).reduce((total, fee) => {
      const absoluteFee = fee.isPercentage ? (fee.value / 100) * homeSalePrice : fee.value;
      return total + absoluteFee;
    }, 0);
  };

  const totalCostToSell = calculateTotalFees() + mortgagePayoffAmount + repairsNeeded;
  const netProceeds = homeSalePrice - totalCostToSell;

  const validateAndSetHomeSalePrice = (value?: string) => {
    const price = Number(value?.replace(/,/g, ''));
    if (isNaN(price) || price < 0) {
      return;
    }

    setHomeSalePrice(price);

    if (price < 5000 || price > 100000000) {
      setHomeSalePriceError('Invalid home sale price');
      return;
    }

    setHomeSalePriceError('');
  };

  const handleFeeChange = (id: string, value: number, isPercentage: boolean) => {
    setFees((prevFees) => ({
      ...prevFees,
      [id]: { value, isPercentage },
    }));
  };

  const NetProceedsSection = () => (
    <Box
      py="$24x"
      px="$24x"
      backgroundColor="$backgroundInversePrimary"
      borderRadius="$16x"
      justifyContent="center"
      alignItems="center"
      testID="Net-proceeds-container"
    >
      <Text
        typography="$labelXsmall"
        tag="span"
        color="$contentInverseTertiary"
        $largerThanMD={{
          typography: '$labelSmall',
        }}
      >
        NET PROCEEDS
      </Text>
      <Text
        pt="$6x"
        typography="$headerMedium"
        tag="h2"
        color="$contentInverseTertiary"
        $largerThanMD={{
          typography: '$displayMedium',
        }}
        $largerThanXS={{
          pt: '$4x',
        }}
      >
        ${netProceeds.toLocaleString('en-US')}
      </Text>
      <Box
        display="flex"
        flexDirection="row"
        pt="$28x"
        justifyContent="center"
        width="100%"
        $largerThanMD={{ gap: '$12x' }}
        $largerThanSM={{ gap: '$10x' }}
        $largerThanXS={{ gap: '$10x' }}
      >
        <Box>
          <Text typography="$labelXsmall" tag="span" color="$contentInverseTertiary">
            HOME SALE PRICE
          </Text>
          <Text typography="$subheaderSmall" tag="span" color="$contentInverseTertiary">
            ${homeSalePrice.toLocaleString('en-US')}
          </Text>
        </Box>
        <Box borderLeftColor="$borderSecondary" borderStyle="solid" borderWidth="$default"></Box>
        <Box>
          <Text typography="$labelXsmall" tag="span" color="$contentInverseTertiary">
            TOTAL COSTS TO SELL
          </Text>
          <Text typography="$subheaderSmall" tag="span" color="$contentInverseTertiary">
            ${totalCostToSell.toLocaleString('en-US')}
          </Text>
        </Box>
      </Box>
    </Box>
  );

  const OpendoorOfferComparesSection = () => (
    <Box
      py="$24x"
      px="$32x"
      borderStyle="solid"
      borderRadius="$16x"
      gap="$20x"
      $largerThanSM={{ py: '$16x', pt: '$16x' }}
      borderWidth="$default"
      justifyContent="space-between"
      borderColor="$borderPrimary"
      width="100%"
    >
      <Box>
        <Text
          $largerThanMD={{ typography: '$headerSmall' }}
          typography="$subheaderMedium"
          tag="h2"
          textAlign="center"
          mb="$8x"
        >
          See how your Opendoor offer compares
        </Text>
        <Text tag="span" typography="$bodyMedium" textAlign="center" color="$contentPrimary">
          We'll make a no-obligation offer on your home. If you love the offer, we'll purchase your
          home directly from you, so offer eligibility and purchase price may vary.
        </Text>
      </Box>
      <Box>
        <SellerFlowAddressInput
          analyticsName="cosmos-articles-topic-page-address-input"
          channel="DEFAULT"
          inputLocation="offer-cta"
          autoFocus={false}
          placeholderText="Enter your home address"
          showCta
          ctaProps={{
            actionText: 'Get cash offer',
            'aria-label': '',
          }}
          hideLabel
          product="SELL_DIRECT"
        />
        <TextButton
          analyticsName="cosmos-homesale-calculator-learn-more-about-opendoor"
          aria-label="learn-more-about-opendoor"
          onPress={() => window.location.assign('https://www.opendoor.com/')}
          variant="primary"
          alignSelf="center"
          size="medium"
          label="Learn more about Opendoor"
          pt="$10x"
        />
      </Box>
    </Box>
  );

  return (
    <>
      <Box
        py="$12x"
        gap="$28x"
        $largerThanMD={{ flexDirection: 'row', justifyContent: 'space-between' }}
      >
        <Box display="none" $largerThanMD={{ maxWidth: '50%', display: 'flex' }}>
          <Box
            gap="$16x"
            style={{
              position: 'sticky',
              top: '60px',
              zIndex: 10,
            }}
          >
            <NetProceedsSection />
            <OpendoorOfferComparesSection />
          </Box>
        </Box>

        <Box
          gap="$40x"
          testID="Form-Container"
          $largerThanMD={{
            width: '50%',
            maxWidth: 630,
          }}
          $largerThanXS={{ gap: '$28x' }}
        >
          <Box>
            <TextInput
              size="medium"
              title="Home sale price"
              prefix="$"
              value={homeSalePrice.toLocaleString('en-US')}
              onChangeText={(text: string) => {
                validateAndSetHomeSalePrice(text);
              }}
              errorText={homeSalePriceError}
              supportText="Enter the selling price of your home."
            ></TextInput>
            <Link
              aria-label={`opendoor-offer-link`}
              href={'https://www.opendoor.com'}
              size={largerThanXS ? 'small' : 'medium'}
              analyticsName={`cosmos-homesale-calculator-opendoor-offer`}
              color="$contentPrimary"
              variant="primary"
              pt="$8x"
            >
              See what Opendoor can offer for your home
            </Link>
          </Box>
          {feeInputFields.map((fee) => (
            <Box key={fee.id}>
              <FeeInput
                id={fee.id}
                title={fee.title}
                supportText={fee.supportText}
                homeSalePrice={homeSalePrice}
                isFormatPercentage={fee.isFormatPercentage}
                defaultValue={fee.defaultValue}
                onValueChange={handleFeeChange}
              />
              <Link
                aria-label={`${fee.id}-link`}
                href={fee.learnMoreLink}
                size="xsmall"
                analyticsName={`cosmos-homesale-calculator-${fee.id}`}
                color="$contentPrimary"
                textDecorationLine="underline"
                pt="$10x"
              >
                Learn more
              </Link>
            </Box>
          ))}
          <Box>
            <TextInput
              id="repairs-needed-input"
              value={repairsNeeded.toLocaleString('en-US')}
              onChangeText={(text: string) => {
                setRepairsNeeded(Number(text.replace(/,/g, '')));
              }}
              size="medium"
              title="Repairs needed to sell home"
              supportText="Once you’ve agreed to a buyer’s offer, they’ll inspect your home for defects. The buyer will usually ask you to make repairs or request a credit equal to the expected repair cost."
              prefix="$"
            ></TextInput>
            <Link
              aria-label="repairs-needed-info"
              href={'https://www.opendoor.com/articles/things-to-repair-before-selling-a-house'}
              size="small"
              analyticsName="cosmos-homesale-calculator-repairs-needed-info"
              color="$contentPrimary"
              textDecorationLine="underline"
              pt="$10x"
            >
              Learn more
            </Link>
          </Box>

          <Box>
            <TextInput
              id="mortgage-payoff-amount-input"
              value={mortgagePayoffAmount.toLocaleString('en-US')}
              onChangeText={(text: string) => {
                setMortgagePayoffAmount(Number(text.replace(/,/g, '')));
              }}
              size="medium"
              title="Mortgage payoff amount"
              supportText="This is how much it would cost to pay off your mortgage today. Note that this may not be the same as your current balance."
              prefix="$"
            ></TextInput>
            <Link
              aria-label="mortgage-payoff-info"
              href={'https://www.opendoor.com/articles/how-much-are-closing-costs-for-seller'}
              size="small"
              analyticsName="cosmos-homesale-calculator-mortgage-payoff-info"
              color="$contentPrimary"
              textDecorationLine="underline"
              pt="$10x"
            >
              Learn more
            </Link>
          </Box>
        </Box>

        <Box
          style={{ position: 'sticky', bottom: '48px' }}
          $largerThanMD={{ display: 'none' }}
          padding="$8x"
          gap="$8x"
          $largerThanSM={{
            padding: '$16x',
            gap: '$12x',
          }}
          backgroundColor="$backgroundSecondary"
          borderRadius="$12x"
          flexDirection="row"
          flexWrap="wrap"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            gap="$4x"
            width="100%"
            $largerThanSM={{
              width: 'inherit',
              flex: 1,
            }}
          >
            <Text tag="span" typography="$labelMedium" color="$contentTertiary">
              Net proceeds
            </Text>
            <Text
              tag="span"
              typography="$subheaderLarge"
              $largerThanSM={{ typography: '$subheaderSmall' }}
              color="$contentPrimary"
              testID="net-proceeds-value"
            >
              ${netProceeds.toLocaleString('en-US')}
            </Text>
          </Box>
          <Box
            gap="$4x"
            flex={1}
            $largerThanSM={{
              flex: 'unset',
            }}
          >
            <Text tag="span" typography="$labelMedium" color="$contentTertiary">
              Home sale price
            </Text>
            <Text
              tag="span"
              typography="$subheaderSmall"
              color="$contentPrimary"
              testID="home-sale-price-value"
            >
              ${homeSalePrice.toLocaleString('en-US')}
            </Text>
          </Box>
          <Box
            gap="$4x"
            flex={1}
            $largerThanSM={{
              flex: 'unset',
            }}
          >
            <Text tag="span" typography="$labelMedium" color="$contentTertiary">
              Total costs to sell
            </Text>
            <Text
              tag="span"
              typography="$subheaderSmall"
              color="$contentPrimary"
              testID="total-cost-value"
            >
              ${totalCostToSell.toLocaleString('en-US')}
            </Text>
          </Box>
        </Box>
      </Box>
      <Box py="$28x" gap="$16x" $largerThanMD={{ maxWidth: 620 }}>
        <Text tag="h2" typography="$subheaderLarge">
          Disclosure
        </Text>
        <Text tag="p" color="$contentPrimary" typography="$bodyMedium" display="block">
          {markdownToReactElement(disclosureText as string)}
        </Text>
      </Box>
    </>
  );
};

export default HomeSaleCalculator;
