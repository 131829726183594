import { EntryComponent } from 'cms/entries/entries';

import { CalculatorType, ILpComponentCalculator } from 'declarations/contentful';

import { feeInputFields } from './constants/home-sale-calculator';
import { feeInputFields as comparisonCalculatorInputFields } from './constants/net-proceeds-comparison-calculator';
import HomeSaleCalculator from './HomeSaleCalculator';
import MortgageCalculator from './MortgageCalculator';
import NetProceedsComparisonCalculator from './NetProceedsComparisonCalculator';
import { LandingPageSectionContainer } from './shared/Container';

const getCalculatorToRender = (calculatorType: CalculatorType, disclosureText: string) => {
  switch (calculatorType) {
    case 'Mortgage Calculator':
      return <MortgageCalculator disclosureText={disclosureText} />;
    case 'Home Rate Calculator':
      return <HomeSaleCalculator disclosureText={disclosureText} feeInputFields={feeInputFields} />;
    case 'Builder Calculator':
      return (
        <NetProceedsComparisonCalculator
          disclosureText={disclosureText}
          feeInputFields={comparisonCalculatorInputFields}
        />
      );
  }

  return null;
};

export const RenderCalculator = (entry: ILpComponentCalculator) => {
  const {
    fields: { calculatorType, disclosureText },
  } = entry;
  return calculatorType === 'Builder Calculator' ? (
    getCalculatorToRender(calculatorType, disclosureText)
  ) : (
    <LandingPageSectionContainer>
      {getCalculatorToRender(calculatorType, disclosureText)}
    </LandingPageSectionContainer>
  );
};

const Calculator: EntryComponent<ILpComponentCalculator> = {
  render: RenderCalculator,
};

export default Calculator;
