import { css, keyframes } from '@emotion/react';

export const ANIMATION_DURATION = 0.7;

const disappearAnimationKeyframes = keyframes`
  from {
    transform: scale(100%);
    opacity: 1;
  }
  to {
    transform: scale(80%);
    opacity: 0;
  }
`;

export const disappearAnimation = css({
  animation: `${disappearAnimationKeyframes} ${ANIMATION_DURATION}s`,
});

const reverseDisappearAnimationKeyframes = keyframes`
  from {
    transform: scale(80%);
    opacity: 0;
  }
  to {
    transform: scale(100%);
    opacity: 1;
  }
`;

export const reverseDisappearAnimation = css({
  animation: `${reverseDisappearAnimationKeyframes} ${ANIMATION_DURATION}s`,
});

const slideAnimationKeyFrames = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-105%);
  }
`;

export const slideAnimation = css({
  animation: `${slideAnimationKeyFrames} ${ANIMATION_DURATION}s`,
  willChange: 'transform',
});

const reverseSlideAnimationKeyFrames = keyframes`
  0% {
    transform: translateX(-105%);
  }
  100% {
    transform: translateX(0%);
  }
`;

export const reverseSlideAnimation = css({
  animation: `${reverseSlideAnimationKeyFrames} ${ANIMATION_DURATION}s`,
  willChange: 'transform',
});
