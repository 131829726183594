import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Text } from '@opendoor/bricks-next';
import { Box, Button, Flex } from '@opendoor/bricks/core';

import { EntryComponent } from 'cms/entries/entries';

import { Desc } from 'components/landing-pages-v2/shared/Typography';

import { ILpComponentContactButton, ILpComponentContactSection } from 'declarations/contentful';

import Grid, { gridStyles } from './Grid';

const ContactButton = styled(Button)`
  @media (max-width: 1249px) {
    font-size: 16px;
    height: 45px;
  }
  @media (min-width: 1250px) {
    font-size: 20px;
    height: 64px;
  }
  background-color: var(--colors-warmgrey950);
  &:hover {
    background-color: '#000000';
  }
`;

const RenderContactButton = (entry: ILpComponentContactButton) => {
  const { fields } = entry;
  const analyticsName = `cosmos-landing-page-contact-button-${fields?.analyticsId}`;
  return (
    <ContactButton
      type="button"
      key={analyticsName}
      borderBottomRadius="rounded"
      borderTopRadius="rounded"
      aria-label={null}
      analyticsName={analyticsName}
      onClick={() => {
        window.location.href = fields.href;
      }}
      width="fit-content"
      px={[7, 7, 7, 8]}
      minHeight="unset"
    >
      {fields.text}
    </ContactButton>
  );
};

const RenderContactSection = (entry: ILpComponentContactSection) => {
  const { fields } = entry;
  const analyticsName = `cosmos-landing-page-contact-section-${fields?.analyticsId}`;

  return (
    <Box analyticsName={analyticsName} trackImpression>
      <Grid>
        <Flex
          gridColumn={gridStyles.colSpan12}
          flexDirection={['column', null, 'row']}
          mt={[11, '96px', '88px', '136px']}
          mb={[10, 11, '20px', '136px']}
          marginX={['auto', null, '48px', null, '80px']}
          paddingX={['32px', null, '0']}
          justifyContent="space-between"
          alignItems={'flex-start'}
          gap={[11, 11, 6, null, 12]}
        >
          <Flex
            width={[null, '293px', '335px', '485px']}
            flexDir="column"
            gap={7}
            alignItems={['center', null, 'flex-start']}
          >
            <Box>
              <Text
                tag="h2"
                typography="$headerSmall"
                $largerThanMD={{ typography: '$headerLarge' }}
              >
                {fields?.title}
              </Text>
              <Text
                tag="h2"
                typography="$headerSmall"
                $largerThanMD={{ typography: '$headerLarge' }}
              >
                {fields?.subtitle}
              </Text>
              <Desc mt={'$10x'} $largerThanMD={{ fontSize: 24 }}>
                {fields?.description}
              </Desc>
            </Box>
          </Flex>
          <Flex
            gridColumn={gridStyles.colSpan12}
            justifyContent={[null, null, 'space-between']}
            gap={[3, null, null, null, 6]}
            alignItems="flex-start"
            css={css`
              @media (max-width: 374px) {
                flex-wrap: nowrap;
                flex-direction: column;
              }
              @media (min-width: 375px) {
                flex-wrap: nowrap;
                flex-direction: column;
                width: 293px;
              }
              @media (min-width: 961px) {
                flex-wrap: wrap;
                flex-direction: row;
                width: 450px;
              }
              @media (min-width: 1250px) {
                flex-wrap: wrap;
                flex-direction: row;
                width: 525px;
              }
            `}
          >
            {entry.fields.contactButtons?.map((contactButton: ILpComponentContactButton) => {
              return RenderContactButton(contactButton);
            })}
          </Flex>
        </Flex>
      </Grid>
    </Box>
  );
};

const ContactSection: EntryComponent<ILpComponentContactSection> = {
  render: RenderContactSection,
};

export default ContactSection;
